.braney-title {
    display: block;
    width: 100%;
    &>* {
        @include bold;
        @include title(s74);

        @extend .title-format;
    }
}

.braney-desc {
    margin-top: 30px;

    display: block;
    width: 100%;

    &>* {
        @include size(s24);
        color: map-get($color, black-3);
        line-height: 1.2;
        @include regular;

        @include rp(tx) {
            font-size: 2rem;
        }
    }
}

.braney-link {
    margin-top: 30px;

    display: inline-flex;
    position: relative;
    overflow: hidden;
    border-radius: 30px;
    transition: .3s ease-in-out;

    &::after {
        transition: .3s ease-in-out;
        z-index: 1;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 200%;
        content: "";
        background: linear-gradient(180deg, rgba(146, 214, 101, 1) 0%, rgba(0, 67, 63, 1) 50%, rgba(146, 214, 101, 1) 100%);
    }

    a {
        display: inline-flex;
        background: transparent;
        padding: 6px 40px;

        @include rpmin(lg) {
            padding: 13px 60px;
        }

        @include bold;
        @include size(s18);
        line-height: 29px;
        color: map-get($color, white);
        border-radius: 30px;
        position: relative;
        z-index: 2;
        overflow: hidden;
    }

    &:hover {
        transform: translateY(-3px);

        &::after {
            top: -100%;
        }
    }
}

.braney-title-3{

    &>*{
        color: map-get($color, main);
        @include bold;
        @include title(s40);
        line-height: 1.25;
    }
}

.svd {
    &-1 {
        &>.container {
            padding-top: 60px;
        }
    }
    
    &-2{
        margin-top: 80px;
        @include rpmin(lg){
            margin-top: 12rem;
        }
        .braney-image{
            position: relative;
            width: 100%;
            height: 0;
            padding-top: 50%;
            &>*{
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }
    }

    &-3{
        margin-top: 50px;
        @include rpmin(lg){
            margin-top: 70px;
        }
    }

    &-4{
        margin-top: 80px;
        @include rpmin(lg){
            margin-top: 12rem;
        }

        .procedure-box{
            margin-top: 5rem;
        }
    }

    &-5{
        margin-top: 80px;
        @include rpmin(lg){
            margin-top: 12rem;
        }
        
        margin-bottom: 5rem;

        .project-hl{
            margin-top: 8rem;
        }
    }
}