.about-2{
    &__title{
        padding-bottom: 3.5vh;
        margin-bottom: 3.5vh;
        @include rpmin(xxl){
            padding-bottom: 5vh;
            margin-bottom: 5vh;

            @media (max-height: 900px){
                padding-bottom: 3.5vh;
                margin-bottom: 3.5vh;
            }
        }

        position: relative;
        &::after{
            content: "";
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            height: 1px;
            background-color: map-get($color, main );
        }
    }
    &__content{
        
        // padding-top: 70px;
        // margin-top: 70px;
        // @include rp(ps){
        //     // padding-top: 50px;
        //     // margin-top: 50px;
        //     padding-top: 3.5vh;
        //     margin-top: 3.5vh;
        // }
        margin-right: 0;
        margin-left: 0;
        width: 100%;
        @include rpmin(lg){
            margin-right: -40px;
            margin-left: -40px;
            width: calc(100% + 80px);
        }

        padding-top: 20px;
        margin-top: -20px;

        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: stretch;
        justify-content: center;

        &>*{
            padding-top: 20px;
            
            flex: 0 0 100%;
            width: 100%;
            max-width: 100%;
            
            @include rpmin(sm){
                flex: 0 0 50%;
                width: 50%;
                max-width: 50%;
            }
            @include rpmin(lg){
                flex: 0 0 33.33333%;
                width: 33.33333%;
                max-width: 33.33333%;
            }
        }
        
    }
    &__box{
        display: flex;
        flex-direction: column;
        padding: 16px 10px 0px 10px;
        @include rpmin(lg){
            padding: 20px 30px 0px 30px;
        }
        @include rpmin(xxl){
            padding: 20px 40px 0 40px;
        }
        &--img{
            position: relative;
            padding-top: 60%;
            @include rpmin(xxl){
                @media (max-height: 900px){
                    padding-top: 20vh;
                }
            }
            
            img{
                position: absolute;
                top: 0;
                width: 100%;
                height: 100%;
                left: 0;
                right: 0;
                bottom: 0;
                object-fit: contain;
            }
        }
        &--desc{
            margin-top: 3vh;
            @include rpmin(xxl){
                margin-top: 6vh;
                @media (max-height: 900px){
                    margin-top: 3vh;
                }
            }
            // margin-top: 60px;
            // @include rp(ps){
            //     margin-top: 40px;
            // }
           &>*{
                text-align: center;
                @include size(s24);
                color: map-get($color , black-2 );
                @include regular;
           }
        }
    }
   
}
