#brn-index-8{
    background-color: white;
    height: 100%;
    width: 100%;
    
    .brn-index-8{
        // &--padding{
        //     padding-top: 60px;
        // }
        // height: 100vh;
        width: 100%;
        &__wrapper{
            height: 100%;
            margin-bottom: 40px;
            flex-direction: column;
            justify-content: center;
            // width: 100%;
            &--intro{
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: space-between;
                position: relative;
                padding-bottom: 5vh;
                flex-direction: column;
                align-items: center;

                @include rpmin(lg){
                    flex-direction: row;
                }

                &>*{
                    width: auto;
                }

                &::after{
                    content: "";
                    position: absolute;
                    bottom: 0px;
                    left: 0;
                    width: 100%;
                    height: 1px;
                    background-color: map-get($color, main );

                }
                &_title{
                    display: inline-block;
                    cursor: pointer;
                    @include bold;
                    @include title(s74);
                    &.active{
                        color: map-get($color,main );
                    }
                }
            }
            &--project{
                padding-top: 5vh;

                width: 100%;
                position: relative;
                display: none;
                &.active{
                    display: block;
                    // animation: fadeEffect 1s;
                    // transform-origin: left ;
                }
                @keyframes fadeEffect {
                    0% {
                        transform: scaleX(0);
                    }
                    100% {
                        transform: scaleX(1);
                    }
                }

                // .swiper-slide{
                //     height: calc((100% - 30px) / 2) !important;
                // }

                .project_items{
                    &:not(:last-child){
                        margin-bottom: 30px;
                        @include rpmin(lg){
                            margin-bottom: 60px;
                        }
                    }
                }
                
            }
        }
    }
}