form{
    &>*:nth-of-type(n + 1){
        padding-top: 16px;
    }
    .group-input{
        // width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        
        margin: -16px -8px 0 -8px;
        width: calc(100% + 16px);

        &>*{
            flex: 1;
            margin: 16px 8px 0 8px;
        }
    }

    button, input[type="button"]{
        outline: none;
        background: transparent;
        border: none;
    }

    textarea{
        resize: none;
        width: 100%;
    }

    input, textarea{
        border: 1px solid #4E8848;
        border-radius: 10px;
        box-shadow: 0 3px 6px rgba(0,0,0,.16);
        color: #363636;
        
        @include regular;
        line-height: 1.3;
        @include size(s16);
        padding: 16px 20px;
    }

    input[type="checkbox"]{
        width: 24px;
        height: 24px;
    }

    .check{
        display: flex;
        align-items: center;

        input[type="checkbox"]{
            position: absolute;
            opacity: 0;
        
            & + label{
                position: relative;
                cursor: pointer;
                padding: 0;

                color: #000;
                text-transform: uppercase;
                
                font-size: 16px;
                
                display: flex;
                align-items: center;

                white-space: nowrap;
            }

            & + label::before{
                content: '';
                margin-right: 10px;
                display: inline-block;
                vertical-align: text-top;
                width: 24px;
                height: 24px;
                background: white;
                border: 1px solid map-get($color, main);
                border-radius: 5px;
            }

            &:hover + label::before{
                background: map-get($color, main);
            }

            &:focus + label::before{
                box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
            }

            &:checked + label:before {
                background: map-get($color, main);
            }

            &:disabled + label {
                // color: #b8b8b8;
                opacity: .7;
                cursor: auto;
            }
            &:disabled + label:before {
                box-shadow: none;
                background-color: white;
                opacity: .7;
            }

            &:checked + label:after {
                content: '';
                position: absolute;
                top: 11px;
                left: 6px;
                background: white;
                width: 3px;
                height: 3px;
                box-shadow: 
                  2px 0 0 white,
                  4px 0 0 white,
                  4px -2px 0 white,
                  4px -4px 0 white,
                  4px -6px 0 white,
                  4px -8px 0 white,
                  4px -10px 0 white;
                transform: rotate(45deg);
            }
        }
    }

    button{
        min-width: 200px;
        flex: initial !important;
        line-height: 1.5;
        padding: 12px 24px;
        background-image: linear-gradient(to bottom, #92D665 0%, #00433F 100%);
        border-radius: 50px;
        color: white;
        @include bold;
        @include size(s18);

        &:disabled{
            background: grey;
            opacity: .8;
            cursor: auto;
        }
    }

    .submitFormContact{
        margin-top: 24px;
        @include rpmin(lg){
            margin-top: 32px;
        }
    }
}