.brand-service{
    &__content{
        display: flex;
        flex-wrap: wrap;
        // margin-right: 8px;
        // margin-left: 8px;
        width: 100%;
        
        flex-direction: column;
        @include rpmin(lg){
            flex-direction: row;
        }
    }
    &__img{
        flex: 0 0 100%;
        width: 100%;
        order: 1;
        @include rpmin(lg){
            order: initial;
            flex: 0 0 58.33333%;
            width: 58.33333%;
        }
    }
    &__infor{
        flex: 0 0 100%;
        width: 100%;
        order: 2;
        margin-top: 3vh;
        
        text-align: center;
        @include rpmin(lg){
            margin-top: 0;

            order: initial;
            flex: 0 0 41.66667%;
            width: 41.66667%;

            text-align: initial;
        }
        &--title{
            &>*{
                @include title(s40);
                color: map-get($color , main);
                @include bold;
            }

            @include p-line-clamp(2);
            @include rpmin(xxl){
                -webkit-line-clamp: initial;
            }
        }
        &--desc{
            margin-top: 2vh;
            @include rpmin(yl){
                margin-top: 36px;
            }

            &>*{
                @include size(s24);
                color: map-get($color , black-3);
                @include regular;
                line-height: 1.2;
            }

            @include p-line-clamp(7);
            @include rpmin(xxl){
                -webkit-line-clamp: 9;
            }
            @include rpmin(yl){
                -webkit-line-clamp: initial;
            }
        }
        &--seeProject{
            margin-top: 3vh;
            @include rpmin(yl){
                margin-top: 50px;

                @include hmin(yl){
                    margin-top: 3vh;
                }
            }
        }
    }
}