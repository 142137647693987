.cursor{
    width: var(--size);
    height: var(--size);
    border-radius: 50%;
    background-color: #FFF;
    position: absolute;
    z-index: 9999;
    pointer-events: none;
    mix-blend-mode: difference;
    opacity: 0; // 1
}

.cursor-f{
    width: var(--size);
    height: var(--size);
    position: absolute;
    top: 0;
    left: 0;
    background-image: url('../assets/icons/cursor.png');
    background-size: cover;
    mix-blend-mode: difference;
    pointer-events: none;
    opacity: 0; // 0.5
}