.box{
    width: 100%;
    height: 100%;
    &__content{
        padding: 2vh;
        // padding: 40px;
        // @include rp(pm){
        //     padding: 30px;
        // }
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    &__img{
        width: 45%;
        position: relative;
        padding-top: 45%;
        img{
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border-radius: 50%;
            object-fit: cover;
        }
    }
    &__position{
        // margin-top: 28px;
        // @include rp(pm){
        //     margin-top: 20px;
        // }
        margin-top: 2vh;
        // @include rpmin(xxl){
        //     margin-top: 2vh;
        // }
        &>*{
            @include size(s32);
            color: map-get($color , black-2 );
            @include medium;
        }
    }
    &__name{
        margin-top: 2vh;
        // @include rpmin(xxl){
        //     margin-top: 3vh;
        // }

        // margin-top: 20px;
        // @include rp(pm){
        //     margin-top: 16px;
        // }
        &>*{
            @include size(s32);
            color: map-get($color , main );
            @include bold;
        }
    }
    &__desc{
        text-align: center;
        margin-top: 2vh;
        // @include rpmin(xxl){
        //     margin-top: 3vh;
        // }
        // @include rp(pm){
        //     margin-top: 20px;
        // }
        &>*{
            @include size(s24);
            color: map-get($color , black-1 );
            @include regular;
        }
    }
    &__linkSocial{  
        flex: 1;
        display: flex;
        align-items: flex-end;
        justify-content: center;

        width: 100%;
        margin-top: 3vh;
        // @include rpmin(xxl){
        //     margin-top: 5vh;
        // }
        // @include rp(pm){
        //     margin-top: 40px;
        // }
    }
}