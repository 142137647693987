.project-hl{
    display: block;
    width: 100%;
    
    --kc: 0vw;
    @include rpmin(lg){
        --kc: 4vw;
    }

    ul{
        list-style-type: none;
    }
    li{
        overflow: hidden;
    }
    li + li{
        margin-top: 60px;
    }
    &__left{
        display: flex;
        flex-direction: column;
    }

    &__right{
        margin-top: 40px;
        @include rpmin(lg){
            margin-top: 0;
            
        }
    }
    &-img{
        width: 100%;
        height: 0;
        padding-top: 75%;
        position: relative;

        &>*{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top center;
        }
    }
    .order{
        font-size: 80px;
        @include rpmin(lg){
            font-size: 100px;
        }
        position: relative;
        display: flex;
        align-items: flex-start;

        &::after{
            --kc: 0vw;
            max-width: 54%;
            width: 100%;
            @include rpmin(lg){
                --kc: 4vw;
                width: calc(100% - #{var(--kc)} - 16px);
            }
            content: "";
            position: absolute;
            top: 2px;
            right: calc(#{var(--kc)} - 16px);
            
            margin-left: var(--kc);
            border-bottom: 5px solid #000;
        }

        span{
            display: inline-block;
            position: relative;
            z-index: 1;
            background-color: #fff;
            padding-right: var(--kc);

            line-height: 60%;
        }
    }
    .title{
        margin-top: 30px;
        @include bold;

        @include rpmin(lg){
            margin-top: 40px;
        }

        @include family--primary;
        font-size: 24px;
    }
    .cate{
        font-size: 16px;
        @include family--primary;
    }

    .desc{
        margin-top: 30px;
        width: calc(100% - #{var(--kc)});
        font-size: 16px;
        line-height: 206%;
    }

    &__more{
        display: flex;
        align-items: center;
        text-align: right;
        justify-content: flex-start;
        margin-top: 40px;
        @include rpmin(lg){
            justify-content: flex-end;
        }
    }
}