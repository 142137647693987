#brn-index-about{
    height: 100%;
    background-color: white;

    @include fp{
        height: auto;
    }

    .brn-index-about{
        // background: blue;
        width: 100%;
        height: 100vh;

        @include fp{
            height: auto;
        }

        &__wrapper{
            height: 100%;
            @include fp{
                height: auto;
            }
            display: flex;
            align-items: center;
            position: relative;
            &--abusImg{
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;

                @include rpmin(lg){
                    width: 58.33333%;
                    padding-right: 2%;
                }

                // width: 100%;
                // position: absolute;
                // top: 50%;
                // left: 0;
                // transform: translateY(-50%);
                svg{
                    max-height: 70vh;
                    width: 85%;
                    height: auto;

                    @include rp(pm){
                        width: 90%;
                    }

                    #braney_board{
                        animation: fadein infinite 3s ease-in-out;
                       
                    }
                    .braney_computer{
                        animation: fadein_computer infinite 3s ease-in-out;
                        transform-origin: center bottom;
                        transform-box: fill-box;
                        
                    }
                    #about_girl{
                        .about_girl_hair{
                            animation: hair_flow 3s infinite ease-in-out;
                            transform-origin: top right;
                            transform-box: fill-box;
                           
                        }
                    }
                    #about_boy{
                        .about_boy_hand{
                            animation: hand_flow 1.5s infinite ease-in-out;
                            transform-origin: top right;
                            transform-box: fill-box;
                           
                        }
                    }
                    .head_boy{
                        animation: hand_flow 3s infinite ease-in-out;
                        transform-origin: center bottom;
                        transform-box: fill-box;
                    }
                    
                }
            }
            &--abusInfor{
                width: 100%;
                margin-top: 24px;
                text-align: center;
                @include rpmin(lg){
                    margin-top: 0px;
                    width: 41.66667%;
                    text-align: initial;
                }

                &>*:not(:last-child){
                    margin-bottom: 3vh;
                    @include rpmin(yl){
                        margin-bottom: 60px;
                    }
                }
                &_title{
                    &>*{
                        @include bold;
                        @include title(s74);
                    }
                }
                &_decs{
                    p{
                        @include size(s24);
                        line-height: 29px;
                    }
                }
                &_btn{
                    display: inline-flex;
                    position: relative;
                    overflow: hidden;
                    border-radius: 30px;
                    transition: .3s ease-in-out;
                    &::after{
                        transition: .3s ease-in-out;
                        z-index: 1;
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 200%;
                        content: "";
                        background:  linear-gradient(180deg, rgba(146,214,101,1) 0%, rgba(0,67,63,1) 50%, rgba(146,214,101,1) 100%);
                    }
                    a{
                        display: inline-flex;
                        background: transparent;
                        padding: 6px 40px;
                        @include rpmin(lg){
                            padding: 13px 60px;
                        }

                        @include bold;
                        @include size(s18);
                        line-height: 29px;
                        color: map-get($color, white);
                        border-radius: 30px;
                        position: relative;
                        z-index: 2;
                        overflow: hidden;
                    }
                    &:hover{
                        transform: translateY(-10px);
                        &::after{
                            top: -100%;
                        }
                    }
                }
            }
        }
    }
}