#brn-index-services{
    background-color: white;
    height: 100%;
    width: 100%;
    @include fp{
        height:auto;
    }
    .brn-index-services{
        height: 100vh;
        width: 100%;
        @include fp{
            height:auto;
        }

        &__wrapper{
            height: 100%;
            @include fp{
                height:auto;
            }
            display: flex;
            flex-direction: column;
            justify-content: center;
            // margin-bottom: 40px;

            &--intro{
                // display: flex;
                // flex-wrap: wrap;
                // align-items: center;
                // margin-bottom: 75px;
                @extend .title__content;

                &_title{
                    @include bold;
                    @include title(s74);

                    @extend .title-format;
                    @extend .title__name;
                }
                &_decs{
                    @extend .title__desc;

                    // @include size(s24);
                    // line-height: 29px;
                }
            }
            &--services{
                width: 100%;
                padding-top: 3vh;
                margin-top: 3vh;

                // opacity: 0;
                position: relative;
                &::after{
                    content: "";
                    position: absolute;
                    top: 0px;
                    left: 0;
                    width: 100%;
                    height: 1px;
                    background-color: map-get($color, main );
                }
                .nav-custom{
                    // width: 100%;
                    margin: 0 -10px;
                    .swiper-container{
                        width: 100%;
                        padding: 10px;
                        
                    }
                }
                &_items{
                    // padding: 20px;
                    cursor: pointer;
                    // padding-bottom: 70%;
                    width: 100%;
                    position: relative;
                    overflow: hidden;
                    transition: .2s ease-in-out;

                    .link_services {
                        position: absolute;
                        top: 0;
                        left: 0;
                        bottom: 0;
                        right: 0;
                        z-index: 1;
                    }
                    .items{
                        padding: 30px;
                        background: white;
                        height: 100%;
                        // position: absolute;
                        // top: 0;
                        // left: 0;
                        // bottom: 0;
                        // right: 0;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        .items-header{
                            display: flex;
                            flex-wrap: nowrap;
                            justify-content: space-between;
                            align-items: flex-start;

                            &__title{
                                flex: 1;
                                margin-right: 0.8rem;
                                @include bold;
                                @include title(s40);
                                color: map-get($color,main );
                            }
                            &__icon{
                                width: 5.2rem;
                            }
                            
                        }
                        .items-main{
                            @include size(s24);
                            line-height: 29px;
                            color: map-get($color, black-3 );
                            @include p-line-clamp(3)
                        }
                        .items-footer {
                            display: inline-flex;
                            .btn-see-more{
                                padding: 10px 0;
                                @include size(s24);
                                color: map-get($color, main);
                                // @include bold;
                                position: relative;
                                display: inline-flex;
                                transition: .2s ease-in-out;
                               
                            }
                        }
                    }
                    box-shadow: 1px 3px 10px rgba($color: #000000, $alpha: .16);
                    @include rpmin(md){
                        box-shadow: none;
                    }
                    &:hover{
                        box-shadow: 1px 3px 10px rgba($color: #000000, $alpha: .16);
                        span{
                            @include bold;
                        }
                        .btn-see-more{
                            .line{
                                width: 20px;
                                @include rp(tl){
                                    width: 18px;
                                }
                                &::before{
                                    width: 100%;
                                    transform-origin: right;
                                    transform: translateY(-50%) rotate(25deg);
                                    border-top: 2px solid map-get($color, main);
                                }
                                &::after{
                                    width: 100%;
                                    transform-origin: right;
                                    transform: translateY(-50%) rotate(-25deg);
                                    border-top: 2px solid map-get($color, main);
                                }
                            }
                        }
                    }
                    @keyframes slide {
                        0%{
                            width:  0;
                        }
                        100%{
                            width: 5.6rem;
                        }
                    }
                }
            }
        }
    }
}