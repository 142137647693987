.swiper-container{
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: hidden;
    list-style: none;
    padding: 0;
    z-index: 1;
}

.swiper-button-lock{
    display: none;
}

.nav-custom{
    .nav{
        margin-top: 2vh;
        display: flex;
        align-items: center;
        // width: 100%;
        // padding: 0 10px; 
        position: relative;
        // right: 0;
        justify-content: flex-end;
        .swiper-button-next,.swiper-button-prev{
            position: relative;
            // top: 100%;
            width: auto;
            left: unset;
            right: unset;
            margin-left: 2vw;

            height: auto;
            margin-top: 0;
            // margin-left: auto;
            &::after{
                display: none;
            }

            svg{
                width: 40px;
                height: 20px;
                @include rpmin(xxl){
                    width: 62.048px;
                    height: 25.789px;
                }
            }
        }
    }

    .swiper{
        &-slide{
            height: auto;

            &>*{
                height: 100%;
            }
        }
    }
}

.swiper-pagination{
    margin-left: -15px;
    padding: 5px 0;
   .swiper-pagination-bullet{
       background: map-get($color, black-3 );
       opacity: 1;
       margin: 0 15px !important;

       height: 6px;
       width: 6px;
       transform: unset;
       
       @include rpmin(lg){
           height: 10px;
           width: 10px;
       }
       &.swiper-pagination-bullet-active{
        transform: scale(1.6);
        background: map-get($color, main );
       }
   }
}
// .swiper-slide-active{
//     .btn-see-more{
        
//         span{
//             @include bold ;
//         }
//         .line{
//             width: 20px;
//             @include rp(tl){
//                 width: 18px;
//             }
//             &::before{
//                 width: 100%;
//                 transform-origin: right;
//                 transform: translateY(-50%) rotate(25deg);
//                 border-top: 2px solid map-get($color, main);
//             }
//             &::after{
//                 width: 100%;
//                 transform-origin: right;
//                 transform: translateY(-50%) rotate(-25deg);
//                 border-top: 2px solid map-get($color, main);
//             }
//         }
            
           
//     }
// }