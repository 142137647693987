.hash2{
    @include rp(tx){
        max-width: 460px !important;
    }
    display: flex;
    flex-direction: column;

    &__img{
        position: relative;
        padding-top: map-get($ratio, 4_3);

        &>*{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            background-size: cover;
            object-position: center;
            background-position: center;
            background-repeat: no-repeat;
        }
    }

    &-content{
        padding-top: 48px;
        @include rp(tx){
            padding-top: 32px;
        }
        @include rp(mx){
            padding-top: 24px;
        }

        flex: 1;

        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        &__title{
            flex: 1;
            @include rp(tl){
                flex: 0 0 100%;
            }
            &>*{
                @include size(s32);
                @include medium;
                color: map-get($color, black-2);
            }
        }

        &__tag{
            @include rp(tl){
                flex: 0 0 100%;
                margin-top: 4px;
            }
            display: flex;
            align-items: flex-end;
            flex-direction: row;
            flex-wrap: wrap;

            &>*{
                @include size(s24);
                @include regular;
                color: map-get($color, main);
            }
        }
        
        &-btn{
            margin-top: 20px;
            @include rp(tl){
                margin-top: 18px;
            }

            flex: 0 0 100%;
        }
    }
}