.news1{
    &-container{
        padding-top: 60px;
        @include fp{
            padding-top: 24px;
        }
    }
    &__title{
        width: 100%;
        text-align: center;
    }

    &-cards{
        padding-top: 60px;
        
        margin-right: -20px;
        margin-left: -20px;
        margin-top: -40px;
        
        width: calc(100% + 40px);
        
        &>*{
            padding-right: 20px;
            padding-left: 20px;
            padding-top: 40px;
        }

        align-items: stretch;
    }

    .swiper-slide{
        height: auto;

        display: flex;
        flex-direction: column;
        &>*{
            flex: 1;
        }
    }
}