.about-3{
    opacity: 0;
    transition: .3s all linear;
}

.fp-viewing-2{
    .about-3{
        opacity: 1;
    }
}


#about-3{
    // height: 100%;
    // width: 100%;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    // vertical-align: middle;

    .about-3{
        &__content{
            // padding-top: 70px;
            // margin-top: 70px;
            padding-top: 5vh;
            margin-top: 5vh;

            @include rp(ps){
                // padding-top: 50px;
                // margin-top: 50px;
                padding-top: 3.5vh;
                margin-top: 3.5vh;
            }
            width: 100%;
            position: relative;
            &::after{
                position: absolute;
                content: "";
                top: 0;
                left: 0;
                width: 100%;
                height: 1px;
                background-color: map-get($color, main );
            }

            .swiper-container{
                padding: 6px 3px;
                margin-left: -1rem;
                margin-right: -1rem;
                @include rpmin(yl){
                    margin-left: -1.5rem;
                    margin-right: -1.5rem;
                }
            }

            .swiper-slide{
                height: auto;
            }

            &-block{
                width: 100%;
                padding-left: 1rem;
                padding-right: 1rem;
                
                @include rpmin(yl){
                    padding-left: 1.5rem;
                    padding-right: 1.5rem;
                }
            }

            &-img{
                position: relative;
                width: 100%;
                height: 0;
                padding-top: 45%;
                @include rpmin(yl){
                    padding-top: 33.333%;
                }
                img{
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: contain;

                    padding: 7% 12%;

                    box-shadow: 0 3px 6px rgba(0,0,0,.16);
                }
            }
        }
    }
}