/*!
* source: https://getbootstrap.com/
* Bootstrap Grid v5.0.2 (https://getbootstrap.com/)
* Copyright 2011-2021 The Bootstrap Authors
* Copyright 2011-2021 Twitter, Inc.
* Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
*/
@import url("https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
.container {
  --bs-gutter-x: -1.5rem;
  width: 96%;
  margin-right: auto;
  margin-left: auto;
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; }

@media (min-width: 576px) {
  .container {
    --bs-gutter-x: 0;
    max-width: 540px; } }

@media (min-width: 768px) {
  .container {
    --bs-gutter-x: 0;
    max-width: 720px; } }

@media (min-width: 992px) {
  .container {
    --bs-gutter-x: 0;
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container {
    --bs-gutter-x: 0;
    max-width: 1140px; } }

@media (min-width: 1400px) {
  .container {
    --bs-gutter-x: 0;
    max-width: 1320px; } }

@media (min-width: 1680px) {
  .container {
    --bs-gutter-x: 0;
    max-width: 84%; } }

.col {
  flex: 1 0 0%; }

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) * -.5);
  margin-left: calc(var(--bs-gutter-x) * -.5);
  width: calc(100% + (var(--bs-gutter-x) * 0.5 * 2)); }
  .row > * {
    box-sizing: border-box;
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-top: var(--bs-gutter-y); }
  .row-cols-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%; }
  .row-cols-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%; }

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%; }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 91.66667%; }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 83.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 75%; }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 66.66667%; }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 58.33333%; }
  .row-cols-sm-7 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-sm-8 > * {
    flex: 0 0 auto;
    width: 41.66667%; }
  .row-cols-sm-9 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-sm-10 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-sm-11 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .row-cols-sm-12 > * {
    flex: 0 0 auto;
    width: 8.33333%; } }

@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%; }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 91.66667%; }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 83.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 75%; }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 66.66667%; }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 58.33333%; }
  .row-cols-md-7 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-md-8 > * {
    flex: 0 0 auto;
    width: 41.66667%; }
  .row-cols-md-9 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-md-10 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-md-11 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .row-cols-md-12 > * {
    flex: 0 0 auto;
    width: 8.33333%; } }

@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%; }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 91.66667%; }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 83.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 75%; }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 66.66667%; }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 58.33333%; }
  .row-cols-lg-7 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-lg-8 > * {
    flex: 0 0 auto;
    width: 41.66667%; }
  .row-cols-lg-9 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-lg-10 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-lg-11 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .row-cols-lg-12 > * {
    flex: 0 0 auto;
    width: 8.33333%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%; }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 91.66667%; }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 83.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 75%; }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 66.66667%; }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 58.33333%; }
  .row-cols-xl-7 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-xl-8 > * {
    flex: 0 0 auto;
    width: 41.66667%; }
  .row-cols-xl-9 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-xl-10 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-xl-11 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .row-cols-xl-12 > * {
    flex: 0 0 auto;
    width: 8.33333%; } }

@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%; }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 91.66667%; }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 83.33333%; }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 75%; }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 66.66667%; }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 58.33333%; }
  .row-cols-xxl-7 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-xxl-8 > * {
    flex: 0 0 auto;
    width: 41.66667%; }
  .row-cols-xxl-9 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-xxl-10 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-xxl-11 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .row-cols-xxl-12 > * {
    flex: 0 0 auto;
    width: 8.33333%; } }

@media (min-width: 1680px) {
  .col-yl {
    flex: 1 0 0%; }
  .row-cols-yl-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-yl-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-yl-2 > * {
    flex: 0 0 auto;
    width: 91.66667%; }
  .row-cols-yl-3 > * {
    flex: 0 0 auto;
    width: 83.33333%; }
  .row-cols-yl-4 > * {
    flex: 0 0 auto;
    width: 75%; }
  .row-cols-yl-5 > * {
    flex: 0 0 auto;
    width: 66.66667%; }
  .row-cols-yl-6 > * {
    flex: 0 0 auto;
    width: 58.33333%; }
  .row-cols-yl-7 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-yl-8 > * {
    flex: 0 0 auto;
    width: 41.66667%; }
  .row-cols-yl-9 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-yl-10 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-yl-11 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .row-cols-yl-12 > * {
    flex: 0 0 auto;
    width: 8.33333%; } }

.col-auto {
  flex: 0 0 auto;
  width: auto; }

.col-1 {
  flex: 0 0 auto;
  width: 8.33333%; }

.column-1 {
  flex: 0 0 auto;
  width: 8.33333%; }

.col-2 {
  flex: 0 0 auto;
  width: 16.66667%; }

.column-2 {
  flex: 0 0 auto;
  width: 16.66667%; }

.col-3 {
  flex: 0 0 auto;
  width: 25%; }

.column-3 {
  flex: 0 0 auto;
  width: 25%; }

.col-4 {
  flex: 0 0 auto;
  width: 33.33333%; }

.column-4 {
  flex: 0 0 auto;
  width: 33.33333%; }

.col-5 {
  flex: 0 0 auto;
  width: 41.66667%; }

.column-5 {
  flex: 0 0 auto;
  width: 41.66667%; }

.col-6 {
  flex: 0 0 auto;
  width: 50%; }

.column-6 {
  flex: 0 0 auto;
  width: 50%; }

.col-7 {
  flex: 0 0 auto;
  width: 58.33333%; }

.column-7 {
  flex: 0 0 auto;
  width: 58.33333%; }

.col-8 {
  flex: 0 0 auto;
  width: 66.66667%; }

.column-8 {
  flex: 0 0 auto;
  width: 66.66667%; }

.col-9 {
  flex: 0 0 auto;
  width: 75%; }

.column-9 {
  flex: 0 0 auto;
  width: 75%; }

.col-10 {
  flex: 0 0 auto;
  width: 83.33333%; }

.column-10 {
  flex: 0 0 auto;
  width: 83.33333%; }

.col-11 {
  flex: 0 0 auto;
  width: 91.66667%; }

.column-11 {
  flex: 0 0 auto;
  width: 91.66667%; }

.col-12 {
  flex: 0 0 auto;
  width: 100%; }

.column-12 {
  flex: 0 0 auto;
  width: 100%; }

.offset-0 {
  margin-left: 0%; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

.gx-0 {
  --bs-gutter-x: 0; }

.gy-0 {
  --bs-gutter-y: 0; }

.gx-1 {
  --bs-gutter-x: 0.25rem; }

.gy-1 {
  --bs-gutter-y: 0.25rem; }

.gx-2 {
  --bs-gutter-x: 0.5rem; }

.gy-2 {
  --bs-gutter-y: 0.5rem; }

.gx-3 {
  --bs-gutter-x: 1rem; }

.gy-3 {
  --bs-gutter-y: 1rem; }

.gx-4 {
  --bs-gutter-x: 1.5rem; }

.gy-4 {
  --bs-gutter-y: 1.5rem; }

.gx-5 {
  --bs-gutter-x: 3rem; }

.gy-5 {
  --bs-gutter-y: 3rem; }

.gx-6 {
  --bs-gutter-x: 5rem; }

.gy-6 {
  --bs-gutter-y: 5rem; }

@media (min-width: 576px) {
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto; }
  .column-sm-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .column-sm-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .column-sm-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%; }
  .column-sm-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .column-sm-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .column-sm-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%; }
  .column-sm-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .column-sm-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .column-sm-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%; }
  .column-sm-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .column-sm-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .column-sm-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%; }
  .column-sm-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-sm-0 > * {
    margin-left: 0%; }
  .offset-sm-1 > * {
    margin-left: 8.33333%; }
  .offset-sm-2 > * {
    margin-left: 16.66667%; }
  .offset-sm-3 > * {
    margin-left: 25%; }
  .offset-sm-4 > * {
    margin-left: 33.33333%; }
  .offset-sm-5 > * {
    margin-left: 41.66667%; }
  .offset-sm-6 > * {
    margin-left: 50%; }
  .offset-sm-7 > * {
    margin-left: 58.33333%; }
  .offset-sm-8 > * {
    margin-left: 66.66667%; }
  .offset-sm-9 > * {
    margin-left: 75%; }
  .offset-sm-10 > * {
    margin-left: 83.33333%; }
  .offset-sm-11 > * {
    margin-left: 91.66667%; }
  .gx-sm-0 {
    --bs-gutter-x: 0; }
  .gy-sm-0 {
    --bs-gutter-y: 0; }
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem; }
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem; }
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem; }
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem; }
  .gx-sm-3 {
    --bs-gutter-x: 1rem; }
  .gy-sm-3 {
    --bs-gutter-y: 1rem; }
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem; }
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem; }
  .gx-sm-5 {
    --bs-gutter-x: 3rem; }
  .gy-sm-5 {
    --bs-gutter-y: 3rem; }
  .gx-sm-6 {
    --bs-gutter-x: 5rem; }
  .gy-sm-6 {
    --bs-gutter-y: 5rem; } }

@media (min-width: 768px) {
  .col-md-auto {
    flex: 0 0 auto;
    width: auto; }
  .column-md-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .column-md-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .column-md-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%; }
  .column-md-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .column-md-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .column-md-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%; }
  .column-md-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .column-md-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .column-md-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%; }
  .column-md-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .column-md-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .column-md-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%; }
  .column-md-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-md-0 > * {
    margin-left: 0%; }
  .offset-md-1 > * {
    margin-left: 8.33333%; }
  .offset-md-2 > * {
    margin-left: 16.66667%; }
  .offset-md-3 > * {
    margin-left: 25%; }
  .offset-md-4 > * {
    margin-left: 33.33333%; }
  .offset-md-5 > * {
    margin-left: 41.66667%; }
  .offset-md-6 > * {
    margin-left: 50%; }
  .offset-md-7 > * {
    margin-left: 58.33333%; }
  .offset-md-8 > * {
    margin-left: 66.66667%; }
  .offset-md-9 > * {
    margin-left: 75%; }
  .offset-md-10 > * {
    margin-left: 83.33333%; }
  .offset-md-11 > * {
    margin-left: 91.66667%; }
  .gx-md-0 {
    --bs-gutter-x: 0; }
  .gy-md-0 {
    --bs-gutter-y: 0; }
  .gx-md-1 {
    --bs-gutter-x: 0.25rem; }
  .gy-md-1 {
    --bs-gutter-y: 0.25rem; }
  .gx-md-2 {
    --bs-gutter-x: 0.5rem; }
  .gy-md-2 {
    --bs-gutter-y: 0.5rem; }
  .gx-md-3 {
    --bs-gutter-x: 1rem; }
  .gy-md-3 {
    --bs-gutter-y: 1rem; }
  .gx-md-4 {
    --bs-gutter-x: 1.5rem; }
  .gy-md-4 {
    --bs-gutter-y: 1.5rem; }
  .gx-md-5 {
    --bs-gutter-x: 3rem; }
  .gy-md-5 {
    --bs-gutter-y: 3rem; }
  .gx-md-6 {
    --bs-gutter-x: 5rem; }
  .gy-md-6 {
    --bs-gutter-y: 5rem; } }

@media (min-width: 992px) {
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto; }
  .column-lg-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .column-lg-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .column-lg-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%; }
  .column-lg-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .column-lg-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .column-lg-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%; }
  .column-lg-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .column-lg-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .column-lg-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%; }
  .column-lg-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .column-lg-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .column-lg-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%; }
  .column-lg-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-lg-0 > * {
    margin-left: 0%; }
  .offset-lg-1 > * {
    margin-left: 8.33333%; }
  .offset-lg-2 > * {
    margin-left: 16.66667%; }
  .offset-lg-3 > * {
    margin-left: 25%; }
  .offset-lg-4 > * {
    margin-left: 33.33333%; }
  .offset-lg-5 > * {
    margin-left: 41.66667%; }
  .offset-lg-6 > * {
    margin-left: 50%; }
  .offset-lg-7 > * {
    margin-left: 58.33333%; }
  .offset-lg-8 > * {
    margin-left: 66.66667%; }
  .offset-lg-9 > * {
    margin-left: 75%; }
  .offset-lg-10 > * {
    margin-left: 83.33333%; }
  .offset-lg-11 > * {
    margin-left: 91.66667%; }
  .gx-lg-0 {
    --bs-gutter-x: 0; }
  .gy-lg-0 {
    --bs-gutter-y: 0; }
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem; }
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem; }
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem; }
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem; }
  .gx-lg-3 {
    --bs-gutter-x: 1rem; }
  .gy-lg-3 {
    --bs-gutter-y: 1rem; }
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem; }
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem; }
  .gx-lg-5 {
    --bs-gutter-x: 3rem; }
  .gy-lg-5 {
    --bs-gutter-y: 3rem; }
  .gx-lg-6 {
    --bs-gutter-x: 5rem; }
  .gy-lg-6 {
    --bs-gutter-y: 5rem; } }

@media (min-width: 1200px) {
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto; }
  .column-xl-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .column-xl-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .column-xl-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%; }
  .column-xl-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .column-xl-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .column-xl-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%; }
  .column-xl-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .column-xl-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .column-xl-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%; }
  .column-xl-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .column-xl-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .column-xl-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%; }
  .column-xl-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-xl-0 > * {
    margin-left: 0%; }
  .offset-xl-1 > * {
    margin-left: 8.33333%; }
  .offset-xl-2 > * {
    margin-left: 16.66667%; }
  .offset-xl-3 > * {
    margin-left: 25%; }
  .offset-xl-4 > * {
    margin-left: 33.33333%; }
  .offset-xl-5 > * {
    margin-left: 41.66667%; }
  .offset-xl-6 > * {
    margin-left: 50%; }
  .offset-xl-7 > * {
    margin-left: 58.33333%; }
  .offset-xl-8 > * {
    margin-left: 66.66667%; }
  .offset-xl-9 > * {
    margin-left: 75%; }
  .offset-xl-10 > * {
    margin-left: 83.33333%; }
  .offset-xl-11 > * {
    margin-left: 91.66667%; }
  .gx-xl-0 {
    --bs-gutter-x: 0; }
  .gy-xl-0 {
    --bs-gutter-y: 0; }
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem; }
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem; }
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem; }
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem; }
  .gx-xl-3 {
    --bs-gutter-x: 1rem; }
  .gy-xl-3 {
    --bs-gutter-y: 1rem; }
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem; }
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem; }
  .gx-xl-5 {
    --bs-gutter-x: 3rem; }
  .gy-xl-5 {
    --bs-gutter-y: 3rem; }
  .gx-xl-6 {
    --bs-gutter-x: 5rem; }
  .gy-xl-6 {
    --bs-gutter-y: 5rem; } }

@media (min-width: 1400px) {
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto; }
  .column-xxl-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .column-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .column-xxl-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%; }
  .column-xxl-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .column-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .column-xxl-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%; }
  .column-xxl-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .column-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .column-xxl-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%; }
  .column-xxl-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .column-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .column-xxl-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%; }
  .column-xxl-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-xxl-0 > * {
    margin-left: 0%; }
  .offset-xxl-1 > * {
    margin-left: 8.33333%; }
  .offset-xxl-2 > * {
    margin-left: 16.66667%; }
  .offset-xxl-3 > * {
    margin-left: 25%; }
  .offset-xxl-4 > * {
    margin-left: 33.33333%; }
  .offset-xxl-5 > * {
    margin-left: 41.66667%; }
  .offset-xxl-6 > * {
    margin-left: 50%; }
  .offset-xxl-7 > * {
    margin-left: 58.33333%; }
  .offset-xxl-8 > * {
    margin-left: 66.66667%; }
  .offset-xxl-9 > * {
    margin-left: 75%; }
  .offset-xxl-10 > * {
    margin-left: 83.33333%; }
  .offset-xxl-11 > * {
    margin-left: 91.66667%; }
  .gx-xxl-0 {
    --bs-gutter-x: 0; }
  .gy-xxl-0 {
    --bs-gutter-y: 0; }
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem; }
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem; }
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem; }
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem; }
  .gx-xxl-3 {
    --bs-gutter-x: 1rem; }
  .gy-xxl-3 {
    --bs-gutter-y: 1rem; }
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem; }
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem; }
  .gx-xxl-5 {
    --bs-gutter-x: 3rem; }
  .gy-xxl-5 {
    --bs-gutter-y: 3rem; }
  .gx-xxl-6 {
    --bs-gutter-x: 5rem; }
  .gy-xxl-6 {
    --bs-gutter-y: 5rem; } }

@media (min-width: 1680px) {
  .col-yl-auto {
    flex: 0 0 auto;
    width: auto; }
  .column-yl-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-yl-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .column-yl-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-yl-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .column-yl-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-yl-3 {
    flex: 0 0 auto;
    width: 25%; }
  .column-yl-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-yl-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .column-yl-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-yl-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .column-yl-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-yl-6 {
    flex: 0 0 auto;
    width: 50%; }
  .column-yl-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-yl-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .column-yl-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-yl-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .column-yl-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-yl-9 {
    flex: 0 0 auto;
    width: 75%; }
  .column-yl-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-yl-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .column-yl-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-yl-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .column-yl-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-yl-12 {
    flex: 0 0 auto;
    width: 100%; }
  .column-yl-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-yl-0 > * {
    margin-left: 0%; }
  .offset-yl-1 > * {
    margin-left: 8.33333%; }
  .offset-yl-2 > * {
    margin-left: 16.66667%; }
  .offset-yl-3 > * {
    margin-left: 25%; }
  .offset-yl-4 > * {
    margin-left: 33.33333%; }
  .offset-yl-5 > * {
    margin-left: 41.66667%; }
  .offset-yl-6 > * {
    margin-left: 50%; }
  .offset-yl-7 > * {
    margin-left: 58.33333%; }
  .offset-yl-8 > * {
    margin-left: 66.66667%; }
  .offset-yl-9 > * {
    margin-left: 75%; }
  .offset-yl-10 > * {
    margin-left: 83.33333%; }
  .offset-yl-11 > * {
    margin-left: 91.66667%; }
  .gx-yl-0 {
    --bs-gutter-x: 0; }
  .gy-yl-0 {
    --bs-gutter-y: 0; }
  .gx-yl-1 {
    --bs-gutter-x: 0.25rem; }
  .gy-yl-1 {
    --bs-gutter-y: 0.25rem; }
  .gx-yl-2 {
    --bs-gutter-x: 0.5rem; }
  .gy-yl-2 {
    --bs-gutter-y: 0.5rem; }
  .gx-yl-3 {
    --bs-gutter-x: 1rem; }
  .gy-yl-3 {
    --bs-gutter-y: 1rem; }
  .gx-yl-4 {
    --bs-gutter-x: 1.5rem; }
  .gy-yl-4 {
    --bs-gutter-y: 1.5rem; }
  .gx-yl-5 {
    --bs-gutter-x: 3rem; }
  .gy-yl-5 {
    --bs-gutter-y: 3rem; }
  .gx-yl-6 {
    --bs-gutter-x: 5rem; }
  .gy-yl-6 {
    --bs-gutter-y: 5rem; } }

/*# sourceMappingURL=bootstrap-grid.css.map */
@keyframes rotateAround3Item1 {
  0% {
    stroke-width: 0; }
  65% {
    stroke-width: 0.3; }
  70% {
    stroke-width: 0.7; }
  80% {
    stroke-width: 0.75; }
  90% {
    stroke-width: 0.7; }
  95% {
    stroke-width: 0.3; }
  100% {
    stroke-width: 0; } }

@keyframes rotate360deg {
  to {
    transform: translate(-50%, -50%) rotate(360deg); } }

@keyframes hoverBox {
  0% {
    opacity: 1;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%); }
  100% {
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
    opacity: 0.5; } }

@keyframes hoverBox2 {
  0% {
    background-color: #4E8848;
    opacity: 0.5;
    clip-path: polygon(0 0, 100% 0, 0 0, 0% 100%); }
  100% {
    background-color: #4E8848;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    opacity: 1; } }

@keyframes StrokeLineDown {
  0% {
    stroke-dashoffset: 0; }
  100% {
    stroke-dashoffset: -200; } }

@keyframes StrokeLineUp {
  0% {
    stroke-dashoffset: 0; }
  100% {
    stroke-dashoffset: 200; } }

@keyframes bounceInBottom {
  0% {
    opacity: 0;
    transform: translate3d(0, -700px, 0); }
  75% {
    opacity: 1;
    transform: translate3d(0, -25px, 0); }
  80% {
    transform: translate3d(0, 5px, 0); }
  90% {
    transform: translate3d(0, -5px, 0); }
  to {
    transform: translateZ(0);
    opacity: 1; } }

@keyframes bounceOpacity {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes pieces {
  0% {
    transform: translateX(10px) translateY(0px); }
  50% {
    transform: translateX(-5px) translateY(10px); }
  100% {
    transform: translateX(10px) translateY(0px); } }

@keyframes piecesRotate {
  0% {
    transform: rotate(5deg); }
  50% {
    transform: rotate(-5deg); }
  100% {
    transform: rotate(5deg); } }

@keyframes piecesMouse {
  0% {
    transform: translate(0px, 0px); }
  30% {
    transform: translate(10px, -10px); }
  60% {
    transform: translate(0px, 0px); }
  100% {
    transform: translate(0px, 0px); } }

@keyframes line_through {
  to {
    stroke-dashoffset: -1000; } }

@keyframes hair_flow {
  0% {
    transform: rotate(15deg);
    -o-transform: rotate(15deg);
    -ms-transform: rotate(15deg);
    -webkit-transform: rotate(15deg);
    -moz-transform: rotate(15deg); }
  50% {
    transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg); }
  100% {
    transform: rotate(15deg);
    -o-transform: rotate(15deg);
    -ms-transform: rotate(15deg);
    -webkit-transform: rotate(15deg);
    -moz-transform: rotate(15deg); } }

@keyframes hand_flow {
  0% {
    transform: rotate(10deg);
    -o-transform: rotate(10deg);
    -ms-transform: rotate(10deg);
    -webkit-transform: rotate(10deg);
    -moz-transform: rotate(10deg); }
  50% {
    transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg); }
  100% {
    transform: rotate(10deg);
    -o-transform: rotate(10deg);
    -ms-transform: rotate(10deg);
    -webkit-transform: rotate(10deg);
    -moz-transform: rotate(10deg); } }

@keyframes flow_index {
  0% {
    transform: rotate(0deg) translate(-25px) rotate(0deg); }
  100% {
    transform: rotate(360deg) translate(-25px) rotate(-360deg); } }

@keyframes fadein_computer {
  0% {
    transform: scaleY(0.96); }
  40% {
    transform: scaleY(1); }
  100% {
    transform: scaleY(0.96); } }

@keyframes fadein {
  0% {
    transform: translateY(-10px); }
  40% {
    transform: translateY(20px); }
  100% {
    transform: translateY(-10px); } }

@keyframes fadein_paper {
  0% {
    transform: translateY(-4px); }
  40% {
    transform: translateY(4px); }
  100% {
    transform: translateY(-4px); } }

@keyframes circle_handshake_rotate {
  from {
    transform: rotate(360deg); }
  to {
    transform: rotate(0deg); } }

@keyframes circle_handshake {
  0% {
    transform: scale(0.9); }
  50% {
    transform: scale(1.01); }
  100% {
    transform: scale(0.9); } }

@keyframes phoneShake {
  0% {
    transform: rotate(0deg); }
  10% {
    transform: rotate(-20deg); }
  20% {
    transform: rotate(20deg); }
  30% {
    transform: rotate(-20deg); }
  40% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(0deg); } }

@keyframes opacityTextLogo {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes animateLogo {
  0% {
    stroke-dashoffset: 0; }
  40% {
    stroke-dashoffset: 650; }
  70% {
    stroke-dashoffset: 1300;
    fill: transparent; }
  100% {
    stroke-dashoffset: 1300;
    fill: #fff; } }

@keyframes animatedMutiply {
  0%, 35%, 100% {
    transform: translateX(0) rotate(0); }
  70% {
    transform: translateX(20vw) rotate(360deg); } }

@keyframes animatedArrowRight {
  0% {
    opacity: 0;
    transform: translateX(-40px); }
  50% {
    opacity: 1; }
  100% {
    opacity: 0;
    transform: translateX(40px); } }

@keyframes animatedArrowUp {
  0% {
    opacity: 0;
    transform: translateY(40px); }
  50% {
    opacity: 1; }
  100% {
    opacity: 0;
    transform: translateY(-40px); } }

@keyframes rotate360 {
  0% {
    opacity: 0;
    transform: rotateZ(0) scale(0); }
  20% {
    opacity: 1;
    transform: rotateZ(0) scale(1); }
  100% {
    opacity: 0;
    transform: rotateZ(360deg); } }

@keyframes rotate90 {
  0% {
    transform: rotateZ(0); }
  40% {
    transform: rotateZ(120deg); }
  90% {
    transform: rotateZ(-30deg); }
  100% {
    transform: rotateZ(0deg); } }

@keyframes fillCircle {
  0%, 35%, 45%, 85%, 95%, 100% {
    filter: drop-shadow(0 0 0px #91c73e); }
  40%, 90% {
    filter: drop-shadow(0 0 15px #91c73e) drop-shadow(0 0 20px #91c73e) drop-shadow(0 0 25px #91c73e) drop-shadow(0 0 30px #91c73e) drop-shadow(0 0 35px #91c73e); } }

@keyframes STAR {
  0% {
    fill: powderblue;
    transform: scale(1); }
  10% {
    fill: #b0e5de; }
  20% {
    fill: #b0e5cc; }
  30% {
    fill: #cce5b0; }
  40% {
    fill: #dae5b0; }
  50% {
    fill: #e5ddb0;
    transform: scale(10); }
  100% {
    transform: scale(1);
    fill: powderblue; } }

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0; }

html {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-size: 52.5%; }
  @media (min-width: 769px) {
    html {
      font-size: 57.5%; } }
  @media (min-width: 1281px) {
    html {
      font-size: 62.5%; } }

body {
  font-size: 1.6rem;
  line-height: 1.2;
  background-color: #ffffff;
  color: #000;
  font-family: "Barlow";
  font-weight: 400;
  scrollbar-width: none;
  -ms-overflow-style: none; }
  body.hiddenScroll {
    height: 100vh !important;
    overflow: hidden !important; }

main {
  position: relative;
  width: 100%; }

#fullpage .section .scrollText {
  height: 220px;
  overflow: auto; }

main,
#banner,
#bannerSub,
#breadcrumb {
  margin-left: auto;
  margin-right: auto; }

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none; }

.hidden {
  display: none; }

address {
  margin-bottom: 0;
  font-style: normal;
  line-height: inherit; }

b,
strong {
  font-weight: bolder;
  font-weight: 700; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

a {
  text-decoration: none;
  background-color: transparent; }

pre,
code,
kbd,
samp {
  font-family: inherit;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 0;
  overflow: auto; }

figure {
  margin: 0; }

img {
  border-style: none;
  max-width: 100%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0;
  padding-bottom: 0;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0; }

button {
  border-radius: 0; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

select {
  word-wrap: normal; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

*:focus,
*:active {
  outline: none; }

.p-line-clamp-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden; }

.p-line-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden; }

.p-line-clamp-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden; }

.p-line-clamp-4 {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden; }

.p-line-clamp-5 {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden; }

.p-line-clamp-6 {
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden; }

.p-line-clamp-7 {
  display: -webkit-box;
  -webkit-line-clamp: 7;
  -webkit-box-orient: vertical;
  overflow: hidden; }

.p-line-clamp-8 {
  display: -webkit-box;
  -webkit-line-clamp: 8;
  -webkit-box-orient: vertical;
  overflow: hidden; }

.p-line-clamp-9 {
  display: -webkit-box;
  -webkit-line-clamp: 9;
  -webkit-box-orient: vertical;
  overflow: hidden; }

.p-line-clamp-10 {
  display: -webkit-box;
  -webkit-line-clamp: 10;
  -webkit-box-orient: vertical;
  overflow: hidden; }

.m-auto {
  margin-left: auto;
  margin-right: auto; }

.ml-auto {
  margin-left: auto; }

.mr-auto {
  margin-right: auto; }

.jc-center {
  justify-content: center; }

.thin {
  font-weight: 100 !important; }

.extra-light {
  font-weight: 200 !important; }

.light {
  font-weight: 300 !important; }

.regular {
  font-weight: 400 !important; }

.normal {
  font-weight: 400 !important; }

.medium {
  font-weight: 500 !important; }

.semi-bold {
  font-weight: 600 !important; }

.bold {
  font-weight: 700 !important; }

.extra-bold {
  font-weight: 800 !important; }

.ultra-bold {
  font-weight: 900; }

.content-format p, .content-format span {
  color: #292929;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.25; }
  @media (min-width: 992px) {
    .content-format p, .content-format span {
      font-size: 1.7rem; } }
  @media (min-width: 1200px) {
    .content-format p, .content-format span {
      font-size: 1.8rem; } }

.content-format .alt {
  font-weight: 700;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  display: block;
  width: calc(66.66667% - 40px); }
  @media (max-width: 1280px) {
    .content-format .alt {
      width: calc(83.33333% - 40px); } }
  @media (max-width: 640px) {
    .content-format .alt {
      width: calc(100% - 40px); } }

.content-format img {
  margin-left: auto;
  margin-right: auto;
  display: block;
  height: auto;
  object-fit: contain;
  width: 66.66667%; }
  @media (max-width: 1280px) {
    .content-format img {
      width: 83.33333%; } }
  @media (max-width: 640px) {
    .content-format img {
      width: 100%; } }

.content-format img + .alt {
  margin-top: 1.8rem; }

.content-format p + p {
  margin-top: 1.8rem; }

.content-format p.alt + p {
  margin-top: 4rem; }

.content-format p + img {
  margin-top: 4rem; }

.btn-see-more {
  display: inline-flex;
  flex-direction: row;
  align-items: stretch;
  font-size: 2rem;
  color: #4E8848; }
  @media (min-width: 992px) {
    .btn-see-more {
      font-size: 2.2rem; } }
  @media (min-width: 1200px) {
    .btn-see-more {
      font-size: 2.4rem; } }
  .btn-see-more span {
    order: 3;
    font-weight: 400;
    padding-left: 16px; }
    @media (max-width: 1024px) {
      .btn-see-more span {
        padding-left: 8px; } }
  .btn-see-more:hover span {
    font-weight: 700; }
  .btn-see-more:hover .line {
    width: 20px; }
    @media (max-width: 1024px) {
      .btn-see-more:hover .line {
        width: 18px; } }
    .btn-see-more:hover .line::before {
      width: 100%;
      transform-origin: right;
      transform: translateY(-50%) rotate(25deg);
      border-top: 2px solid #4E8848; }
    .btn-see-more:hover .line::after {
      width: 100%;
      transform-origin: right;
      transform: translateY(-50%) rotate(-25deg);
      border-top: 2px solid #4E8848; }
  .btn-see-more .line {
    padding: 0;
    margin: 0;
    position: relative;
    display: block;
    width: 58px;
    transition: .7s all ease-in-out; }
    @media (max-width: 1024px) {
      .btn-see-more .line {
        width: 48px; } }
    .btn-see-more .line::before {
      transition: .7s all ease-in-out;
      position: absolute;
      content: "";
      top: 50%;
      left: 0%;
      width: 50%;
      border-top: 1px solid #4E8848;
      transform: translateY(-50%); }
    .btn-see-more .line::after {
      transition: .7s all ease-in-out;
      position: absolute;
      content: "";
      top: 50%;
      right: 0;
      width: 50%;
      height: 0px;
      border-top: 1px solid #4E8848;
      transform: translateY(-50%); }

.time {
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  flex-wrap: wrap;
  width: 72px;
  background-color: #4E8848; }
  .time > * {
    height: 30px;
    color: white;
    font-weight: 400;
    font-size: 1.6rem;
    display: flex;
    justify-content: center;
    align-items: center; }
    @media (min-width: 992px) {
      .time > * {
        font-size: 1.7rem; } }
    @media (min-width: 1200px) {
      .time > * {
        font-size: 1.8rem; } }
  .time .day {
    order: 1;
    flex: 0 0 50%;
    width: 50%;
    max-width: 50%; }
  .time .month {
    order: 2;
    flex: 0 0 50%;
    width: 50%;
    max-width: 50%;
    position: relative; }
    .time .month::before {
      content: "";
      position: absolute;
      top: calc(50% + 2px);
      left: 0;
      transform: translate(-50%, -50%);
      width: 6px;
      height: 0;
      border-top: 2px solid white; }
  .time .year {
    order: 3;
    flex: 0 0 100%;
    width: 100%;
    max-width: 100%;
    position: relative; }
    .time .year::before {
      content: "";
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 80%;
      height: 0;
      border-top: 2px solid white; }

.service-1 {
  margin-top: 0; }
  @media (max-width: 1279px), (max-height: 539px) {
    .service-1 {
      margin-top: 24px; } }

.service-2,
.service-3,
.service-4,
.service-5,
.contact2,
.brn-index-8--padding,
.about-2,
.about-3,
.brn-index-services,
.brn-index-project,
.brn-index-process,
.brn-index-client,
.brn-index-contact {
  margin-top: 0; }
  @media (max-width: 1279px), (max-height: 539px) {
    .service-2,
    .service-3,
    .service-4,
    .service-5,
    .contact2,
    .brn-index-8--padding,
    .about-2,
    .about-3,
    .brn-index-services,
    .brn-index-project,
    .brn-index-process,
    .brn-index-client,
    .brn-index-contact {
      margin-top: 64px; } }
  @media (min-width: 768px) {
    .service-2,
    .service-3,
    .service-4,
    .service-5,
    .contact2,
    .brn-index-8--padding,
    .about-2,
    .about-3,
    .brn-index-services,
    .brn-index-project,
    .brn-index-process,
    .brn-index-client,
    .brn-index-contact {
      margin-top: 64px; } }

#fullpage .section {
  overflow: hidden; }
  @media (max-width: 1279px), (max-height: 539px) {
    #fullpage .section {
      height: auto !important; }
      #fullpage .section .fp-tableCell {
        height: auto !important; } }

@media (max-width: 1279px), (max-height: 539px) {
  #fullpage {
    height: auto !important;
    position: initial !important;
    transform: none !important; } }

@media (min-width: 1280px) and (min-height: 540px) {
  .contact1, .contact2,
  .about-1, .about-2,
  .brn-index-about, .brn-index-services, .brn-index-project, .brn-index-process,
  .brn-index-client, .brn-index-contact,
  .service-1, .service-2, .service-3, .service-4, .service-5 {
    opacity: 0;
    transition: .3s all linear; }
  .fp-viewing-0 .contact1, .fp-viewing-0 .about-1, .fp-viewing-0 .brn-index-about, .fp-viewing-0 .service-1 {
    opacity: 1; }
  .fp-viewing-1 .contact2, .fp-viewing-1 .about-2, .fp-viewing-1 .brn-index-services, .fp-viewing-1 .service-2 {
    opacity: 1; }
  .fp-viewing-2 .brn-index-project, .fp-viewing-2 .service-3 {
    opacity: 1; }
  .fp-viewing-3 .brn-index-process, .fp-viewing-3 .service-4 {
    opacity: 1; }
  .fp-viewing-4 .brn-index-client, .fp-viewing-4 .service-5 {
    opacity: 1; }
  .fp-viewing-5 .brn-index-contact {
    opacity: 1; } }

.cursor {
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  background-color: #FFF;
  position: absolute;
  z-index: 9999;
  pointer-events: none;
  mix-blend-mode: difference;
  opacity: 0; }

.cursor-f {
  width: var(--size);
  height: var(--size);
  position: absolute;
  top: 0;
  left: 0;
  background-image: url("../assets/icons/cursor.png");
  background-size: cover;
  mix-blend-mode: difference;
  pointer-events: none;
  opacity: 0; }

.swiper-container {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 1; }

.swiper-button-lock {
  display: none; }

.nav-custom .nav {
  margin-top: 2vh;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: flex-end; }
  .nav-custom .nav .swiper-button-next, .nav-custom .nav .swiper-button-prev {
    position: relative;
    width: auto;
    left: unset;
    right: unset;
    margin-left: 2vw;
    height: auto;
    margin-top: 0; }
    .nav-custom .nav .swiper-button-next::after, .nav-custom .nav .swiper-button-prev::after {
      display: none; }
    .nav-custom .nav .swiper-button-next svg, .nav-custom .nav .swiper-button-prev svg {
      width: 40px;
      height: 20px; }
      @media (min-width: 1400px) {
        .nav-custom .nav .swiper-button-next svg, .nav-custom .nav .swiper-button-prev svg {
          width: 62.048px;
          height: 25.789px; } }

.nav-custom .swiper-slide {
  height: auto; }
  .nav-custom .swiper-slide > * {
    height: 100%; }

.swiper-pagination {
  margin-left: -15px;
  padding: 5px 0; }
  .swiper-pagination .swiper-pagination-bullet {
    background: #1C1C1C;
    opacity: 1;
    margin: 0 15px !important;
    height: 6px;
    width: 6px;
    transform: unset; }
    @media (min-width: 992px) {
      .swiper-pagination .swiper-pagination-bullet {
        height: 10px;
        width: 10px; } }
    .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
      transform: scale(1.6);
      background: #4E8848; }

form > *:nth-of-type(n + 1) {
  padding-top: 16px; }

form .group-input {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: -16px -8px 0 -8px;
  width: calc(100% + 16px); }
  form .group-input > * {
    flex: 1;
    margin: 16px 8px 0 8px; }

form button, form input[type="button"] {
  outline: none;
  background: transparent;
  border: none; }

form textarea {
  resize: none;
  width: 100%; }

form input, form textarea {
  border: 1px solid #4E8848;
  border-radius: 10px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  color: #363636;
  font-weight: 400;
  line-height: 1.3;
  font-size: 15px;
  padding: 16px 20px; }
  @media (min-width: 992px) {
    form input, form textarea {
      font-size: 1.5rem; } }
  @media (min-width: 1200px) {
    form input, form textarea {
      font-size: 1.6rem; } }

form input[type="checkbox"] {
  width: 24px;
  height: 24px; }

form .check {
  display: flex;
  align-items: center; }
  form .check input[type="checkbox"] {
    position: absolute;
    opacity: 0; }
    form .check input[type="checkbox"] + label {
      position: relative;
      cursor: pointer;
      padding: 0;
      color: #000;
      text-transform: uppercase;
      font-size: 16px;
      display: flex;
      align-items: center;
      white-space: nowrap; }
    form .check input[type="checkbox"] + label::before {
      content: '';
      margin-right: 10px;
      display: inline-block;
      vertical-align: text-top;
      width: 24px;
      height: 24px;
      background: white;
      border: 1px solid #4E8848;
      border-radius: 5px; }
    form .check input[type="checkbox"]:hover + label::before {
      background: #4E8848; }
    form .check input[type="checkbox"]:focus + label::before {
      box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12); }
    form .check input[type="checkbox"]:checked + label:before {
      background: #4E8848; }
    form .check input[type="checkbox"]:disabled + label {
      opacity: .7;
      cursor: auto; }
    form .check input[type="checkbox"]:disabled + label:before {
      box-shadow: none;
      background-color: white;
      opacity: .7; }
    form .check input[type="checkbox"]:checked + label:after {
      content: '';
      position: absolute;
      top: 11px;
      left: 6px;
      background: white;
      width: 3px;
      height: 3px;
      box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white, 4px -10px 0 white;
      transform: rotate(45deg); }

form button {
  min-width: 200px;
  flex: initial !important;
  line-height: 1.5;
  padding: 12px 24px;
  background-image: linear-gradient(to bottom, #92D665 0%, #00433F 100%);
  border-radius: 50px;
  color: white;
  font-weight: 700;
  font-size: 1.6rem; }
  @media (min-width: 992px) {
    form button {
      font-size: 1.7rem; } }
  @media (min-width: 1200px) {
    form button {
      font-size: 1.8rem; } }
  form button:disabled {
    background: grey;
    opacity: .8;
    cursor: auto; }

form .submitFormContact {
  margin-top: 24px; }
  @media (min-width: 992px) {
    form .submitFormContact {
      margin-top: 32px; } }

.procedure-box {
  display: block;
  width: 100%; }
  .procedure-box > ul {
    list-style-type: none; }
    .procedure-box > ul * {
      transition: .3s all linear; }
    .procedure-box > ul > li {
      display: block;
      background-color: #E9E9E9;
      padding: 30px 20px; }
      .procedure-box > ul > li.hide {
        padding: 30px 20px; }
        .procedure-box > ul > li.hide .arrow {
          transform: rotate(180deg); }
        .procedure-box > ul > li.hide .desc {
          margin-top: 0;
          opacity: 0;
          height: 0;
          overflow: hidden;
          clip-path: polygon(0 0, 100% 0, 100% 0, 0 0); }
      .procedure-box > ul > li .title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 2.6rem;
        line-height: 100%;
        color: #151515;
        font-family: "Barlow";
        font-weight: 400;
        cursor: pointer; }
        @media (min-width: 768px) {
          .procedure-box > ul > li .title {
            font-size: 2.8rem; } }
        @media (min-width: 992px) {
          .procedure-box > ul > li .title {
            font-size: 3rem; } }
        @media (min-width: 1200px) {
          .procedure-box > ul > li .title {
            font-size: 3.2rem; } }
        .procedure-box > ul > li .title > * {
          font-size: 100%;
          line-height: inherit;
          color: currentColor;
          font-family: inherit;
          font-weight: inherit;
          font-size: 2.6rem; }
          @media (min-width: 768px) {
            .procedure-box > ul > li .title > * {
              font-size: 2.8rem; } }
          @media (min-width: 992px) {
            .procedure-box > ul > li .title > * {
              font-size: 3rem; } }
          @media (min-width: 1200px) {
            .procedure-box > ul > li .title > * {
              font-size: 3.2rem; } }
      .procedure-box > ul > li .arrow {
        margin-left: 18px;
        user-select: none;
        pointer-events: none; }
        .procedure-box > ul > li .arrow > * {
          width: 16px;
          height: 16px; }
      .procedure-box > ul > li .desc {
        margin-top: 26px;
        transform-origin: top;
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
        color: #1C1C1C;
        font-size: 2rem;
        line-height: 138%;
        font-family: "Barlow";
        font-weight: 400; }
        @media (min-width: 992px) {
          .procedure-box > ul > li .desc {
            font-size: 2.2rem; } }
        @media (min-width: 1200px) {
          .procedure-box > ul > li .desc {
            font-size: 2.4rem; } }
        .procedure-box > ul > li .desc > * {
          color: currentColor;
          font-family: inherit;
          font-weight: inherit;
          line-height: inherit;
          font-size: 2rem; }
          @media (min-width: 992px) {
            .procedure-box > ul > li .desc > * {
              font-size: 2.2rem; } }
          @media (min-width: 1200px) {
            .procedure-box > ul > li .desc > * {
              font-size: 2.4rem; } }
        .procedure-box > ul > li .desc ul {
          padding-left: 3rem; }
      .procedure-box > ul > li:nth-of-type(1) {
        background-color: #E9E9E9; }
      .procedure-box > ul > li:nth-of-type(2) {
        background-color: #D5D5D5; }
      .procedure-box > ul > li:nth-of-type(3) {
        background-color: #C8C8C8; }
      .procedure-box > ul > li:nth-of-type(4) {
        background-color: #ADADAD; }
      .procedure-box > ul > li:nth-of-type(5) {
        background-color: #9B9B9B; }
      .procedure-box > ul > li:nth-of-type(6) {
        background-color: #858585; }

.project-hl {
  display: block;
  width: 100%;
  --kc: 0vw; }
  @media (min-width: 992px) {
    .project-hl {
      --kc: 4vw; } }
  .project-hl ul {
    list-style-type: none; }
  .project-hl li {
    overflow: hidden; }
  .project-hl li + li {
    margin-top: 60px; }
  .project-hl__left {
    display: flex;
    flex-direction: column; }
  .project-hl__right {
    margin-top: 40px; }
    @media (min-width: 992px) {
      .project-hl__right {
        margin-top: 0; } }
  .project-hl-img {
    width: 100%;
    height: 0;
    padding-top: 75%;
    position: relative; }
    .project-hl-img > * {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top center; }
  .project-hl .order {
    font-size: 80px;
    position: relative;
    display: flex;
    align-items: flex-start; }
    @media (min-width: 992px) {
      .project-hl .order {
        font-size: 100px; } }
    .project-hl .order::after {
      --kc: 0vw;
      max-width: 54%;
      width: 100%;
      content: "";
      position: absolute;
      top: 2px;
      right: calc(var(--kc) - 16px);
      margin-left: var(--kc);
      border-bottom: 5px solid #000; }
      @media (min-width: 992px) {
        .project-hl .order::after {
          --kc: 4vw;
          width: calc(100% - var(--kc) - 16px); } }
    .project-hl .order span {
      display: inline-block;
      position: relative;
      z-index: 1;
      background-color: #fff;
      padding-right: var(--kc);
      line-height: 60%; }
  .project-hl .title {
    margin-top: 30px;
    font-weight: 700;
    font-family: "Barlow";
    font-size: 24px; }
    @media (min-width: 992px) {
      .project-hl .title {
        margin-top: 40px; } }
  .project-hl .cate {
    font-size: 16px;
    font-family: "Barlow"; }
  .project-hl .desc {
    margin-top: 30px;
    width: calc(100% - var(--kc));
    font-size: 16px;
    line-height: 206%; }
  .project-hl__more {
    display: flex;
    align-items: center;
    text-align: right;
    justify-content: flex-start;
    margin-top: 40px; }
    @media (min-width: 992px) {
      .project-hl__more {
        justify-content: flex-end; } }

@keyframes toggleMenuBar {
  50% {
    height: 4px;
    width: 100%;
    top: 50%;
    transform: translate(-50%, -50%); }
  100% {
    height: 4px;
    width: 100%;
    top: 50%;
    transform: translate(-50%, -50%);
    opacity: 0; } }

@keyframes toggleMenuBarFirts {
  50% {
    height: 4px;
    width: 100%;
    top: 50%;
    transform: translate(-50%, -50%); }
  100% {
    height: 4px;
    width: 100%;
    top: 50%;
    transform: translate(-50%, -50%) rotate(45deg); } }

@keyframes toggleMenuBarLast {
  50% {
    height: 4px;
    width: 100%;
    top: 50%;
    transform: translate(-50%, -50%); }
  100% {
    height: 4px;
    width: 100%;
    top: 50%;
    transform: translate(-50%, -50%) rotate(-45deg); } }

@keyframes toggleMenuBarReverse {
  0% {
    height: 4px;
    width: 100%;
    top: 50%;
    transform: translate(-50%, -50%);
    opacity: 0; }
  50% {
    height: 4px;
    width: 100%;
    top: 50%;
    transform: translate(-50%, -50%); } }

@keyframes toggleMenuBarFirtsReverse {
  0% {
    height: 4px;
    width: 100%;
    top: 50%;
    transform: translate(-50%, -50%) rotate(45deg); }
  50% {
    height: 4px;
    width: 100%;
    top: 50%;
    transform: translate(-50%, -50%); } }

@keyframes toggleMenuBarLastReverse {
  0% {
    height: 4px;
    width: 100%;
    top: 50%;
    transform: translate(-50%, -50%) rotate(-45deg); }
  50% {
    height: 4px;
    width: 100%;
    top: 50%;
    transform: translate(-50%, -50%); } }

@keyframes strokeLine {
  0% {
    fill-dashoffset: 0; }
  100% {
    stroke-dashoffset: 10000; } }

@keyframes translateLine {
  from {
    transform: rotate3d(0, 0, 0); }
  to {
    transform: rotate3d(0, 0, 180deg); } }

@keyframes wave {
  from {
    transform: translate(0px) rotate(0);
    opacity: 1; }
  to {
    transform: translate(1000px) rotate(360deg);
    opacity: 0.5; } }

@keyframes wave2 {
  from {
    transform: translateX(0px) translateY(0); }
  to {
    transform: translateX(30px) translateY(20px); } }

@keyframes visibleOverlay {
  from {
    opacity: 0;
    clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);
    width: 100%;
    height: 100%; }
  to {
    width: 100%;
    height: 100%;
    opacity: 1;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%); } }

@keyframes fixedHeader {
  0% {
    opacity: 1;
    top: 0%;
    transform: translateY(-100%); }
  100% {
    opacity: 1;
    top: 0;
    transform: translateY(0%);
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16); } }

.header {
  background-color: transparent;
  position: fixed;
  z-index: 111;
  display: block;
  width: 100%;
  transition: .6s all ease-in-out; }
  @media (max-width: 1279px), (max-height: 539px) {
    .header {
      position: relative;
      background-color: white; }
      .header.scroll {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        transform: translateY(-100%);
        opacity: 1;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
        animation: fixedHeader 1.5s ease forwards; } }
  .header.scrollDown {
    background-color: white;
    box-shadow: none; }
  .header.show {
    height: 100vh;
    background-color: white; }
    .header.show .container {
      height: 100%;
      flex-direction: column; }
    .header.show .header-overlay {
      animation: visibleOverlay 1.3s ease-in-out .6s forwards; }
    .header.show .header-bottom {
      height: auto;
      display: block;
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
      opacity: 1; }
  .header-overlay {
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    display: flex;
    align-items: flex-end;
    opacity: 0;
    clip-path: polygon(0 0, 0 0, 0 100%, 0 100%); }
    .header-overlay .dot {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0; }
    .header-overlay svg {
      position: absolute;
      left: 0;
      bottom: 0; }
      .header-overlay svg .dotGroup {
        transform: translate(-150px, 450px) rotate(22deg); }
      .header-overlay svg .dotGroup2 {
        transform: translate(-150px, 120px) rotate(38deg); }
      .header-overlay svg .dotGroup3 {
        transform: translate(-150px, 275px) rotate(22deg); }
  .header-nav {
    list-style-type: none;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; }
    .header-nav__item {
      text-align: center; }
      .header-nav__item > * {
        font-size: 4.4rem;
        font-weight: 700;
        color: #000;
        line-height: 1.6; }
        @media (min-width: 768px) {
          .header-nav__item > * {
            font-size: 4.8rem; } }
        @media (min-width: 992px) {
          .header-nav__item > * {
            font-size: 5rem; } }
        @media (min-width: 1200px) {
          .header-nav__item > * {
            font-size: 5.2rem; } }
        @media (min-width: 1400px) {
          .header-nav__item > * {
            font-size: 6rem; } }
        @media (min-width: 1680px) {
          .header-nav__item > * {
            font-size: 7.4rem; } }
        .header-nav__item > *.active ~ ul {
          opacity: 1;
          height: auto;
          user-select: initial;
          pointer-events: initial; }
      .header-nav__item ul {
        transition: .3s all;
        opacity: 0;
        height: 0;
        list-style-type: none;
        pointer-events: none;
        user-select: none; }
        .header-nav__item ul li {
          text-align: center;
          display: block; }
          .header-nav__item ul li a {
            display: block;
            font-size: 2.6rem;
            color: #616161;
            line-height: 1.4; }
            @media (min-width: 768px) {
              .header-nav__item ul li a {
                font-size: 2.8rem; } }
            @media (min-width: 992px) {
              .header-nav__item ul li a {
                font-size: 3rem; } }
            @media (min-width: 1200px) {
              .header-nav__item ul li a {
                font-size: 3.2rem; } }
  .header-top {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch; }
  .header-bottom {
    flex: 1;
    width: 100%;
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
    height: 0px;
    opacity: 0;
    transition: .4s all ease-in-out;
    flex: 1;
    overflow: auto; }
  .header-logo {
    display: inline-flex;
    height: 42px;
    width: 140px;
    margin: 18px 0;
    position: relative;
    overflow: hidden; }
    @media (min-width: 576px) {
      .header-logo {
        height: 59px;
        width: 160px;
        margin: 12px 0; } }
    @media (min-width: 992px) {
      .header-logo {
        margin: 30px 0; } }
    .header-logo img {
      width: auto;
      height: 100%;
      object-fit: contain; }
    .header-logo__default {
      transition: .3s all ease-in-out;
      user-select: none;
      pointer-events: none; }
    .header-logo__active {
      pointer-events: none;
      user-select: none;
      position: absolute;
      top: 0;
      left: 0;
      transition: .3s all ease-in-out;
      transform: translateY(100%);
      width: 100%;
      height: 100%; }
    .header-logo.header-logo--bg .header-logo__default {
      transform: translateY(-100%); }
    .header-logo.header-logo--bg .header-logo__active {
      transform: translateY(0%); }
  .header-toggle {
    display: flex;
    align-items: center; }
    .header-toggle .btn-toggle {
      width: 42px;
      height: 48px;
      position: relative;
      cursor: pointer;
      transition: .3s all ease-in-out; }
      @media (min-width: 768px) {
        .header-toggle .btn-toggle {
          width: 55px;
          height: 55px; } }
      .header-toggle .btn-toggle.btn-toggle--white:hover .line {
        box-shadow: 0 3px 3px rgba(0, 0, 0, 0.16); }
      .header-toggle .btn-toggle.btn-toggle--white .line {
        background-color: #ffff; }
      .header-toggle .btn-toggle.focus {
        width: 28px;
        height: 28px; }
        .header-toggle .btn-toggle.focus .line {
          height: 3px !important;
          animation: toggleMenuBar .3s ease-in-out forwards; }
          .header-toggle .btn-toggle.focus .line:first-child {
            animation: toggleMenuBarFirts .3s ease-in-out forwards; }
          .header-toggle .btn-toggle.focus .line:last-child {
            animation: toggleMenuBarLast .3s ease-in-out forwards; }
      .header-toggle .btn-toggle:hover {
        transform: scale(0.9); }
        .header-toggle .btn-toggle:hover .line {
          box-shadow: 0 3px 3px rgba(255, 255, 255, 0.16); }
      .header-toggle .btn-toggle .line {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 5px;
        background-color: #000;
        border-radius: 10px;
        transition: .3s all ease-in-out; }
        .header-toggle .btn-toggle .line:first-child {
          top: calc(50% - 20%);
          width: calc(100% - 20px);
          height: 5px; }
          @media (min-width: 768px) {
            .header-toggle .btn-toggle .line:first-child {
              height: 6px; } }
        .header-toggle .btn-toggle .line:last-child {
          top: calc(50% + 20%);
          width: calc(100% - 20px);
          height: 5px; }
          @media (min-width: 768px) {
            .header-toggle .btn-toggle .line:last-child {
              height: 6px; } }

#footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: transparent; }
  @media (max-width: 1279px), (max-height: 539px) {
    #footer {
      position: relative;
      background-color: white;
      padding-top: 5.2vh; } }
  #footer.full {
    position: relative;
    bottom: auto;
    background-color: white; }
    #footer.full .normalInfo {
      display: none !important; }
    #footer.full .fullInfo {
      display: block !important;
      padding-bottom: 20px; }
      #footer.full .fullInfo .container {
        position: relative;
        padding-top: 2vh;
        margin-top: 2vh; }
        #footer.full .fullInfo .container::after {
          position: absolute;
          top: 0px;
          left: 50%;
          transform: translateX(-50%);
          width: 100%;
          height: 1px;
          content: "";
          background-color: #4E8848; }
    @media (max-width: 1279px), (max-height: 539px) {
      #footer.full {
        padding-top: 0; } }
  #footer.relate {
    position: relative;
    bottom: auto;
    background-color: white; }
  #footer .footer.normalInfo {
    position: relative; }
    #footer .footer.normalInfo .container {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap; }
    #footer .footer.normalInfo .box-linkSocial {
      padding-bottom: 4.2vh;
      display: inline-flex;
      width: auto; }
      #footer .footer.normalInfo .box-linkSocial__content {
        justify-content: flex-start; }
        #footer .footer.normalInfo .box-linkSocial__content > *:not(:first-child) {
          margin-left: 12px; }
    #footer .footer.normalInfo .languages {
      display: flex;
      align-items: center;
      padding-bottom: 4.2vh; }
      @media (min-width: 1200px) {
        #footer .footer.normalInfo .languages {
          margin-right: 0; } }
      #footer .footer.normalInfo .languages-item {
        cursor: pointer;
        background: transparent;
        border: none;
        outline: none;
        padding: 8px 12px;
        color: #4E8848;
        font-size: 2.6rem;
        font-weight: 700;
        display: none; }
        #footer .footer.normalInfo .languages-item:hover, #footer .footer.normalInfo .languages-item:focus {
          background: transparent;
          border: none;
          outline: none; }
        @media (min-width: 768px) {
          #footer .footer.normalInfo .languages-item {
            font-size: 2.8rem; } }
        @media (min-width: 992px) {
          #footer .footer.normalInfo .languages-item {
            font-size: 3rem; } }
        @media (min-width: 1200px) {
          #footer .footer.normalInfo .languages-item {
            font-size: 3.2rem; } }
        #footer .footer.normalInfo .languages-item.active {
          display: block; }
  #footer .footer.fullInfo {
    display: none; }
  #footer .box-linkSocial__content {
    justify-content: flex-start; }

@keyframes tranlateY100_up {
  0% {
    transform: translateY(0);
    opacity: 1;
    display: block; }
  100% {
    transform: translateY(-100%);
    opacity: 0; } }

#loading-container {
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 111111;
  background-color: gray; }
  #loading-container.hiddenLoading {
    animation: tranlateY100_up .9s ease-in-out forwards; }
  #loading-container #brn-loading .brn-loading__wrapper {
    overflow: hidden;
    width: 100%;
    height: 100vh;
    background: linear-gradient(45deg, #00423E 0%, #0A4B3E 14%, #26653E 36.4%, #55903E 66%, #91C73E 100%);
    position: relative; }
    #loading-container #brn-loading .brn-loading__wrapper--bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; }
      #loading-container #brn-loading .brn-loading__wrapper--bg svg {
        width: 100%;
        height: 100%; }
    #loading-container #brn-loading .brn-loading__wrapper--logo {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 2; }
      #loading-container #brn-loading .brn-loading__wrapper--logo svg {
        max-width: 60vw;
        max-height: 8vw; }
        #loading-container #brn-loading .brn-loading__wrapper--logo svg path {
          fill: transparent;
          stroke: 10;
          stroke: #fff;
          stroke-dasharray: 650;
          stroke-dashoffset: 650;
          animation-delay: 1s;
          animation: animateLogo 3s linear infinite alternate; }
        #loading-container #brn-loading .brn-loading__wrapper--logo svg text {
          user-select: none;
          animation-delay: 1s;
          animation: opacityTextLogo 3s linear infinite alternate; }
    #loading-container #brn-loading .brn-loading__wrapper--effects {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 1; }
      #loading-container #brn-loading .brn-loading__wrapper--effects svg {
        width: 100%;
        height: 100%; }
      #loading-container #brn-loading .brn-loading__wrapper--effects .objs-01 path {
        transform-origin: center;
        transform-box: fill-box;
        animation: animatedMutiply 5s infinite linear; }
        #loading-container #brn-loading .brn-loading__wrapper--effects .objs-01 path:nth-of-type(2) {
          animation-delay: -0.4s; }
        #loading-container #brn-loading .brn-loading__wrapper--effects .objs-01 path:nth-of-type(3) {
          animation-delay: -0.8s; }
      #loading-container #brn-loading .brn-loading__wrapper--effects .objs-02 {
        transform-box: fill-box;
        transform-origin: center;
        animation: rotate90 5s linear infinite; }
        #loading-container #brn-loading .brn-loading__wrapper--effects .objs-02__small {
          position: relative;
          animation: fillCircle 5s linear infinite; }
      #loading-container #brn-loading .brn-loading__wrapper--effects .objs-03 path {
        transform-box: fill-box;
        transform-origin: center;
        animation: animatedArrowRight 2s infinite; }
        #loading-container #brn-loading .brn-loading__wrapper--effects .objs-03 path:nth-of-type(2) {
          animation-delay: -0.3s; }
        #loading-container #brn-loading .brn-loading__wrapper--effects .objs-03 path:nth-of-type(3) {
          animation-delay: -0.6s; }
      #loading-container #brn-loading .brn-loading__wrapper--effects .objs-04 path {
        transform-box: fill-box;
        transform-origin: center;
        animation: animatedArrowUp 2s infinite; }
        #loading-container #brn-loading .brn-loading__wrapper--effects .objs-04 path:nth-of-type(2) {
          animation-delay: -0.3s; }
        #loading-container #brn-loading .brn-loading__wrapper--effects .objs-04 path:nth-of-type(3) {
          animation-delay: -0.6s; }
      #loading-container #brn-loading .brn-loading__wrapper--effects .objs-05 path {
        fill: powderblue;
        transform: scale(1);
        transform-origin: center;
        transition: .5;
        transform-box: fill-box;
        transform-origin: center;
        animation-delay: var(--i);
        animation: STAR 4s infinite linear alternate; }
      #loading-container #brn-loading .brn-loading__wrapper--effects .circleAnimate {
        transform-box: fill-box;
        transform-origin: center;
        animation: rotate360 6s infinite; }

#fp-nav ul li {
  margin: 20px 7px; }
  #fp-nav ul li:hover a span {
    margin: unset; }
  #fp-nav ul li:hover a.active {
    margin: unset; }
    #fp-nav ul li:hover a.active span {
      margin: unset; }
  #fp-nav ul li a span {
    margin: unset;
    width: 10px;
    height: 10px;
    transform: translate(-50%, -50%);
    transition: .2s ease-in-out; }
  #fp-nav ul li a.active {
    position: relative;
    margin: unset; }
    #fp-nav ul li a.active span {
      margin: unset;
      width: 14px;
      height: 14px;
      background-color: #91C73E; }
    #fp-nav ul li a.active::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: #4E8848;
      width: 20px;
      height: 20px;
      border-radius: 50%; }

.box {
  width: 100%;
  height: 100%; }
  .box__content {
    padding: 2vh;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }
  .box__img {
    width: 45%;
    position: relative;
    padding-top: 45%; }
    .box__img img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 50%;
      object-fit: cover; }
  .box__position {
    margin-top: 2vh; }
    .box__position > * {
      font-size: 2.6rem;
      color: #292929;
      font-weight: 500; }
      @media (min-width: 768px) {
        .box__position > * {
          font-size: 2.8rem; } }
      @media (min-width: 992px) {
        .box__position > * {
          font-size: 3rem; } }
      @media (min-width: 1200px) {
        .box__position > * {
          font-size: 3.2rem; } }
  .box__name {
    margin-top: 2vh; }
    .box__name > * {
      font-size: 2.6rem;
      color: #4E8848;
      font-weight: 700; }
      @media (min-width: 768px) {
        .box__name > * {
          font-size: 2.8rem; } }
      @media (min-width: 992px) {
        .box__name > * {
          font-size: 3rem; } }
      @media (min-width: 1200px) {
        .box__name > * {
          font-size: 3.2rem; } }
  .box__desc {
    text-align: center;
    margin-top: 2vh; }
    .box__desc > * {
      font-size: 2rem;
      color: #363636;
      font-weight: 400; }
      @media (min-width: 992px) {
        .box__desc > * {
          font-size: 2.2rem; } }
      @media (min-width: 1200px) {
        .box__desc > * {
          font-size: 2.4rem; } }
  .box__linkSocial {
    flex: 1;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    width: 100%;
    margin-top: 3vh; }

.title {
  width: 100%; }
  .title__content, #brn-index-services .brn-index-services__wrapper--intro, #brn-index-project .brn-index-project__wrapper--intro {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    text-align: center; }
    @media (min-width: 992px) {
      .title__content, #brn-index-services .brn-index-services__wrapper--intro, #brn-index-project .brn-index-project__wrapper--intro {
        flex-direction: row;
        text-align: initial; } }
  .title__name, #brn-index-services .brn-index-services__wrapper--intro_title, #brn-index-project .brn-index-project__wrapper--intro_title, #brn-index-process .brn-index-process__wrapper--process__title, #brn-index-contact .brn-index-contact__wrapper--contact__title {
    display: flex;
    align-items: center;
    max-width: 100%;
    width: 100%; }
    @media (min-width: 992px) {
      .title__name, #brn-index-services .brn-index-services__wrapper--intro_title, #brn-index-project .brn-index-project__wrapper--intro_title, #brn-index-process .brn-index-process__wrapper--process__title, #brn-index-contact .brn-index-contact__wrapper--contact__title {
        width: auto;
        max-width: 50%;
        text-align: initial; } }
    .title__name > *, #brn-index-services .brn-index-services__wrapper--intro_title > *, #brn-index-project .brn-index-project__wrapper--intro_title > *, #brn-index-process .brn-index-process__wrapper--process__title > *, #brn-index-contact .brn-index-contact__wrapper--contact__title > * {
      width: 100%;
      text-align: center; }
      @media (min-width: 992px) {
        .title__name > *, #brn-index-services .brn-index-services__wrapper--intro_title > *, #brn-index-project .brn-index-project__wrapper--intro_title > *, #brn-index-process .brn-index-process__wrapper--process__title > *, #brn-index-contact .brn-index-contact__wrapper--contact__title > * {
          text-align: initial; } }
  .title__desc, #brn-index-services .brn-index-services__wrapper--intro_decs, #brn-index-project .brn-index-project__wrapper--intro_decs {
    flex: 1;
    padding-left: 0;
    padding-top: 12px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end; }
    @media (min-width: 992px) {
      .title__desc, #brn-index-services .brn-index-services__wrapper--intro_decs, #brn-index-project .brn-index-project__wrapper--intro_decs {
        padding-top: 0px;
        padding-left: 4.2rem;
        flex: 1;
        width: auto; } }
    @media (max-width: 640px) {
      .title__desc, #brn-index-services .brn-index-services__wrapper--intro_decs, #brn-index-project .brn-index-project__wrapper--intro_decs {
        margin-left: 0px;
        margin-top: 8px; } }
    .title__desc > *, #brn-index-services .brn-index-services__wrapper--intro_decs > *, #brn-index-project .brn-index-project__wrapper--intro_decs > * {
      font-size: 2rem;
      color: #1C1C1C;
      line-height: 1.2;
      font-weight: 400; }
      @media (min-width: 992px) {
        .title__desc > *, #brn-index-services .brn-index-services__wrapper--intro_decs > *, #brn-index-project .brn-index-project__wrapper--intro_decs > * {
          font-size: 2.2rem; } }
      @media (min-width: 1200px) {
        .title__desc > *, #brn-index-services .brn-index-services__wrapper--intro_decs > *, #brn-index-project .brn-index-project__wrapper--intro_decs > * {
          font-size: 2.4rem; } }
      @media (max-width: 1280px) {
        .title__desc > *, #brn-index-services .brn-index-services__wrapper--intro_decs > *, #brn-index-project .brn-index-project__wrapper--intro_decs > * {
          font-size: 2rem; } }
  .title-format > *, #brn-index-services .brn-index-services__wrapper--intro_title > *, #brn-index-project .brn-index-project__wrapper--intro_title > *, #brn-index-process .brn-index-process__wrapper--process__title > *, #brn-index-client .brn-index-client__wrapper--client__title > *, #brn-index-contact .brn-index-contact__wrapper--contact__title > *, .braney-title > * > * {
    font-size: 4.4rem;
    color: #000;
    line-height: 1.2;
    font-weight: 700; }
    @media (min-width: 768px) {
      .title-format > *, #brn-index-services .brn-index-services__wrapper--intro_title > *, #brn-index-project .brn-index-project__wrapper--intro_title > *, #brn-index-process .brn-index-process__wrapper--process__title > *, #brn-index-client .brn-index-client__wrapper--client__title > *, #brn-index-contact .brn-index-contact__wrapper--contact__title > *, .braney-title > * > * {
        font-size: 4.8rem; } }
    @media (min-width: 992px) {
      .title-format > *, #brn-index-services .brn-index-services__wrapper--intro_title > *, #brn-index-project .brn-index-project__wrapper--intro_title > *, #brn-index-process .brn-index-process__wrapper--process__title > *, #brn-index-client .brn-index-client__wrapper--client__title > *, #brn-index-contact .brn-index-contact__wrapper--contact__title > *, .braney-title > * > * {
        font-size: 5rem; } }
    @media (min-width: 1200px) {
      .title-format > *, #brn-index-services .brn-index-services__wrapper--intro_title > *, #brn-index-project .brn-index-project__wrapper--intro_title > *, #brn-index-process .brn-index-process__wrapper--process__title > *, #brn-index-client .brn-index-client__wrapper--client__title > *, #brn-index-contact .brn-index-contact__wrapper--contact__title > *, .braney-title > * > * {
        font-size: 5.2rem; } }
    @media (min-width: 1400px) {
      .title-format > *, #brn-index-services .brn-index-services__wrapper--intro_title > *, #brn-index-project .brn-index-project__wrapper--intro_title > *, #brn-index-process .brn-index-process__wrapper--process__title > *, #brn-index-client .brn-index-client__wrapper--client__title > *, #brn-index-contact .brn-index-contact__wrapper--contact__title > *, .braney-title > * > * {
        font-size: 6rem; } }
    @media (min-width: 1680px) {
      .title-format > *, #brn-index-services .brn-index-services__wrapper--intro_title > *, #brn-index-project .brn-index-project__wrapper--intro_title > *, #brn-index-process .brn-index-process__wrapper--process__title > *, #brn-index-client .brn-index-client__wrapper--client__title > *, #brn-index-contact .brn-index-contact__wrapper--contact__title > *, .braney-title > * > * {
        font-size: 7.4rem; } }

.box-linkSocial__content {
  display: flex;
  justify-content: center; }
  .box-linkSocial__content > *:not(:first-child) {
    margin-left: 12px; }

.box-linkSocial__facebook, .box-linkSocial__be, .box-linkSocial__ins {
  padding: 4px 8px;
  height: 28px;
  min-width: 28px;
  transition: .3s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center; }
  @media (min-width: 992px) {
    .box-linkSocial__facebook, .box-linkSocial__be, .box-linkSocial__ins {
      height: 42px;
      min-width: 42px; } }
  .box-linkSocial__facebook:hover, .box-linkSocial__be:hover, .box-linkSocial__ins:hover {
    background-color: #4E8848; }
    .box-linkSocial__facebook:hover path, .box-linkSocial__be:hover path, .box-linkSocial__ins:hover path {
      fill: #ffff; }

.hash1 {
  padding-top: 24px;
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto 0 auto;
  border-top: 1px solid #818282;
  flex: 0 0 auto;
  width: 100%;
  padding-left: 52px;
  padding-right: 52px; }
  @media (min-width: 768px) {
    .hash1 {
      padding-top: 36px; } }
  @media (min-width: 992px) {
    .hash1 {
      padding-top: 56px; } }
  @media (min-width: 992px) {
    .hash1 {
      padding-left: 0px;
      padding-right: 0px;
      width: 66.66667%; } }
  .hash1-left {
    width: 100%; }
    @media (min-width: 1200px) {
      .hash1-left {
        width: 75%;
        padding-right: 8.3333%; } }
    .hash1-left__title > * {
      font-weight: 700;
      font-size: 3.4rem;
      color: #1C1C1C; }
      @media (min-width: 992px) {
        .hash1-left__title > * {
          font-size: 3.6rem; } }
      @media (min-width: 1200px) {
        .hash1-left__title > * {
          font-size: 4rem; } }
    .hash1-left__desc {
      margin-top: 24px; }
      @media (min-width: 768px) {
        .hash1-left__desc {
          margin-top: 40px; } }
      @media (min-width: 992px) {
        .hash1-left__desc {
          margin-top: 56px; } }
      .hash1-left__desc > * {
        font-weight: 400;
        font-size: 1.8rem;
        color: #707070; }
        @media (min-width: 992px) {
          .hash1-left__desc > * {
            font-size: 1.9rem; } }
        @media (min-width: 1200px) {
          .hash1-left__desc > * {
            font-size: 2rem; } }
  .hash1-right {
    margin-top: 24px;
    width: 100%; }
    @media (min-width: 1200px) {
      .hash1-right {
        margin-top: 0px;
        width: 25%; } }
    .hash1-right__item:not(:first-child) {
      margin-top: 28px; }
    .hash1-right__item--tag {
      font-weight: 400;
      font-size: 2rem;
      color: #1C1C1C; }
      @media (min-width: 992px) {
        .hash1-right__item--tag {
          font-size: 2.2rem; } }
      @media (min-width: 1200px) {
        .hash1-right__item--tag {
          font-size: 2.4rem; } }
    .hash1-right__item--content {
      margin-top: 8px;
      font-weight: 700;
      font-size: 2rem;
      color: #1C1C1C; }
      @media (min-width: 992px) {
        .hash1-right__item--content {
          font-size: 2.2rem; } }
      @media (min-width: 1200px) {
        .hash1-right__item--content {
          font-size: 2.4rem; } }

.hash2 {
  display: flex;
  flex-direction: column; }
  @media (max-width: 1280px) {
    .hash2 {
      max-width: 460px !important; } }
  .hash2__img {
    position: relative;
    padding-top: 75%; }
    .hash2__img > * {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      background-size: cover;
      object-position: center;
      background-position: center;
      background-repeat: no-repeat; }
  .hash2-content {
    padding-top: 48px;
    flex: 1;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; }
    @media (max-width: 1280px) {
      .hash2-content {
        padding-top: 32px; } }
    @media (max-width: 640px) {
      .hash2-content {
        padding-top: 24px; } }
    .hash2-content__title {
      flex: 1; }
      @media (max-width: 1024px) {
        .hash2-content__title {
          flex: 0 0 100%; } }
      .hash2-content__title > * {
        font-size: 2.6rem;
        font-weight: 500;
        color: #292929; }
        @media (min-width: 768px) {
          .hash2-content__title > * {
            font-size: 2.8rem; } }
        @media (min-width: 992px) {
          .hash2-content__title > * {
            font-size: 3rem; } }
        @media (min-width: 1200px) {
          .hash2-content__title > * {
            font-size: 3.2rem; } }
    .hash2-content__tag {
      display: flex;
      align-items: flex-end;
      flex-direction: row;
      flex-wrap: wrap; }
      @media (max-width: 1024px) {
        .hash2-content__tag {
          flex: 0 0 100%;
          margin-top: 4px; } }
      .hash2-content__tag > * {
        font-size: 2rem;
        font-weight: 400;
        color: #4E8848; }
        @media (min-width: 992px) {
          .hash2-content__tag > * {
            font-size: 2.2rem; } }
        @media (min-width: 1200px) {
          .hash2-content__tag > * {
            font-size: 2.4rem; } }
    .hash2-content-btn {
      margin-top: 20px;
      flex: 0 0 100%; }
      @media (max-width: 1024px) {
        .hash2-content-btn {
          margin-top: 18px; } }

.card {
  padding-bottom: 6px; }
  .card + .card {
    padding-top: 10px; }
    @media (min-width: 992px) {
      .card + .card {
        padding-top: 20px; } }
    @media (min-width: 1200px) {
      .card + .card {
        padding-top: 40px; } }
  .card-box {
    height: 100%;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    position: relative;
    display: flex;
    flex-direction: column; }
  .card__img {
    position: relative;
    padding-top: 67.77%; }
    .card__img > * {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center; }
  .card-content {
    flex: 1;
    padding: 16px 12px 12px 12px;
    display: flex;
    flex-direction: column; }
    @media (min-width: 1200px) {
      .card-content {
        padding: 40px 32px 24px 32px; } }
    .card-content__title {
      padding-top: 12px;
      border-top: 1px solid #4E8848;
      flex: 1;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden; }
      @media (min-width: 1200px) {
        .card-content__title {
          padding-top: 20px; } }
      .card-content__title > * {
        color: #4E8848;
        font-weight: 700;
        line-height: 1.4;
        font-size: 2.6rem; }
        @media (min-width: 768px) {
          .card-content__title > * {
            font-size: 2.8rem; } }
        @media (min-width: 992px) {
          .card-content__title > * {
            font-size: 3rem; } }
        @media (min-width: 1200px) {
          .card-content__title > * {
            font-size: 3.2rem; } }
    .card-content__desc {
      margin-top: 18px; }
      @media (min-width: 1200px) {
        .card-content__desc {
          margin-top: 24px; } }
      @media (min-width: 1200px) {
        .card-content__desc {
          margin-top: 36px; } }
      @media (min-width: 1400px) {
        .card-content__desc {
          margin-top: 42px; } }
      @media (min-width: 1680px) {
        .card-content__desc {
          margin-top: 56px; } }
      .card-content__desc > * {
        color: #1C1C1C;
        font-weight: 400;
        font-size: 2rem;
        line-height: 1.32;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden; }
        @media (min-width: 992px) {
          .card-content__desc > * {
            font-size: 2.2rem; } }
        @media (min-width: 1200px) {
          .card-content__desc > * {
            font-size: 2.4rem; } }
    .card-content-bottom {
      margin-top: 46px; }

.brand-service__content {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  flex-direction: column; }
  @media (min-width: 992px) {
    .brand-service__content {
      flex-direction: row; } }

.brand-service__img {
  flex: 0 0 100%;
  width: 100%;
  order: 1; }
  @media (min-width: 992px) {
    .brand-service__img {
      order: initial;
      flex: 0 0 58.33333%;
      width: 58.33333%; } }

.brand-service__infor {
  flex: 0 0 100%;
  width: 100%;
  order: 2;
  margin-top: 3vh;
  text-align: center; }
  @media (min-width: 992px) {
    .brand-service__infor {
      margin-top: 0;
      order: initial;
      flex: 0 0 41.66667%;
      width: 41.66667%;
      text-align: initial; } }
  .brand-service__infor--title {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden; }
    .brand-service__infor--title > * {
      font-size: 3.4rem;
      color: #4E8848;
      font-weight: 700; }
      @media (min-width: 992px) {
        .brand-service__infor--title > * {
          font-size: 3.6rem; } }
      @media (min-width: 1200px) {
        .brand-service__infor--title > * {
          font-size: 4rem; } }
    @media (min-width: 1400px) {
      .brand-service__infor--title {
        -webkit-line-clamp: initial; } }
  .brand-service__infor--desc {
    margin-top: 2vh;
    display: -webkit-box;
    -webkit-line-clamp: 7;
    -webkit-box-orient: vertical;
    overflow: hidden; }
    @media (min-width: 1680px) {
      .brand-service__infor--desc {
        margin-top: 36px; } }
    .brand-service__infor--desc > * {
      font-size: 2rem;
      color: #1C1C1C;
      font-weight: 400;
      line-height: 1.2; }
      @media (min-width: 992px) {
        .brand-service__infor--desc > * {
          font-size: 2.2rem; } }
      @media (min-width: 1200px) {
        .brand-service__infor--desc > * {
          font-size: 2.4rem; } }
    @media (min-width: 1400px) {
      .brand-service__infor--desc {
        -webkit-line-clamp: 9; } }
    @media (min-width: 1680px) {
      .brand-service__infor--desc {
        -webkit-line-clamp: initial; } }
  .brand-service__infor--seeProject {
    margin-top: 3vh; }
    @media (min-width: 1680px) {
      .brand-service__infor--seeProject {
        margin-top: 50px; } }
  @media (min-width: 1680px) and (min-height: 960px) {
    .brand-service__infor--seeProject {
      margin-top: 3vh; } }

.project_items {
  width: 100%;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  transition: .2s ease-in-out; }
  .project_items .link_project {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: transparent;
    z-index: 1; }
  .project_items .items {
    display: flex;
    flex-direction: column;
    justify-content: space-between; }
    .project_items .items .items-header {
      width: 100%;
      padding-bottom: 55%;
      position: relative; }
      .project_items .items .items-header img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0; }
    .project_items .items .items-main {
      display: flex;
      align-items: center;
      margin-top: 12px; }
      .project_items .items .items-main__name {
        font-size: 2.6rem;
        color: #1C1C1C;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        font-weight: 500;
        flex: 1; }
        @media (min-width: 768px) {
          .project_items .items .items-main__name {
            font-size: 2.8rem; } }
        @media (min-width: 992px) {
          .project_items .items .items-main__name {
            font-size: 3rem; } }
        @media (min-width: 1200px) {
          .project_items .items .items-main__name {
            font-size: 3.2rem; } }
      .project_items .items .items-main__sub {
        margin-left: auto;
        font-size: 2rem;
        color: #4E8848;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden; }
        @media (min-width: 992px) {
          .project_items .items .items-main__sub {
            font-size: 2.2rem; } }
        @media (min-width: 1200px) {
          .project_items .items .items-main__sub {
            font-size: 2.4rem; } }
    .project_items .items .items-footer {
      margin-top: 12px;
      display: inline-flex; }
      .project_items .items .items-footer .btn-see-more {
        font-size: 2rem;
        color: #4E8848;
        position: relative;
        display: inline-flex;
        transition: .2s ease-in-out;
        padding: 10px 0;
        z-index: 2; }
        @media (min-width: 992px) {
          .project_items .items .items-footer .btn-see-more {
            font-size: 2.2rem; } }
        @media (min-width: 1200px) {
          .project_items .items .items-footer .btn-see-more {
            font-size: 2.4rem; } }
  .project_items:hover span {
    font-weight: 700; }
  .project_items:hover .btn-see-more .line {
    width: 20px; }
    @media (max-width: 1024px) {
      .project_items:hover .btn-see-more .line {
        width: 18px; } }
    .project_items:hover .btn-see-more .line::before {
      width: 100%;
      transform-origin: right;
      transform: translateY(-50%) rotate(25deg);
      border-top: 2px solid #4E8848; }
    .project_items:hover .btn-see-more .line::after {
      width: 100%;
      transform-origin: right;
      transform: translateY(-50%) rotate(-25deg);
      border-top: 2px solid #4E8848; }

.company_infor {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  position: relative;
  margin-left: -8px;
  margin-right: -8px;
  margin-top: -24px; }
  .company_infor > * {
    margin-top: 3vh;
    font-size: 2rem;
    word-wrap: break-word;
    padding: 0 8px;
    width: 100%;
    text-align: center; }
    @media (min-width: 992px) {
      .company_infor > * {
        font-size: 2.2rem; } }
    @media (min-width: 1200px) {
      .company_infor > * {
        font-size: 2.4rem; } }
    @media (min-width: 576px) {
      .company_infor > * {
        width: 50%;
        text-align: initial;
        text-align: center; } }
    @media (min-width: 992px) {
      .company_infor > * {
        width: 33.3333%;
        text-align: left; } }
  .company_infor .social {
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    width: 100%;
    padding: 0; }
    @media (min-width: 576px) {
      .company_infor .social {
        padding: 0 20%; } }
    @media (min-width: 992px) {
      .company_infor .social {
        width: auto;
        padding: 0 0; } }
    .company_infor .social .box-linkSocial__content svg {
      height: 100%;
      width: 100%; }
      .company_infor .social .box-linkSocial__content svg path {
        fill: #1C1C1C; }
    .company_infor .social .box-linkSocial__facebook:hover, .company_infor .social .box-linkSocial__be:hover, .company_infor .social .box-linkSocial__ins:hover {
      background-color: unset !important; }
      .company_infor .social .box-linkSocial__facebook:hover svg path, .company_infor .social .box-linkSocial__be:hover svg path, .company_infor .social .box-linkSocial__ins:hover svg path {
        fill: #4E8848 !important; }

#brn-index-about {
  height: 100%;
  background-color: white; }
  @media (max-width: 1279px), (max-height: 539px) {
    #brn-index-about {
      height: auto; } }
  #brn-index-about .brn-index-about {
    width: 100%;
    height: 100vh; }
    @media (max-width: 1279px), (max-height: 539px) {
      #brn-index-about .brn-index-about {
        height: auto; } }
    #brn-index-about .brn-index-about__wrapper {
      height: 100%;
      display: flex;
      align-items: center;
      position: relative; }
      @media (max-width: 1279px), (max-height: 539px) {
        #brn-index-about .brn-index-about__wrapper {
          height: auto; } }
      #brn-index-about .brn-index-about__wrapper--abusImg {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center; }
        @media (min-width: 992px) {
          #brn-index-about .brn-index-about__wrapper--abusImg {
            width: 58.33333%;
            padding-right: 2%; } }
        #brn-index-about .brn-index-about__wrapper--abusImg svg {
          max-height: 70vh;
          width: 85%;
          height: auto; }
          @media (max-width: 1440px) {
            #brn-index-about .brn-index-about__wrapper--abusImg svg {
              width: 90%; } }
          #brn-index-about .brn-index-about__wrapper--abusImg svg #braney_board {
            animation: fadein infinite 3s ease-in-out; }
          #brn-index-about .brn-index-about__wrapper--abusImg svg .braney_computer {
            animation: fadein_computer infinite 3s ease-in-out;
            transform-origin: center bottom;
            transform-box: fill-box; }
          #brn-index-about .brn-index-about__wrapper--abusImg svg #about_girl .about_girl_hair {
            animation: hair_flow 3s infinite ease-in-out;
            transform-origin: top right;
            transform-box: fill-box; }
          #brn-index-about .brn-index-about__wrapper--abusImg svg #about_boy .about_boy_hand {
            animation: hand_flow 1.5s infinite ease-in-out;
            transform-origin: top right;
            transform-box: fill-box; }
          #brn-index-about .brn-index-about__wrapper--abusImg svg .head_boy {
            animation: hand_flow 3s infinite ease-in-out;
            transform-origin: center bottom;
            transform-box: fill-box; }
      #brn-index-about .brn-index-about__wrapper--abusInfor {
        width: 100%;
        margin-top: 24px;
        text-align: center; }
        @media (min-width: 992px) {
          #brn-index-about .brn-index-about__wrapper--abusInfor {
            margin-top: 0px;
            width: 41.66667%;
            text-align: initial; } }
        #brn-index-about .brn-index-about__wrapper--abusInfor > *:not(:last-child) {
          margin-bottom: 3vh; }
          @media (min-width: 1680px) {
            #brn-index-about .brn-index-about__wrapper--abusInfor > *:not(:last-child) {
              margin-bottom: 60px; } }
        #brn-index-about .brn-index-about__wrapper--abusInfor_title > * {
          font-weight: 700;
          font-size: 4.4rem; }
          @media (min-width: 768px) {
            #brn-index-about .brn-index-about__wrapper--abusInfor_title > * {
              font-size: 4.8rem; } }
          @media (min-width: 992px) {
            #brn-index-about .brn-index-about__wrapper--abusInfor_title > * {
              font-size: 5rem; } }
          @media (min-width: 1200px) {
            #brn-index-about .brn-index-about__wrapper--abusInfor_title > * {
              font-size: 5.2rem; } }
          @media (min-width: 1400px) {
            #brn-index-about .brn-index-about__wrapper--abusInfor_title > * {
              font-size: 6rem; } }
          @media (min-width: 1680px) {
            #brn-index-about .brn-index-about__wrapper--abusInfor_title > * {
              font-size: 7.4rem; } }
        #brn-index-about .brn-index-about__wrapper--abusInfor_decs p {
          font-size: 2rem;
          line-height: 29px; }
          @media (min-width: 992px) {
            #brn-index-about .brn-index-about__wrapper--abusInfor_decs p {
              font-size: 2.2rem; } }
          @media (min-width: 1200px) {
            #brn-index-about .brn-index-about__wrapper--abusInfor_decs p {
              font-size: 2.4rem; } }
        #brn-index-about .brn-index-about__wrapper--abusInfor_btn {
          display: inline-flex;
          position: relative;
          overflow: hidden;
          border-radius: 30px;
          transition: .3s ease-in-out; }
          #brn-index-about .brn-index-about__wrapper--abusInfor_btn::after {
            transition: .3s ease-in-out;
            z-index: 1;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 200%;
            content: "";
            background: linear-gradient(180deg, #92d665 0%, #00433f 50%, #92d665 100%); }
          #brn-index-about .brn-index-about__wrapper--abusInfor_btn a {
            display: inline-flex;
            background: transparent;
            padding: 6px 40px;
            font-weight: 700;
            font-size: 1.6rem;
            line-height: 29px;
            color: #ffff;
            border-radius: 30px;
            position: relative;
            z-index: 2;
            overflow: hidden; }
            @media (min-width: 992px) {
              #brn-index-about .brn-index-about__wrapper--abusInfor_btn a {
                padding: 13px 60px; } }
            @media (min-width: 992px) {
              #brn-index-about .brn-index-about__wrapper--abusInfor_btn a {
                font-size: 1.7rem; } }
            @media (min-width: 1200px) {
              #brn-index-about .brn-index-about__wrapper--abusInfor_btn a {
                font-size: 1.8rem; } }
          #brn-index-about .brn-index-about__wrapper--abusInfor_btn:hover {
            transform: translateY(-10px); }
            #brn-index-about .brn-index-about__wrapper--abusInfor_btn:hover::after {
              top: -100%; }

#brn-index-services {
  background-color: white;
  height: 100%;
  width: 100%; }
  @media (max-width: 1279px), (max-height: 539px) {
    #brn-index-services {
      height: auto; } }
  #brn-index-services .brn-index-services {
    height: 100vh;
    width: 100%; }
    @media (max-width: 1279px), (max-height: 539px) {
      #brn-index-services .brn-index-services {
        height: auto; } }
    #brn-index-services .brn-index-services__wrapper {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center; }
      @media (max-width: 1279px), (max-height: 539px) {
        #brn-index-services .brn-index-services__wrapper {
          height: auto; } }
      #brn-index-services .brn-index-services__wrapper--intro_title {
        font-weight: 700;
        font-size: 4.4rem; }
        @media (min-width: 768px) {
          #brn-index-services .brn-index-services__wrapper--intro_title {
            font-size: 4.8rem; } }
        @media (min-width: 992px) {
          #brn-index-services .brn-index-services__wrapper--intro_title {
            font-size: 5rem; } }
        @media (min-width: 1200px) {
          #brn-index-services .brn-index-services__wrapper--intro_title {
            font-size: 5.2rem; } }
        @media (min-width: 1400px) {
          #brn-index-services .brn-index-services__wrapper--intro_title {
            font-size: 6rem; } }
        @media (min-width: 1680px) {
          #brn-index-services .brn-index-services__wrapper--intro_title {
            font-size: 7.4rem; } }
      #brn-index-services .brn-index-services__wrapper--services {
        width: 100%;
        padding-top: 3vh;
        margin-top: 3vh;
        position: relative; }
        #brn-index-services .brn-index-services__wrapper--services::after {
          content: "";
          position: absolute;
          top: 0px;
          left: 0;
          width: 100%;
          height: 1px;
          background-color: #4E8848; }
        #brn-index-services .brn-index-services__wrapper--services .nav-custom {
          margin: 0 -10px; }
          #brn-index-services .brn-index-services__wrapper--services .nav-custom .swiper-container {
            width: 100%;
            padding: 10px; }
        #brn-index-services .brn-index-services__wrapper--services_items {
          cursor: pointer;
          width: 100%;
          position: relative;
          overflow: hidden;
          transition: .2s ease-in-out;
          box-shadow: 1px 3px 10px rgba(0, 0, 0, 0.16); }
          #brn-index-services .brn-index-services__wrapper--services_items .link_services {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            z-index: 1; }
          #brn-index-services .brn-index-services__wrapper--services_items .items {
            padding: 30px;
            background: white;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between; }
            #brn-index-services .brn-index-services__wrapper--services_items .items .items-header {
              display: flex;
              flex-wrap: nowrap;
              justify-content: space-between;
              align-items: flex-start; }
              #brn-index-services .brn-index-services__wrapper--services_items .items .items-header__title {
                flex: 1;
                margin-right: 0.8rem;
                font-weight: 700;
                font-size: 3.4rem;
                color: #4E8848; }
                @media (min-width: 992px) {
                  #brn-index-services .brn-index-services__wrapper--services_items .items .items-header__title {
                    font-size: 3.6rem; } }
                @media (min-width: 1200px) {
                  #brn-index-services .brn-index-services__wrapper--services_items .items .items-header__title {
                    font-size: 4rem; } }
              #brn-index-services .brn-index-services__wrapper--services_items .items .items-header__icon {
                width: 5.2rem; }
            #brn-index-services .brn-index-services__wrapper--services_items .items .items-main {
              font-size: 2rem;
              line-height: 29px;
              color: #1C1C1C;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
              overflow: hidden; }
              @media (min-width: 992px) {
                #brn-index-services .brn-index-services__wrapper--services_items .items .items-main {
                  font-size: 2.2rem; } }
              @media (min-width: 1200px) {
                #brn-index-services .brn-index-services__wrapper--services_items .items .items-main {
                  font-size: 2.4rem; } }
            #brn-index-services .brn-index-services__wrapper--services_items .items .items-footer {
              display: inline-flex; }
              #brn-index-services .brn-index-services__wrapper--services_items .items .items-footer .btn-see-more {
                padding: 10px 0;
                font-size: 2rem;
                color: #4E8848;
                position: relative;
                display: inline-flex;
                transition: .2s ease-in-out; }
                @media (min-width: 992px) {
                  #brn-index-services .brn-index-services__wrapper--services_items .items .items-footer .btn-see-more {
                    font-size: 2.2rem; } }
                @media (min-width: 1200px) {
                  #brn-index-services .brn-index-services__wrapper--services_items .items .items-footer .btn-see-more {
                    font-size: 2.4rem; } }
          @media (min-width: 768px) {
            #brn-index-services .brn-index-services__wrapper--services_items {
              box-shadow: none; } }
          #brn-index-services .brn-index-services__wrapper--services_items:hover {
            box-shadow: 1px 3px 10px rgba(0, 0, 0, 0.16); }
            #brn-index-services .brn-index-services__wrapper--services_items:hover span {
              font-weight: 700; }
            #brn-index-services .brn-index-services__wrapper--services_items:hover .btn-see-more .line {
              width: 20px; }
              @media (max-width: 1024px) {
                #brn-index-services .brn-index-services__wrapper--services_items:hover .btn-see-more .line {
                  width: 18px; } }
              #brn-index-services .brn-index-services__wrapper--services_items:hover .btn-see-more .line::before {
                width: 100%;
                transform-origin: right;
                transform: translateY(-50%) rotate(25deg);
                border-top: 2px solid #4E8848; }
              #brn-index-services .brn-index-services__wrapper--services_items:hover .btn-see-more .line::after {
                width: 100%;
                transform-origin: right;
                transform: translateY(-50%) rotate(-25deg);
                border-top: 2px solid #4E8848; }

@keyframes slide {
  0% {
    width: 0; }
  100% {
    width: 5.6rem; } }

#brn-index-project {
  background-color: white;
  height: 100%;
  width: 100%; }
  @media (max-width: 1279px), (max-height: 539px) {
    #brn-index-project {
      height: auto; } }
  #brn-index-project .brn-index-project {
    height: 100vh;
    width: 100%; }
    @media (max-width: 1279px), (max-height: 539px) {
      #brn-index-project .brn-index-project {
        height: auto; } }
    #brn-index-project .brn-index-project__wrapper {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center; }
      @media (max-width: 1279px), (max-height: 539px) {
        #brn-index-project .brn-index-project__wrapper {
          height: auto; } }
      #brn-index-project .brn-index-project__wrapper--project {
        width: 100%;
        padding-top: 3vh;
        margin-top: 3vh;
        position: relative; }
        #brn-index-project .brn-index-project__wrapper--project::after {
          position: absolute;
          top: 0px;
          left: 0;
          width: 100%;
          height: 1px;
          content: "";
          background-color: #4E8848; }
        #brn-index-project .brn-index-project__wrapper--project .nav-custom .nav .btn-all {
          display: inline-flex;
          position: absolute;
          right: 0;
          bottom: -100%; }
          #brn-index-project .brn-index-project__wrapper--project .nav-custom .nav .btn-all .btn {
            font-size: 2rem;
            color: #1C1C1C; }
            @media (min-width: 992px) {
              #brn-index-project .brn-index-project__wrapper--project .nav-custom .nav .btn-all .btn {
                font-size: 2.2rem; } }
            @media (min-width: 1200px) {
              #brn-index-project .brn-index-project__wrapper--project .nav-custom .nav .btn-all .btn {
                font-size: 2.4rem; } }
        #brn-index-project .brn-index-project__wrapper--project .nav-custom .nav .swiper-button-next, #brn-index-project .brn-index-project__wrapper--project .nav-custom .nav .swiper-button-prev {
          position: unset;
          width: auto;
          left: unset;
          right: unset;
          margin-left: 20px; }
          #brn-index-project .brn-index-project__wrapper--project .nav-custom .nav .swiper-button-next::after, #brn-index-project .brn-index-project__wrapper--project .nav-custom .nav .swiper-button-prev::after {
            display: none; }

#brn-index-process {
  background-color: white;
  height: 100%;
  width: 100%; }
  @media (max-width: 1279px), (max-height: 539px) {
    #brn-index-process {
      height: auto; } }
  #brn-index-process .brn-index-process {
    height: 100vh;
    width: 100%; }
    @media (max-width: 1279px), (max-height: 539px) {
      #brn-index-process .brn-index-process {
        height: auto; } }
    #brn-index-process .brn-index-process__wrapper {
      height: 100%;
      display: flex;
      align-items: center; }
      @media (max-width: 1279px), (max-height: 539px) {
        #brn-index-process .brn-index-process__wrapper {
          height: auto; } }
      #brn-index-process .brn-index-process__wrapper--process {
        z-index: 2;
        position: relative;
        width: 100%; }
        @media (min-width: 992px) {
          #brn-index-process .brn-index-process__wrapper--process {
            width: 50%; } }
        #brn-index-process .brn-index-process__wrapper--process__title {
          width: 100% !important;
          max-width: 100% !important; }
          #brn-index-process .brn-index-process__wrapper--process__title > * {
            width: 100% !important;
            max-width: 100% !important; }
        #brn-index-process .brn-index-process__wrapper--process__list {
          margin-top: 30px;
          width: 100%; }
          #brn-index-process .brn-index-process__wrapper--process__list-items {
            padding-right: 10px; }
            #brn-index-process .brn-index-process__wrapper--process__list-items ol {
              padding-inline-start: 40px;
              font-size: 2rem;
              color: #292929; }
              @media (min-width: 992px) {
                #brn-index-process .brn-index-process__wrapper--process__list-items ol {
                  font-size: 2.2rem; } }
              @media (min-width: 1200px) {
                #brn-index-process .brn-index-process__wrapper--process__list-items ol {
                  font-size: 2.4rem; } }
              #brn-index-process .brn-index-process__wrapper--process__list-items ol .items {
                position: relative; }
                #brn-index-process .brn-index-process__wrapper--process__list-items ol .items::before {
                  transition: .3s ease-in-out;
                  opacity: 0;
                  position: absolute;
                  bottom: 0;
                  left: -40px;
                  width: calc(100% + 40px);
                  height: 1px;
                  background-color: #4E8848;
                  content: ""; }
                #brn-index-process .brn-index-process__wrapper--process__list-items ol .items-name {
                  cursor: pointer;
                  padding: 8px 0;
                  display: flex;
                  align-items: center;
                  position: relative; }
                  @media (min-width: 768px) {
                    #brn-index-process .brn-index-process__wrapper--process__list-items ol .items-name {
                      padding: 10px 0; } }
                  @media (min-width: 1200px) {
                    #brn-index-process .brn-index-process__wrapper--process__list-items ol .items-name {
                      padding: 12px 0; } }
                  #brn-index-process .brn-index-process__wrapper--process__list-items ol .items-name span {
                    width: 12px;
                    height: 12px;
                    border-radius: 50%;
                    background: linear-gradient(180deg, #92d665 0%, #00433f 100%);
                    content: "";
                    transition: .2s ease-in-out;
                    margin-left: auto; }
                    @media (min-width: 768px) {
                      #brn-index-process .brn-index-process__wrapper--process__list-items ol .items-name span {
                        width: 18px;
                        height: 18px; } }
                  #brn-index-process .brn-index-process__wrapper--process__list-items ol .items-name::after {
                    position: absolute;
                    top: 100%;
                    left: -40px;
                    width: calc(100% + 40px);
                    height: 1px;
                    background-color: #4E8848;
                    content: ""; }
                #brn-index-process .brn-index-process__wrapper--process__list-items ol .items.active::before {
                  opacity: 1; }
                #brn-index-process .brn-index-process__wrapper--process__list-items ol .items.active .items-name span {
                  width: 18px;
                  height: 6px;
                  border-radius: 10px;
                  animation: rotate .5s ease-in-out; }

@keyframes rotate {
  0% {
    transform: rotate(90deg); }
  100% {
    transform: rotate(0); } }
                #brn-index-process .brn-index-process__wrapper--process__list-items ol .items > * {
                  font-size: 2rem;
                  color: #292929; }
                  @media (min-width: 992px) {
                    #brn-index-process .brn-index-process__wrapper--process__list-items ol .items > * {
                      font-size: 2.2rem; } }
                  @media (min-width: 1200px) {
                    #brn-index-process .brn-index-process__wrapper--process__list-items ol .items > * {
                      font-size: 2.4rem; } }
                #brn-index-process .brn-index-process__wrapper--process__list-items ol .items-infor {
                  padding: 0;
                  font-size: 1.8rem;
                  color: #363636;
                  position: relative;
                  max-height: 0;
                  overflow: hidden;
                  margin-left: -40px;
                  transition: .6s ease-in-out; }
                  @media (min-width: 992px) {
                    #brn-index-process .brn-index-process__wrapper--process__list-items ol .items-infor {
                      font-size: 1.9rem; } }
                  @media (min-width: 1200px) {
                    #brn-index-process .brn-index-process__wrapper--process__list-items ol .items-infor {
                      font-size: 2rem; } }
                  #brn-index-process .brn-index-process__wrapper--process__list-items ol .items-infor ul {
                    padding-inline-start: 21px;
                    list-style-type: disc; }
                  #brn-index-process .brn-index-process__wrapper--process__list-items ol .items-infor.active {
                    max-height: initial; }
                  #brn-index-process .brn-index-process__wrapper--process__list-items ol .items-infor > * {
                    padding: 30px 0; }
                  #brn-index-process .brn-index-process__wrapper--process__list-items ol .items-infor *:not(:last-child) {
                    margin-bottom: 15px; }
      #brn-index-process .brn-index-process__wrapper--project {
        z-index: 1;
        position: relative;
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 24px; }
        @media (min-width: 992px) {
          #brn-index-process .brn-index-process__wrapper--project {
            margin-top: 0px;
            width: 50%;
            justify-content: flex-end; } }
        #brn-index-process .brn-index-process__wrapper--project .svg {
          position: relative;
          width: 85%;
          padding-bottom: 63%; }
          #brn-index-process .brn-index-process__wrapper--project .svg svg {
            position: absolute;
            z-index: 2;
            width: 100%;
            height: 100%;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0; }
            #brn-index-process .brn-index-process__wrapper--project .svg svg #process_line .line {
              stroke: 10;
              stroke-dasharray: 10;
              animation: line_through 20s infinite linear; }
            #brn-index-process .brn-index-process__wrapper--project .svg svg #process_board .process_board_mouse {
              animation: piecesMouse 2s infinite linear; }
            #brn-index-process .brn-index-process__wrapper--project .svg svg #process_girl_1 .process_girl_1_pieceL {
              animation: pieces 3s infinite linear; }
            #brn-index-process .brn-index-process__wrapper--project .svg svg #process_girl_1 .process_girl_1_pieceR {
              animation: piecesRotate 3s infinite ease-in-out;
              transform-origin: center bottom;
              transform-box: fill-box; }
            #brn-index-process .brn-index-process__wrapper--project .svg svg #process_girl_1 .process_girl_1_head {
              animation: piecesRotate 4s infinite ease-in-out reverse;
              transform-origin: center bottom;
              transform-box: fill-box; }
            #brn-index-process .brn-index-process__wrapper--project .svg svg #process_girl_2 .process_girl_2_head {
              animation: piecesRotate 5s infinite ease-in-out reverse;
              transform-origin: center bottom;
              transform-box: fill-box;
              transform: translateY(5px); }
          #brn-index-process .brn-index-process__wrapper--project .svg .circle {
            z-index: 1;
            position: absolute;
            top: -20%;
            right: 30px;
            width: 35%;
            animation: flow_index 4s linear infinite; }

#brn-index-client {
  background-color: white;
  height: 100%;
  width: 100%; }
  @media (max-width: 1279px), (max-height: 539px) {
    #brn-index-client {
      height: auto; } }
  #brn-index-client .brn-index-client {
    height: 100vh;
    width: 100%; }
    @media (max-width: 1279px), (max-height: 539px) {
      #brn-index-client .brn-index-client {
        height: auto; } }
    #brn-index-client .brn-index-client__wrapper {
      height: 100%;
      display: flex;
      align-items: center; }
      @media (max-width: 1279px), (max-height: 539px) {
        #brn-index-client .brn-index-client__wrapper {
          height: auto; } }
      #brn-index-client .brn-index-client__wrapper--client {
        width: 100%; }
        @media (min-width: 992px) {
          #brn-index-client .brn-index-client__wrapper--client {
            width: 33.33333%; } }
        #brn-index-client .brn-index-client__wrapper--client__list {
          margin-top: 2vh;
          padding-top: 2vh;
          padding-bottom: 3vh;
          width: 100%;
          position: relative; }
          #brn-index-client .brn-index-client__wrapper--client__list::after {
            content: "";
            position: absolute;
            top: 0px;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: #4E8848; }
          #brn-index-client .brn-index-client__wrapper--client__list .MyClient {
            padding-bottom: 50px; }
          #brn-index-client .brn-index-client__wrapper--client__list .swiper-pagination {
            overflow: hidden;
            left: 0;
            transform: unset;
            bottom: 5px; }
          #brn-index-client .brn-index-client__wrapper--client__list .client-items__decs {
            margin-bottom: 30px;
            color: #292929; }
          #brn-index-client .brn-index-client__wrapper--client__list .client-items__name {
            margin-top: 3.5vh;
            display: flex;
            flex-direction: column;
            color: #4E8848;
            font-size: 1.8rem; }
            @media (min-width: 992px) {
              #brn-index-client .brn-index-client__wrapper--client__list .client-items__name {
                font-size: 1.9rem; } }
            @media (min-width: 1200px) {
              #brn-index-client .brn-index-client__wrapper--client__list .client-items__name {
                font-size: 2rem; } }
            #brn-index-client .brn-index-client__wrapper--client__list .client-items__name span {
              color: #1C1C1C; }
        #brn-index-client .brn-index-client__wrapper--client__svg {
          margin-top: 24px; }
          @media (min-width: 992px) {
            #brn-index-client .brn-index-client__wrapper--client__svg {
              margin-top: 0; } }
          #brn-index-client .brn-index-client__wrapper--client__svg svg {
            max-height: 20vh;
            overflow: visible;
            width: 100%; }
            #brn-index-client .brn-index-client__wrapper--client__svg svg #client_handshake .client_handshake_circle_rotate {
              animation: circle_handshake_rotate infinite 9s linear;
              transform-origin: center;
              transform-box: fill-box; }
            #brn-index-client .brn-index-client__wrapper--client__svg svg #client_handshake .client_handshake_circle {
              animation: circle_handshake infinite 5s ease-in-out;
              transform-origin: center;
              transform-box: fill-box; }
            #brn-index-client .brn-index-client__wrapper--client__svg svg #client_handshake .client_handshake_paper {
              animation: fadein_paper infinite 3s ease-in-out; }
            #brn-index-client .brn-index-client__wrapper--client__svg svg #client_boyL .client_boyL_hand {
              animation: piecesRotate 3s infinite ease-in-out;
              transform-origin: top right;
              transform-box: fill-box; }
            #brn-index-client .brn-index-client__wrapper--client__svg svg #client_boyL .client_boyL_hand_phone {
              animation: phoneShake 1s infinite ease-in-out;
              transform-origin: center;
              transform-box: fill-box; }
            #brn-index-client .brn-index-client__wrapper--client__svg svg #client_boyR .client_boyR_hand {
              animation: piecesRotate 1.5s infinite ease-in-out;
              transform-origin: top right;
              transform-box: fill-box; }
            #brn-index-client .brn-index-client__wrapper--client__svg svg #client_plants .client_plants_leaf {
              animation: piecesRotate 3s infinite ease-in-out;
              transform-origin: center bottom;
              transform-box: fill-box; }
      #brn-index-client .brn-index-client__wrapper--clientLogo {
        width: 100%;
        margin-top: 24px;
        margin-left: auto; }
        @media (min-width: 992px) {
          #brn-index-client .brn-index-client__wrapper--clientLogo {
            width: 58.33333%;
            margin-top: 0; } }
        #brn-index-client .brn-index-client__wrapper--clientLogo__wrapper {
          width: 100%; }
          @media (min-width: 992px) {
            #brn-index-client .brn-index-client__wrapper--clientLogo__wrapper {
              padding-bottom: 80%;
              position: relative; } }
          #brn-index-client .brn-index-client__wrapper--clientLogo__wrapper .MyClientLogo {
            width: 100%;
            height: 100%; }
            @media (min-width: 992px) {
              #brn-index-client .brn-index-client__wrapper--clientLogo__wrapper .MyClientLogo {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0; } }
        #brn-index-client .brn-index-client__wrapper--clientLogo_items {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center; }
          #brn-index-client .brn-index-client__wrapper--clientLogo_items > * {
            width: 33.3333%; }
          #brn-index-client .brn-index-client__wrapper--clientLogo_items .clientLogo {
            display: flex;
            align-items: center;
            justify-content: space-around;
            max-height: 100%;
            height: 120px;
            padding: 2% 3%; }
            #brn-index-client .brn-index-client__wrapper--clientLogo_items .clientLogo img {
              width: auto;
              object-fit: contain; }

#brn-index-contact {
  background-color: white;
  height: 100%;
  width: 100%; }
  @media (max-width: 1279px), (max-height: 539px) {
    #brn-index-contact {
      height: auto; } }
  #brn-index-contact .brn-index-contact {
    height: 100vh;
    width: 100%; }
    @media (max-width: 1279px), (max-height: 539px) {
      #brn-index-contact .brn-index-contact {
        height: auto; } }
    #brn-index-contact .brn-index-contact__wrapper {
      height: 100%;
      display: flex;
      align-items: center; }
      @media (max-width: 1279px), (max-height: 539px) {
        #brn-index-contact .brn-index-contact__wrapper {
          height: auto; } }
      #brn-index-contact .brn-index-contact__wrapper--contact {
        width: 100%; }
        #brn-index-contact .brn-index-contact__wrapper--contact__infor {
          position: relative;
          padding-top: 2vh;
          margin-top: 2vh; }
          #brn-index-contact .brn-index-contact__wrapper--contact__infor::after {
            position: absolute;
            top: 0px;
            left: 50%;
            transform: translateX(-50%);
            width: 100%;
            height: 1px;
            content: "";
            background-color: #4E8848; }
        #brn-index-contact .brn-index-contact__wrapper--contact__logo {
          margin-top: 6vh;
          display: flex;
          justify-content: center; }
          #brn-index-contact .brn-index-contact__wrapper--contact__logo svg {
            max-height: 20vh; }

.about-1 {
  width: 100%; }
  .about-1__title {
    width: 100%;
    display: flex;
    justify-content: center; }
    .about-1__title > * {
      font-size: 4.4rem;
      color: #000;
      font-weight: 700; }
      @media (min-width: 768px) {
        .about-1__title > * {
          font-size: 4.8rem; } }
      @media (min-width: 992px) {
        .about-1__title > * {
          font-size: 5rem; } }
      @media (min-width: 1200px) {
        .about-1__title > * {
          font-size: 5.2rem; } }
      @media (min-width: 1400px) {
        .about-1__title > * {
          font-size: 6rem; } }
      @media (min-width: 1680px) {
        .about-1__title > * {
          font-size: 7.4rem; } }
  .about-1__content {
    width: 100%;
    margin-top: 2vh; }
    @media (min-width: 1400px) {
      .about-1__content {
        margin-top: 2vh; } }
  .about-1__slide .swiper-container {
    padding: 8px; }
  .about-1__box {
    position: relative;
    overflow: hidden;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.16); }
    .about-1__box:hover .border span:nth-child(1) {
      top: 0;
      left: 0%; }
    .about-1__box:hover .border span:nth-child(2) {
      top: 0%;
      right: 0%; }
    .about-1__box:hover .border span:nth-child(3) {
      bottom: 0;
      right: 0%; }
    .about-1__box:hover .border span:nth-child(4) {
      bottom: 0%;
      left: 0%; }
    .about-1__box .border span {
      display: block;
      background: #4E8848;
      transition: all 0.4s ease; }
      .about-1__box .border span:nth-child(1), .about-1__box .border span:nth-child(3) {
        position: absolute;
        height: 2px;
        width: 100%; }
      .about-1__box .border span:nth-child(2), .about-1__box .border span:nth-child(4) {
        position: absolute;
        height: 100%;
        width: 2px; }
      .about-1__box .border span:nth-child(1) {
        top: 0;
        left: -100%;
        transition-delay: 0.1s; }
      .about-1__box .border span:nth-child(2) {
        top: -100%;
        right: 0%;
        transition-delay: 0.2s; }
      .about-1__box .border span:nth-child(3) {
        bottom: 0;
        right: -100%;
        transition-delay: 0.3s; }
      .about-1__box .border span:nth-child(4) {
        bottom: -100%;
        left: 0%;
        transition-delay: 0.4s; }

.about-2__title {
  padding-bottom: 3.5vh;
  margin-bottom: 3.5vh;
  position: relative; }
  @media (min-width: 1400px) {
    .about-2__title {
      padding-bottom: 5vh;
      margin-bottom: 5vh; } }
  @media (min-width: 1400px) and (max-height: 900px) {
    .about-2__title {
      padding-bottom: 3.5vh;
      margin-bottom: 3.5vh; } }
  .about-2__title::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 1px;
    background-color: #4E8848; }

.about-2__content {
  margin-right: 0;
  margin-left: 0;
  width: 100%;
  padding-top: 20px;
  margin-top: -20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: center; }
  @media (min-width: 992px) {
    .about-2__content {
      margin-right: -40px;
      margin-left: -40px;
      width: calc(100% + 80px); } }
  .about-2__content > * {
    padding-top: 20px;
    flex: 0 0 100%;
    width: 100%;
    max-width: 100%; }
    @media (min-width: 576px) {
      .about-2__content > * {
        flex: 0 0 50%;
        width: 50%;
        max-width: 50%; } }
    @media (min-width: 992px) {
      .about-2__content > * {
        flex: 0 0 33.33333%;
        width: 33.33333%;
        max-width: 33.33333%; } }

.about-2__box {
  display: flex;
  flex-direction: column;
  padding: 16px 10px 0px 10px; }
  @media (min-width: 992px) {
    .about-2__box {
      padding: 20px 30px 0px 30px; } }
  @media (min-width: 1400px) {
    .about-2__box {
      padding: 20px 40px 0 40px; } }
  .about-2__box--img {
    position: relative;
    padding-top: 60%; }

@media (min-width: 1400px) and (max-height: 900px) {
  .about-2__box--img {
    padding-top: 20vh; } }
    .about-2__box--img img {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      left: 0;
      right: 0;
      bottom: 0;
      object-fit: contain; }
  .about-2__box--desc {
    margin-top: 3vh; }
    @media (min-width: 1400px) {
      .about-2__box--desc {
        margin-top: 6vh; } }
  @media (min-width: 1400px) and (max-height: 900px) {
    .about-2__box--desc {
      margin-top: 3vh; } }
    .about-2__box--desc > * {
      text-align: center;
      font-size: 2rem;
      color: #292929;
      font-weight: 400; }
      @media (min-width: 992px) {
        .about-2__box--desc > * {
          font-size: 2.2rem; } }
      @media (min-width: 1200px) {
        .about-2__box--desc > * {
          font-size: 2.4rem; } }

.about-3 {
  opacity: 0;
  transition: .3s all linear; }

.fp-viewing-2 .about-3 {
  opacity: 1; }

#about-3 .about-3__content {
  padding-top: 5vh;
  margin-top: 5vh;
  width: 100%;
  position: relative; }
  @media (max-width: 1336px) {
    #about-3 .about-3__content {
      padding-top: 3.5vh;
      margin-top: 3.5vh; } }
  #about-3 .about-3__content::after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #4E8848; }
  #about-3 .about-3__content .swiper-container {
    padding: 6px 3px;
    margin-left: -1rem;
    margin-right: -1rem; }
    @media (min-width: 1680px) {
      #about-3 .about-3__content .swiper-container {
        margin-left: -1.5rem;
        margin-right: -1.5rem; } }
  #about-3 .about-3__content .swiper-slide {
    height: auto; }
  #about-3 .about-3__content-block {
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem; }
    @media (min-width: 1680px) {
      #about-3 .about-3__content-block {
        padding-left: 1.5rem;
        padding-right: 1.5rem; } }
  #about-3 .about-3__content-img {
    position: relative;
    width: 100%;
    height: 0;
    padding-top: 45%; }
    @media (min-width: 1680px) {
      #about-3 .about-3__content-img {
        padding-top: 33.333%; } }
    #about-3 .about-3__content-img img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;
      padding: 7% 12%;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16); }

.brand1 {
  padding-top: 24px; }
  .brand1-banner__img {
    position: relative;
    padding-top: 46.8%; }
    .brand1-banner__img img {
      position: absolute;
      top: 0;
      left: 0; }
  @media (min-width: 768px) {
    .brand1 {
      padding-top: 40px; } }
  @media (min-width: 992px) {
    .brand1 {
      padding-top: 80px; } }
  .brand1-container {
    position: relative; }
    .brand1-container .brand1-pagination .btn {
      width: 60px;
      height: 60px;
      background-color: #4E8848;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1; }
      @media (max-width: 1024px) {
        .brand1-container .brand1-pagination .btn {
          width: 48px;
          height: 48px; } }
    .brand1-container .brand1-pagination .btn-next {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%); }
    .brand1-container .brand1-pagination .btn-prev {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%) rotate(180deg); }
  .brand1-wrapper {
    width: 100%; }
  .brand1-img {
    margin-top: 24px; }
    @media (min-width: 768px) {
      .brand1-img {
        margin-top: 40px; } }
    @media (min-width: 992px) {
      .brand1-img {
        margin-top: 80px; } }
    .brand1-img > * {
      user-select: none;
      height: auto;
      width: 100%;
      max-width: 100%; }

.brand1-img {
  position: relative; }
  .brand1-img > img {
    opacity: 0; }
  .brand1-img .part {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0; }
    .brand1-img .part .section:nth-of-type(1) {
      position: absolute;
      left: 0;
      top: 1px;
      width: 100%;
      height: 50%; }
      .brand1-img .part .section:nth-of-type(1) img {
        object-fit: cover;
        object-position: 0 4px; }
    .brand1-img .part .section:nth-of-type(2) {
      position: absolute;
      left: 0;
      top: 50%;
      width: 100%;
      height: 50%; }
      .brand1-img .part .section:nth-of-type(2) img {
        object-fit: cover;
        object-position: bottom; }

.brand2 {
  margin-top: 100px; }
  @media (max-width: 1280px) {
    .brand2 {
      margin-top: 80px; } }
  @media (max-width: 640px) {
    .brand2 {
      margin-top: 64px; } }
  .brand2-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: center;
    margin-top: -32px;
    margin-left: -32px;
    margin-right: -32px;
    width: calc(100% + 64px); }
    @media (max-width: 1280px) {
      .brand2-wrapper {
        margin-top: -24px;
        margin-left: -16px;
        margin-right: -16px;
        width: calc(100% + 32px); } }
    @media (max-width: 900px) {
      .brand2-wrapper {
        margin-top: -24px;
        margin-left: -12px;
        margin-right: -12px;
        width: calc(100% + 24px); } }
    @media (max-width: 640px) {
      .brand2-wrapper {
        margin-top: -24px;
        margin-left: -8px;
        margin-right: -8px;
        width: calc(100% + 16px); } }
    .brand2-wrapper > * {
      flex: 0 0 33.33333%;
      width: 33.33333%;
      max-width: 33.33333%;
      padding-top: 32px;
      padding-left: 32px;
      padding-right: 32px; }
      @media (max-width: 1280px) {
        .brand2-wrapper > * {
          padding-top: 24px;
          padding-left: 16px;
          padding-right: 16px; } }
      @media (max-width: 900px) {
        .brand2-wrapper > * {
          padding-top: 24px;
          padding-left: 12px;
          padding-right: 12px; } }
      @media (max-width: 640px) {
        .brand2-wrapper > * {
          padding-top: 24px;
          padding-left: 8px;
          padding-right: 8px; } }
      @media (max-width: 900px) {
        .brand2-wrapper > * {
          flex: 0 0 50%;
          width: 50%;
          max-width: 50%; } }
      @media (max-width: 540px) {
        .brand2-wrapper > * {
          flex: 0 0 100%;
          width: 100%;
          max-width: 100%; } }

.contact1 .contact__title {
  width: 100%;
  text-align: center; }

.contact1 .contact-form {
  margin-top: 3vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  width: 100%; }
  @media (min-width: 1400px) {
    .contact1 .contact-form {
      margin-top: 56px; } }
  @media (min-width: 992px) {
    .contact1 .contact-form {
      margin-left: -90px;
      width: calc(100% + 90px); } }
  .contact1 .contact-form--left {
    flex: 0 0 100%;
    display: flex;
    align-items: center;
    justify-content: center; }
    .contact1 .contact-form--left svg {
      max-height: 54vh; }
    @media (min-width: 992px) {
      .contact1 .contact-form--left {
        flex: 0 0 50%; } }
  .contact1 .contact-form--right {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 24px;
    padding-left: 0px;
    flex: 0 0 100%; }
    @media (min-width: 1400px) {
      .contact1 .contact-form--right {
        margin-top: 0px;
        padding-left: 12px; } }
    @media (min-width: 1400px) {
      .contact1 .contact-form--right {
        padding-left: 45px; } }
    @media (min-width: 992px) {
      .contact1 .contact-form--right {
        flex: 1; } }

.contact2 .contact .container {
  align-items: stretch; }

.contact2 .contact-left {
  width: 100% !important;
  position: relative;
  height: 540px; }
  @media (min-width: 992px) {
    .contact2 .contact-left {
      height: auto;
      width: 50% !important; } }
  @media (min-width: 1200px) {
    .contact2 .contact-left {
      width: 58.33333% !important; } }
  .contact2 .contact-left > * {
    width: 100%;
    height: 100%; }

.contact2 .contact-right {
  width: 100% !important; }
  @media (min-width: 992px) {
    .contact2 .contact-right {
      width: 50% !important; } }
  @media (min-width: 1200px) {
    .contact2 .contact-right {
      width: 41.66667% !important; } }
  .contact2 .contact-right--wrapper {
    padding-top: 24px;
    text-align: left; }
    @media (min-width: 992px) {
      .contact2 .contact-right--wrapper {
        text-align: initial;
        padding-bottom: 12px;
        padding-left: 32px;
        padding-top: 12px; } }
    @media (min-width: 1200px) {
      .contact2 .contact-right--wrapper {
        padding-top: 36px;
        padding-bottom: 36px;
        padding-left: 56px; } }

.contact2 .contact-info {
  border-top: 1px solid #4E8848;
  padding-top: 36px;
  margin-top: 12px; }
  .contact2 .contact-info__item {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: stretch; }
    .contact2 .contact-info__item:not(:first-child) {
      margin-top: 24px; }
    .contact2 .contact-info__item-icon {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      width: 50px;
      flex: 0 0 50px; }
      .contact2 .contact-info__item-icon > * {
        line-height: 2.6;
        max-width: 100%; }
    .contact2 .contact-info__item-desc > * {
      font-size: 2.6rem;
      font-weight: 400;
      white-space: pre-wrap;
      line-height: 1.4; }
      @media (min-width: 768px) {
        .contact2 .contact-info__item-desc > * {
          font-size: 2.8rem; } }
      @media (min-width: 992px) {
        .contact2 .contact-info__item-desc > * {
          font-size: 3rem; } }
      @media (min-width: 1200px) {
        .contact2 .contact-info__item-desc > * {
          font-size: 3.2rem; } }
    .contact2 .contact-info__item-desc.desc-address {
      letter-spacing: 1.2px; }

.news1-container {
  padding-top: 60px; }
  @media (max-width: 1279px), (max-height: 539px) {
    .news1-container {
      padding-top: 24px; } }

.news1__title {
  width: 100%;
  text-align: center; }

.news1-cards {
  padding-top: 60px;
  margin-right: -20px;
  margin-left: -20px;
  margin-top: -40px;
  width: calc(100% + 40px);
  align-items: stretch; }
  .news1-cards > * {
    padding-right: 20px;
    padding-left: 20px;
    padding-top: 40px; }

.news1 .swiper-slide {
  height: auto;
  display: flex;
  flex-direction: column; }
  .news1 .swiper-slide > * {
    flex: 1; }

.service-1__title,
.service-3__title,
.service-5__title {
  width: 100%; }
  .service-1__title .title,
  .service-3__title .title,
  .service-5__title .title {
    width: 100%; }
    .service-1__title .title__content, .service-1__title #brn-index-services .brn-index-services__wrapper--intro, #brn-index-services .service-1__title .brn-index-services__wrapper--intro, .service-1__title #brn-index-project .brn-index-project__wrapper--intro, #brn-index-project .service-1__title .brn-index-project__wrapper--intro,
    .service-3__title .title__content,
    .service-3__title #brn-index-services .brn-index-services__wrapper--intro,
    #brn-index-services .service-3__title .brn-index-services__wrapper--intro,
    .service-3__title #brn-index-project .brn-index-project__wrapper--intro,
    #brn-index-project .service-3__title .brn-index-project__wrapper--intro,
    .service-5__title .title__content,
    .service-5__title #brn-index-services .brn-index-services__wrapper--intro,
    #brn-index-services .service-5__title .brn-index-services__wrapper--intro,
    .service-5__title #brn-index-project .brn-index-project__wrapper--intro,
    #brn-index-project .service-5__title .brn-index-project__wrapper--intro {
      justify-content: space-between; }
    .service-1__title .title__desc > *, .service-1__title #brn-index-services .brn-index-services__wrapper--intro_decs > *, #brn-index-services .service-1__title .brn-index-services__wrapper--intro_decs > *, .service-1__title #brn-index-project .brn-index-project__wrapper--intro_decs > *, #brn-index-project .service-1__title .brn-index-project__wrapper--intro_decs > *,
    .service-3__title .title__desc > *,
    .service-3__title #brn-index-services .brn-index-services__wrapper--intro_decs > *,
    #brn-index-services .service-3__title .brn-index-services__wrapper--intro_decs > *,
    .service-3__title #brn-index-project .brn-index-project__wrapper--intro_decs > *,
    #brn-index-project .service-3__title .brn-index-project__wrapper--intro_decs > *,
    .service-5__title .title__desc > *,
    .service-5__title #brn-index-services .brn-index-services__wrapper--intro_decs > *,
    #brn-index-services .service-5__title .brn-index-services__wrapper--intro_decs > *,
    .service-5__title #brn-index-project .brn-index-project__wrapper--intro_decs > *,
    #brn-index-project .service-5__title .brn-index-project__wrapper--intro_decs > * {
      font-size: 3rem; }
      @media (max-width: 1280px) {
        .service-1__title .title__desc > *, .service-1__title #brn-index-services .brn-index-services__wrapper--intro_decs > *, #brn-index-services .service-1__title .brn-index-services__wrapper--intro_decs > *, .service-1__title #brn-index-project .brn-index-project__wrapper--intro_decs > *, #brn-index-project .service-1__title .brn-index-project__wrapper--intro_decs > *,
        .service-3__title .title__desc > *,
        .service-3__title #brn-index-services .brn-index-services__wrapper--intro_decs > *,
        #brn-index-services .service-3__title .brn-index-services__wrapper--intro_decs > *,
        .service-3__title #brn-index-project .brn-index-project__wrapper--intro_decs > *,
        #brn-index-project .service-3__title .brn-index-project__wrapper--intro_decs > *,
        .service-5__title .title__desc > *,
        .service-5__title #brn-index-services .brn-index-services__wrapper--intro_decs > *,
        #brn-index-services .service-5__title .brn-index-services__wrapper--intro_decs > *,
        .service-5__title #brn-index-project .brn-index-project__wrapper--intro_decs > *,
        #brn-index-project .service-5__title .brn-index-project__wrapper--intro_decs > * {
          font-size: 2.8rem; } }
      @media (max-width: 640px) {
        .service-1__title .title__desc > *, .service-1__title #brn-index-services .brn-index-services__wrapper--intro_decs > *, #brn-index-services .service-1__title .brn-index-services__wrapper--intro_decs > *, .service-1__title #brn-index-project .brn-index-project__wrapper--intro_decs > *, #brn-index-project .service-1__title .brn-index-project__wrapper--intro_decs > *,
        .service-3__title .title__desc > *,
        .service-3__title #brn-index-services .brn-index-services__wrapper--intro_decs > *,
        #brn-index-services .service-3__title .brn-index-services__wrapper--intro_decs > *,
        .service-3__title #brn-index-project .brn-index-project__wrapper--intro_decs > *,
        #brn-index-project .service-3__title .brn-index-project__wrapper--intro_decs > *,
        .service-5__title .title__desc > *,
        .service-5__title #brn-index-services .brn-index-services__wrapper--intro_decs > *,
        #brn-index-services .service-5__title .brn-index-services__wrapper--intro_decs > *,
        .service-5__title #brn-index-project .brn-index-project__wrapper--intro_decs > *,
        #brn-index-project .service-5__title .brn-index-project__wrapper--intro_decs > * {
          font-size: 2.7rem; } }

.service-1__content,
.service-3__content,
.service-5__content {
  padding-top: 24px;
  margin-top: 24px;
  width: 100%;
  position: relative; }
  @media (min-width: 992px) {
    .service-1__content,
    .service-3__content,
    .service-5__content {
      padding-top: 2vh;
      margin-top: 2vh; } }
  @media (min-width: 1680px) {
    .service-1__content,
    .service-3__content,
    .service-5__content {
      padding-top: 70px;
      margin-top: 50px; } }
  @media (min-width: 1680px) and (min-height: 900px) {
    .service-1__content,
    .service-3__content,
    .service-5__content {
      padding-top: 2vh;
      margin-top: 2vh; } }
  .service-1__content::after,
  .service-3__content::after,
  .service-5__content::after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #4E8848; }
  .service-1__content .brand-service__img,
  .service-3__content .brand-service__img,
  .service-5__content .brand-service__img {
    overflow: hidden;
    padding-right: 0px;
    display: flex;
    justify-content: center;
    align-items: center; }
    @media (min-width: 992px) {
      .service-1__content .brand-service__img,
      .service-3__content .brand-service__img,
      .service-5__content .brand-service__img {
        padding-right: 30px; } }
    @media (min-width: 1200px) {
      .service-1__content .brand-service__img,
      .service-3__content .brand-service__img,
      .service-5__content .brand-service__img {
        padding-right: 60px; } }
    .service-1__content .brand-service__img svg,
    .service-3__content .brand-service__img svg,
    .service-5__content .brand-service__img svg {
      max-height: 54vh;
      height: auto;
      width: 100%; }
      @media (min-width: 768px) {
        .service-1__content .brand-service__img svg,
        .service-3__content .brand-service__img svg,
        .service-5__content .brand-service__img svg {
          height: auto;
          width: 80%; } }
    .service-1__content .brand-service__img .maybay,
    .service-3__content .brand-service__img .maybay,
    .service-5__content .brand-service__img .maybay {
      transform-origin: center;
      transform-box: fill-box; }
  .service-1__content .brand-service__infor,
  .service-3__content .brand-service__infor,
  .service-5__content .brand-service__infor {
    order: 1; }
    @media (min-width: 992px) {
      .service-1__content .brand-service__infor,
      .service-3__content .brand-service__infor,
      .service-5__content .brand-service__infor {
        order: 2; } }

.service-2__title,
.service-4__title {
  width: 100%; }
  .service-2__title .title__content, .service-2__title #brn-index-services .brn-index-services__wrapper--intro, #brn-index-services .service-2__title .brn-index-services__wrapper--intro, .service-2__title #brn-index-project .brn-index-project__wrapper--intro, #brn-index-project .service-2__title .brn-index-project__wrapper--intro,
  .service-4__title .title__content,
  .service-4__title #brn-index-services .brn-index-services__wrapper--intro,
  #brn-index-services .service-4__title .brn-index-services__wrapper--intro,
  .service-4__title #brn-index-project .brn-index-project__wrapper--intro,
  #brn-index-project .service-4__title .brn-index-project__wrapper--intro {
    justify-content: space-between; }
  .service-2__title .title__desc > *, .service-2__title #brn-index-services .brn-index-services__wrapper--intro_decs > *, #brn-index-services .service-2__title .brn-index-services__wrapper--intro_decs > *, .service-2__title #brn-index-project .brn-index-project__wrapper--intro_decs > *, #brn-index-project .service-2__title .brn-index-project__wrapper--intro_decs > *,
  .service-4__title .title__desc > *,
  .service-4__title #brn-index-services .brn-index-services__wrapper--intro_decs > *,
  #brn-index-services .service-4__title .brn-index-services__wrapper--intro_decs > *,
  .service-4__title #brn-index-project .brn-index-project__wrapper--intro_decs > *,
  #brn-index-project .service-4__title .brn-index-project__wrapper--intro_decs > * {
    font-size: 3rem; }
    @media (max-width: 1280px) {
      .service-2__title .title__desc > *, .service-2__title #brn-index-services .brn-index-services__wrapper--intro_decs > *, #brn-index-services .service-2__title .brn-index-services__wrapper--intro_decs > *, .service-2__title #brn-index-project .brn-index-project__wrapper--intro_decs > *, #brn-index-project .service-2__title .brn-index-project__wrapper--intro_decs > *,
      .service-4__title .title__desc > *,
      .service-4__title #brn-index-services .brn-index-services__wrapper--intro_decs > *,
      #brn-index-services .service-4__title .brn-index-services__wrapper--intro_decs > *,
      .service-4__title #brn-index-project .brn-index-project__wrapper--intro_decs > *,
      #brn-index-project .service-4__title .brn-index-project__wrapper--intro_decs > * {
        font-size: 2.8rem; } }
    @media (max-width: 640px) {
      .service-2__title .title__desc > *, .service-2__title #brn-index-services .brn-index-services__wrapper--intro_decs > *, #brn-index-services .service-2__title .brn-index-services__wrapper--intro_decs > *, .service-2__title #brn-index-project .brn-index-project__wrapper--intro_decs > *, #brn-index-project .service-2__title .brn-index-project__wrapper--intro_decs > *,
      .service-4__title .title__desc > *,
      .service-4__title #brn-index-services .brn-index-services__wrapper--intro_decs > *,
      #brn-index-services .service-4__title .brn-index-services__wrapper--intro_decs > *,
      .service-4__title #brn-index-project .brn-index-project__wrapper--intro_decs > *,
      #brn-index-project .service-4__title .brn-index-project__wrapper--intro_decs > * {
        font-size: 2.7rem; } }

.service-2__content,
.service-4__content {
  padding-top: 24px;
  margin-top: 24px;
  width: 100%;
  position: relative; }
  @media (min-width: 992px) {
    .service-2__content,
    .service-4__content {
      padding-top: 2vh;
      margin-top: 2vh; } }
  @media (min-width: 1680px) {
    .service-2__content,
    .service-4__content {
      padding-top: 70px;
      margin-top: 50px; } }
  @media (min-width: 1680px) and (min-height: 900px) {
    .service-2__content,
    .service-4__content {
      padding-top: 2vh;
      margin-top: 2vh; } }
  .service-2__content::after,
  .service-4__content::after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #4E8848; }
  .service-2__content .brand-service__img,
  .service-4__content .brand-service__img {
    padding-right: 0px;
    order: 1;
    display: flex;
    justify-content: center;
    align-items: center; }
    @media (min-width: 992px) {
      .service-2__content .brand-service__img,
      .service-4__content .brand-service__img {
        order: 2;
        padding-right: 30px; } }
    @media (min-width: 1200px) {
      .service-2__content .brand-service__img,
      .service-4__content .brand-service__img {
        padding-right: 60px; } }
    .service-2__content .brand-service__img svg,
    .service-4__content .brand-service__img svg {
      max-height: 54vh;
      height: auto;
      width: 100%; }
      @media (min-width: 768px) {
        .service-2__content .brand-service__img svg,
        .service-4__content .brand-service__img svg {
          height: auto;
          width: 80%; } }
    .service-2__content .brand-service__img .kinhhienvi-rotate,
    .service-4__content .brand-service__img .kinhhienvi-rotate {
      animation: flow 4s linear infinite; }

@keyframes flow {
  0% {
    transform: rotate(0deg) translate(-20px) rotate(0deg); }
  100% {
    transform: rotate(360deg) translate(-20px) rotate(-360deg); } }
  .service-2__content .brand-service__infor,
  .service-4__content .brand-service__infor {
    order: 2; }
    @media (min-width: 992px) {
      .service-2__content .brand-service__infor,
      .service-4__content .brand-service__infor {
        order: 1; } }

.service-4 .serSvg04 .search {
  animation: flow 4s linear infinite; }

@keyframes flow {
  0% {
    transform: rotate(0deg) translate(-20px) rotate(0deg); }
  100% {
    transform: rotate(360deg) translate(-20px) rotate(-360deg); } }

.news-detail {
  padding-top: 20rem; }
  .news-detail__title {
    margin-top: 5rem; }
    .news-detail__title > * {
      color: #4E8848;
      font-weight: 700;
      font-size: 3.4rem;
      line-height: 1.25; }
      @media (min-width: 992px) {
        .news-detail__title > * {
          font-size: 3.6rem; } }
      @media (min-width: 1200px) {
        .news-detail__title > * {
          font-size: 4rem; } }
  .news-detail__time {
    margin-top: 6rem;
    color: #4E8848;
    font-weight: 400;
    font-size: 15px;
    line-height: 1.21;
    letter-spacing: 0.5px; }
    @media (min-width: 992px) {
      .news-detail__time {
        font-size: 1.5rem; } }
    @media (min-width: 1200px) {
      .news-detail__time {
        font-size: 1.6rem; } }
  .news-detail-social {
    margin-top: 18px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center; }
    .news-detail-social__item {
      cursor: pointer;
      max-height: 40px;
      padding: 8px 12px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      color: white;
      font-size: 1.6rem;
      background-color: transparent;
      position: relative;
      overflow: hidden;
      transition: .16s all ease-in-out; }
      @media (min-width: 992px) {
        .news-detail-social__item {
          font-size: 1.7rem; } }
      @media (min-width: 1200px) {
        .news-detail-social__item {
          font-size: 1.8rem; } }
      .news-detail-social__item::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #0d8af0;
        z-index: -1; }
      .news-detail-social__item::after {
        content: "";
        position: absolute;
        top: 50%;
        transform: translate(0%, -50%);
        left: 0;
        width: 100%;
        height: 0%;
        background-color: #4E8848;
        z-index: -1;
        transition: .16s all ease-in-out; }
      .news-detail-social__item .iconsSocial {
        width: 24px; }
      .news-detail-social__item span {
        font-weight: 700;
        text-transform: uppercase;
        margin-left: 8px;
        letter-spacing: 0.5;
        white-space: nowrap; }
      .news-detail-social__item:hover {
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16); }
        .news-detail-social__item:hover::after {
          width: 100%;
          height: 100%;
          transform-origin: center; }
    .news-detail-social__item + .news-detail-social__item {
      margin-left: 16px; }
  .news-detail-content {
    margin-top: 6rem; }

.breadcrumbs {
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-x: auto;
  width: 100%; }
  .breadcrumbs__item:not(:first-child) {
    margin-left: 26px;
    padding-left: 26px;
    position: relative; }
    .breadcrumbs__item:not(:first-child)::after {
      content: "";
      position: absolute;
      top: calc(50% + 2px);
      left: 0;
      transform: translate(-50%, -50%);
      width: 0;
      height: 0;
      border-top: 7px solid transparent;
      border-bottom: 7px solid transparent;
      border-left: 13px solid #9E9E9E; }
  .breadcrumbs__item > * {
    font-size: 2rem;
    line-height: 1.32;
    font-weight: 500;
    color: #9E9E9E; }
    @media (min-width: 992px) {
      .breadcrumbs__item > * {
        font-size: 2.2rem; } }
    @media (min-width: 1200px) {
      .breadcrumbs__item > * {
        font-size: 2.4rem; } }
  .breadcrumbs__item a {
    display: block;
    cursor: pointer; }
  .breadcrumbs__item:last-child a {
    cursor: auto; }

#brn-index-8 {
  background-color: white;
  height: 100%;
  width: 100%; }
  #brn-index-8 .brn-index-8 {
    width: 100%; }
    #brn-index-8 .brn-index-8__wrapper {
      height: 100%;
      margin-bottom: 40px;
      flex-direction: column;
      justify-content: center; }
      #brn-index-8 .brn-index-8__wrapper--intro {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        position: relative;
        padding-bottom: 5vh;
        flex-direction: column;
        align-items: center; }
        @media (min-width: 992px) {
          #brn-index-8 .brn-index-8__wrapper--intro {
            flex-direction: row; } }
        #brn-index-8 .brn-index-8__wrapper--intro > * {
          width: auto; }
        #brn-index-8 .brn-index-8__wrapper--intro::after {
          content: "";
          position: absolute;
          bottom: 0px;
          left: 0;
          width: 100%;
          height: 1px;
          background-color: #4E8848; }
        #brn-index-8 .brn-index-8__wrapper--intro_title {
          display: inline-block;
          cursor: pointer;
          font-weight: 700;
          font-size: 4.4rem; }
          @media (min-width: 768px) {
            #brn-index-8 .brn-index-8__wrapper--intro_title {
              font-size: 4.8rem; } }
          @media (min-width: 992px) {
            #brn-index-8 .brn-index-8__wrapper--intro_title {
              font-size: 5rem; } }
          @media (min-width: 1200px) {
            #brn-index-8 .brn-index-8__wrapper--intro_title {
              font-size: 5.2rem; } }
          @media (min-width: 1400px) {
            #brn-index-8 .brn-index-8__wrapper--intro_title {
              font-size: 6rem; } }
          @media (min-width: 1680px) {
            #brn-index-8 .brn-index-8__wrapper--intro_title {
              font-size: 7.4rem; } }
          #brn-index-8 .brn-index-8__wrapper--intro_title.active {
            color: #4E8848; }
      #brn-index-8 .brn-index-8__wrapper--project {
        padding-top: 5vh;
        width: 100%;
        position: relative;
        display: none; }
        #brn-index-8 .brn-index-8__wrapper--project.active {
          display: block; }

@keyframes fadeEffect {
  0% {
    transform: scaleX(0); }
  100% {
    transform: scaleX(1); } }
        #brn-index-8 .brn-index-8__wrapper--project .project_items:not(:last-child) {
          margin-bottom: 30px; }
          @media (min-width: 992px) {
            #brn-index-8 .brn-index-8__wrapper--project .project_items:not(:last-child) {
              margin-bottom: 60px; } }

#fullpage .section .scrollText {
  height: 220px;
  overflow: auto; }

.capacity-profile {
  width: 100%;
  height: calc(100vh - 188.44px); }
  @media (min-width: 768px) {
    .capacity-profile {
      height: calc(100vh - 246.34px); } }
  .capacity-profile .container {
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    text-align: center; }

.braney-title {
  display: block;
  width: 100%; }
  .braney-title > * {
    font-weight: 700;
    font-size: 4.4rem; }
    @media (min-width: 768px) {
      .braney-title > * {
        font-size: 4.8rem; } }
    @media (min-width: 992px) {
      .braney-title > * {
        font-size: 5rem; } }
    @media (min-width: 1200px) {
      .braney-title > * {
        font-size: 5.2rem; } }
    @media (min-width: 1400px) {
      .braney-title > * {
        font-size: 6rem; } }
    @media (min-width: 1680px) {
      .braney-title > * {
        font-size: 7.4rem; } }

.braney-desc {
  margin-top: 30px;
  display: block;
  width: 100%; }
  .braney-desc > * {
    font-size: 2rem;
    color: #1C1C1C;
    line-height: 1.2;
    font-weight: 400; }
    @media (min-width: 992px) {
      .braney-desc > * {
        font-size: 2.2rem; } }
    @media (min-width: 1200px) {
      .braney-desc > * {
        font-size: 2.4rem; } }
    @media (max-width: 1280px) {
      .braney-desc > * {
        font-size: 2rem; } }

.braney-link {
  margin-top: 30px;
  display: inline-flex;
  position: relative;
  overflow: hidden;
  border-radius: 30px;
  transition: .3s ease-in-out; }
  .braney-link::after {
    transition: .3s ease-in-out;
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 200%;
    content: "";
    background: linear-gradient(180deg, #92d665 0%, #00433f 50%, #92d665 100%); }
  .braney-link a {
    display: inline-flex;
    background: transparent;
    padding: 6px 40px;
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 29px;
    color: #ffff;
    border-radius: 30px;
    position: relative;
    z-index: 2;
    overflow: hidden; }
    @media (min-width: 992px) {
      .braney-link a {
        padding: 13px 60px; } }
    @media (min-width: 992px) {
      .braney-link a {
        font-size: 1.7rem; } }
    @media (min-width: 1200px) {
      .braney-link a {
        font-size: 1.8rem; } }
  .braney-link:hover {
    transform: translateY(-3px); }
    .braney-link:hover::after {
      top: -100%; }

.braney-title-3 > * {
  color: #4E8848;
  font-weight: 700;
  font-size: 3.4rem;
  line-height: 1.25; }
  @media (min-width: 992px) {
    .braney-title-3 > * {
      font-size: 3.6rem; } }
  @media (min-width: 1200px) {
    .braney-title-3 > * {
      font-size: 4rem; } }

.svd-1 > .container {
  padding-top: 60px; }

.svd-2 {
  margin-top: 80px; }
  @media (min-width: 992px) {
    .svd-2 {
      margin-top: 12rem; } }
  .svd-2 .braney-image {
    position: relative;
    width: 100%;
    height: 0;
    padding-top: 50%; }
    .svd-2 .braney-image > * {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; }

.svd-3 {
  margin-top: 50px; }
  @media (min-width: 992px) {
    .svd-3 {
      margin-top: 70px; } }

.svd-4 {
  margin-top: 80px; }
  @media (min-width: 992px) {
    .svd-4 {
      margin-top: 12rem; } }
  .svd-4 .procedure-box {
    margin-top: 5rem; }

.svd-5 {
  margin-top: 80px;
  margin-bottom: 5rem; }
  @media (min-width: 992px) {
    .svd-5 {
      margin-top: 12rem; } }
  .svd-5 .project-hl {
    margin-top: 8rem; }

.fb_dialog iframe {
  transition: .1s all;
  transform: translateY(-6rem); }
