.about-1 {
    width: 100%;
    &__title {
        width: 100%;
        display: flex;
        justify-content: center;
       
        & > * {
            @include title(s74);
            color: map-get($color, black);
            @include bold;
        }
    }
    &__content{
        width: 100%;
        // margin-top: 70px;
        margin-top: 2vh;
        @include rpmin(xxl){
            margin-top: 2vh;
        }
        // @include rp(ps){
        //     // margin-top: 50px;
        //     margin-top: 3.5vh;
        // }
        // padding: 0px 30px;
    }
    &__slide{
        .swiper-container{
            padding: 8px;
        }
        
    }
    &__box{
        position: relative;
        overflow: hidden;
        // background-color: yellow;
        box-shadow: 0 3px 6px rgba(0,0,0,.16), 0 3px 6px rgba(0,0,0,.16);
        &:hover{
            .border{
                span{
                    &:nth-child(1){
                        top: 0;
                        left: 0%;
                    }
                    &:nth-child(2){
                        top: 0%;
                        right: 0%;
                    }
                    &:nth-child(3){
                        bottom: 0;
                        right: 0%;
                    }
                    &:nth-child(4){
                        bottom: 0%;
                        left: 0%;
                    }
                }
            }
            
        }
        .border{
            
            span{
                display: block;
                background: map-get($color , main );
                transition: all 0.4s ease;
                
                &:nth-child(1),
                &:nth-child(3){
                    position: absolute;
                    height: 2px;
                    width: 100%;
                    
                }
                &:nth-child(2),
                &:nth-child(4){
                    position: absolute;
                    height: 100%;
                    width: 2px;
                }
                &:nth-child(1){
                    top: 0;
                    left: -100%;
                    transition-delay: 0.1s;
                }
                &:nth-child(2){
                    top: -100%;
                    right: 0%;
                    transition-delay: 0.2s;
                }
                &:nth-child(3){
                    bottom: 0;
                    right: -100%;
                    transition-delay: 0.3s;
                }
                &:nth-child(4){
                    bottom: -100%;
                    left: 0%;
                    transition-delay: 0.4s;
                }
                
            }
        }
        
        
    }
}
