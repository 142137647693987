.brand1{
    &-banner{
        &__img{
            position: relative;
            // padding-top: map-get($ratio, 16_9);
            padding-top: 46.8%;

            img{
                position: absolute;
                top: 0;
                left: 0;
            }
        }
    }

    padding-top: 24px;
    @include rpmin(md){
        padding-top: 40px;
    }
    @include rpmin(lg){
        padding-top: 80px;
    }

    &-container{
        position: relative;

        .brand1-pagination{
            .btn{
                width: 60px;
                height: 60px;
                background-color: map-get($color, main);

                @include rp(tl){
                    width: 48px;
                    height: 48px;
                }

                display: flex;
                justify-content: center;
                align-items: center;

                z-index: 1;
            }

            .btn-next{
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
            }

            .btn-prev{
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%) rotate(180deg);
            }

        }
    }

    &-wrapper{
        width: 100%;
    }

    &-img{
        margin-top: 24px;
        @include rpmin(md){
            margin-top: 40px;
        }
        @include rpmin(lg){
            margin-top: 80px;
        }
        &>*{
            user-select: none;
            height: auto;
            width: 100%;
            max-width: 100%;
        }
    }
}


.brand1-img{
    position: relative;
    &>img{
        opacity: 0;
    }

    .part{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        
        .section:nth-of-type(1){
            position: absolute;
            left: 0;
            top: 1px;
            width: 100%;
            height: 50%;

            img{
                object-fit: cover;
                object-position: 0 4px;
            }
        }
        .section:nth-of-type(2){
            position: absolute;
            left: 0;
            top: 50%;
            width: 100%;
            height: 50%;
            
            img{
                object-fit: cover;
                object-position: bottom;
            }
        }
    }
}