$family: (
	primary: 'Barlow',
	// second: "Be+Vietnam"
);

$color: (
	main: #4E8848,
	main-second:#91C73E,

	white: #ffff,
	black: #000,
	
	black-1:#363636,
	black-2:#292929,
	black-3:#1C1C1C,

	grey: #707070,
);

$shadow:(
	s1: 0 3px 6px rgba(0,0,0,.16),
);

$fpsize:(
	w: 1279px,
	h: 539px
);
$fpsize:(
	w: 1279px,
	h: 539px
);

$ratio: (
	16_9: 56.25%,
    4_3: 75%,
    3_2: 66.66%,
    8_5: 62.5%,
	1_1: 100%,
);

// dùng rem (1 rem = 10px)
// Cách sử dụng: @include size(s12); --> sử dụng biến s12
// ----> tương đương với font-size: 1.2rem;
// 1920 -> 10px -> 1 rem = 10px

// dựa trên gridBs -> responsizes mobile first
$size: (
	s32: (
		default: 2.6rem,
		md: 2.8rem,
		lg: 3rem,
		xl: 3.2rem
	),
	s24: (
		default: 2rem,
		lg: 2.2rem,
		xl: 2.4rem
	),
	s20: (
		default: 1.8rem,
		lg: 1.9rem,
		xl: 2rem
	),
	s18: (
		default: 1.6rem,
		lg: 1.7rem,
		xl: 1.8rem
	),
	s16: (
		default: 15px,
		lg: 1.5rem,
		xl: 1.6rem
	),
);

$title: (
	s74: (
		// default: 7.4rem,
		// pl: 7.2rem,
		// pm: 5.4rem,
		// ps: 7rem,
		// tx: 6.4rem,
		// ml: 6rem
		default: 4.4rem,
		md: 4.8rem,
		lg: 5rem,
		xl: 5.2rem,
		xxl: 6rem,
		yl: 7.4rem,
	),
	s40: (
		default: 3.4rem,
		lg: 3.6rem,
		xl: 4rem
	),
	
);

$lineHeight:(
	
);

// Cách sử dụng: map-get($media, ps);
// Hoặc là: @include rp(ps);
$media: (
	// personal computer
	px: 1920px,
	pl: 1680px,
	pm: 1440px,
	ps: 1336px,
	// tablet
	tx: 1280px,
	tl: 1024px,
	tm: 900px,
	ts: 768px,
	// mobile
	mx: 640px,
	ml: 540px,
	mm: 480px,
	ms: 384px,
	// mobile small
	sx: 360px,
	sl: 320px
);

// Cách sử dụng: Không tùy chỉnh trong đây (phần này mặc định)
// kích thước 1183px (grid layout)
$container-max-widths: (
	// fullpage
	default: 1640px,
	// default: 1640px,
	pl:90%,
	// 1336px
	// default: 1183px,
	// tx: 860px,
	// tm: 94%,
);
                        
// kích thước 1256px (grid layout)
// $container-max-widths: (
// 	default: 1256px,
// 	ps: 960px,
// 	tl: 96%,
// 	// pm: 1200px,
// 	// tx: 960px,
// 	// tl: 96%,
// 	// về talet thì sẽ width = 96%;
// );

// Cách sử dụng: @include block(banner, margin, 20px); --> sử dụng biến s12
// tham số 1: tên key trong block
// tham số 2: thuộc tính cần gán
// tham số 3: (có thể bỏ qua) còn nếu có thì sẽ là
//				giá trị trái phải như của margin or padding
// ----> tương đương với margin: 1080px 20px;

$block: (
	pagiSwiper: (
		default: 43px,
	),

	swiperBtn: (
		default: 42px,
		ts: 36px,
		ml: 32px,
	),
);

// Cách sử dụng: @include space(s134, margin, 20px); --> sử dụng biến s12
// tham số 1: tên key trong block
// tham số 2: thuộc tính cần gán
// tham số 3: (có thể bỏ qua) còn nếu có thì sẽ là
//				giá trị trái phải như của margin or padding
// ----> tương đương với margin: 1080px 20px;
// y như block
// chú ý khi khoảng cách giống nhau nhưng media khác nhau thì có thể thêm đằng sau là -2 -3 (loại 2 loại 3)

$space: (
	
	// // Khoảng cách ngoài của section
	// s80: (
	// 	default: 80px,
	// 	pm: 64px,
	// 	tl: 56px,
	// 	mx: 40px,
	// 	mm: 32px,
	// ),
	s100: (
		default: 100px,
		tx: 80px,
		mx: 64px
	)
);



// use bootstrap
$flexCol: (
	1: 8.33333%,
	2: 16.66667%,
	3: 25%,
	4: 33.33333%,
	5: 41.66667%,
	6: 50%,
	7: 58.33333%,
	8: 66.66667%,
	9: 75%,
	10: 83.33333%,
	11: 91.66667%,
	12: 100%,
);
$flexOffset: (
	0: 0%,
	1: 8.33333%,
	2: 16.66667%,
	3: 25%,
	4: 33.33333%,
	5: 41.66667%,
	6: 50%,
	7: 58.33333%,
	8: 66.66667%,
	9: 75%,
	10: 83.33333%,
	11: 91.66667%
);
$flexRow: (
	1: 100%,
	2: 91.66667%,
	3: 83.33333%,
	4: 75%,
	5: 66.66667%,
	6: 58.33333%,
	7: 50%,
	8: 41.66667%,
	9: 33.33333%,
	10: 25%,
	11: 16.66667%,
	12: 8.33333%,
);
$flexGutter: (
	0: 0,
	1: 0.25rem,
	2: 0.5rem,
	3: 1rem,
	4: 1.5rem,
	5: 3rem,
	6: 5rem
);

$containerWidth: (
	sm: 540px,
	md: 720px,
	lg: 960px,
	xl: 1140px,
	xxl: 1320px,
	yl: 84%,
);

$mdWidth: (
	sm: 576px,
	md: 768px,
	lg: 992px,
	xl: 1200px,
	xxl: 1400px,
	yl: 1680px,
);

$mdHeight: (
	yl: 960px,
	xxl: 900px,
	xl: 768px,
	lg: 640px,
	sm: 480px
);

///////////////////// LAYOUT ////////////////////////////

///////////////////// PAGE ////////////////////////////
@import "../_variables/index";
