.contact2{
    .contact{
        .container{
            align-items: stretch;
        }
        &-left{
            width: 100% !important;
            position: relative;
            height: 540px;

            @include rpmin(lg){
                height: auto;
                width: 50% !important;
            }
            @include rpmin(xl){
                width: 58.33333% !important;
            }
            &>*{
                width: 100%;
                height: 100%;
            }
        }
        &-right{
            width: 100% !important;

            @include rpmin(lg){
                width: 50% !important;
            }
            @include rpmin(xl){
                width: 41.66667% !important;
            }
            &--wrapper{
                padding-top: 24px;
                text-align: left;

                @include rpmin(lg){
                    text-align: initial;
                    padding-bottom: 12px;
                    padding-left: 32px;
                    padding-top: 12px;
                }
                @include rpmin(xl){

                    padding-top: 36px;
                    padding-bottom: 36px;
                    padding-left: 56px;
                }
            }
        }
    
        &-info{
            border-top: 1px solid map-get($color, main);
            padding-top: 36px;
            margin-top: 12px;

            &__item{
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                align-items: stretch;
    
                &:not(:first-child){
                    margin-top: 24px;
                }
    
                &-icon{
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    width: 50px;
                    flex: 0 0 50px;
    
                    &>*{
                        line-height: 2.6;
                        max-width: 100%;
                    }
                }
    
                &-desc{
                    &>*{
                        @include size(s32);
                        @include regular;
                        white-space: pre-wrap;
                        line-height: 1.4;
                    }
    
                    &.desc-address{
                        letter-spacing: 1.2px;
                    }
                }
            }        
        }
    }
}

