.box-linkSocial {
    // width: 100%;
    &__content{
        display: flex;
        justify-content: center;

        &>*:not(:first-child){
            margin-left: 12px;
        }
    }
    &__facebook,&__be,&__ins{
        padding: 4px 8px;
        height: 28px;
        min-width: 28px;
        @include rpmin(lg){
            height: 42px;
            min-width: 42px;
        }
        transition: .3s ease-in-out;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover{
            background-color: map-get($color , main );
            // opacity: 0;
            // animation: hoverBox2 0.4s linear  0.2s;
            path{
                fill: map-get($color , white );
            }
        }
    }
}