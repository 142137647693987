.card{
    padding-bottom: 6px;
    & + .card{
        padding-top: 10px;
        @include rpmin(lg){
            padding-top: 20px;
        }
        @include rpmin(xl){
            padding-top: 40px;
        }
    }
    &-box{
        height: 100%;
        box-shadow: 0 3px 6px rgba(0,0,0,.16);
        position: relative;
        display: flex;
        flex-direction: column;
    }
    &__img{
        position: relative;
        padding-top: 67.77%;
        &>*{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }

    &-content{
        flex:1;
        padding: 16px 12px 12px 12px;
        @include rpmin(xl){
            padding: 40px 32px 24px 32px;
        }

        display: flex;
        flex-direction: column;

        &__title{
            padding-top: 12px;
            @include rpmin(xl){
                padding-top: 20px;
            }
            border-top: 1px solid map-get($color, main);
            flex: 1;
            @include p-line-clamp(2);
            
            &>*{
                color: map-get($color, main);
                @include bold;
                line-height: 1.4;
                @include size(s32);
                
            }
        }
        &__desc{
            margin-top: 18px;
            @include rpmin(xl){
                margin-top: 24px;
            }
            @include rpmin(xl){
                margin-top: 36px;
            }
            @include rpmin(xxl){
                margin-top: 42px;
            }
            @include rpmin(yl){
                margin-top: 56px;
            }

            &>*{
                color: map-get($color, black-3);
                @include regular;
                @include size(s24);
                line-height: 1.32;

                @include p-line-clamp(2);
            }
        }

        &-bottom{
            margin-top: 46px;
        }
    }

}