

#brn-index-contact{
    background-color: white;
    height: 100%;
    width: 100%;
    @include fp{
        height:auto;
    }
    .brn-index-contact{
        height: 100vh;
        width: 100%;
        @include fp{
            height:auto;
        }
        &__wrapper{
            height: 100%;
            @include fp{
                height:auto;
            }
            display: flex;
            align-items: center;
            &--contact{
                width: 100%;
                
                &__title{
                    @extend .title-format;
                    @extend .title__name;
                    
                    // text-align: center;
                    // margin-bottom: 80px;
                    // position: relative;
                    // *{
                    //     @include bold;
                    //     @include title(s74);
                    // }
                    // text{
                    //     fill: map-get($color, black );
                    // }
                }
                &__infor{
                    position: relative;
                    padding-top: 2vh;
                    margin-top: 2vh;
                    &::after{
                        position: absolute;
                        top: 0px;
                        left: 50%;
                        transform: translateX(-50%);
                        width: 100%;
                        height: 1px;
                        content: "";
                        background-color: map-get($color,main );
                    }
                }
                &__logo{
                    margin-top: 6vh;
                    // margin-top: 80px;
                    display: flex;
                    justify-content: center;

                    svg{
                        max-height: 20vh;
                    }
                }
            }
           
        }
    }
}