#fp-nav{
    ul li{
        margin: 20px 7px;
        &:hover{
            a{
                span{
                    margin: unset;
                }
                &.active{
                    margin: unset;
                    span{
                        margin: unset;
                    }
                }
            }
            
        }
        a{
            span{
                margin: unset;
                width: 10px;
                height: 10px;
                transform: translate(-50%,-50%);
                transition: .2s ease-in-out;
            }
            &.active{
                position: relative;
                margin: unset;
                span{
                    margin: unset;
                    width: 14px;
                    height: 14px;
                    background-color: map-get($color, main-second );
                }
                &::after{
                    content: "";
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%,-50%);
                    background-color: map-get($color, main );
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                }
            }
        }
    }
}