.service-1,
.service-3,
.service-5 {
    &__title{
        width: 100%;
        .title{
            width: 100%;
            &__content{
                justify-content: space-between;
            }
            &__desc{
                // flex: initial;
                &>*{
                    font-size: 3rem;
                    @include rp(tx){
                        font-size: 2.8rem;
                    }
                    @include rp(mx){
                        font-size: 2.7rem;
                    }
                }
            }
          
        }
    }
    &__content{
        padding-top: 24px;
        margin-top: 24px;
        
        @include rpmin(lg){
            padding-top: 2vh;
            margin-top: 2vh;
        }
        @include rpmin(yl){
            padding-top: 70px;
            margin-top: 50px;

            @include hmin(xxl){
                padding-top: 2vh;
                margin-top: 2vh;
            }
        }

        // @include hmin(yl){
        //     padding-top: 70px;
        //     margin-top: 50px;
        // }

        // @include hmin(xxl){
        //     padding-top: 2vh;
        //     margin-top: 2vh;
        // }

        width: 100%;
        position: relative;
        &::after{
            position: absolute;
            content: "";
            top: 0;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: map-get($color, main );
        }
        .brand-service{
            &__img{
                overflow: hidden;
                padding-right: 0px;
                @include rpmin(lg){
                    padding-right: 30px;
                }
                @include rpmin(xl){
                    padding-right: 60px;
                }

                display: flex;
                justify-content: center;
                align-items: center;

                svg{
                    max-height: 54vh;
                    
                    height: auto;
                    width: 100%;
                    @include rpmin(md){
                        height: auto;
                        width: 80%;
                    }
                    
                    @include rpmin(lg){
                    }
                }
               .maybay{
                   transform-origin: center;
                   transform-box: fill-box;
               }
            }
            
            &__infor{
                order: 1;
                
                @include rpmin(lg){
                    order: 2;
                }
            }
        }
    }
}

