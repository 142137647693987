.title{
    width: 100%;
    &__content{
        display: flex;
        justify-content: space-between;
        flex-direction: column;

        text-align: center;
        @include rpmin(lg){
            flex-direction: row;
            text-align: initial;
        }
    }

    &__name{
        display: flex;
        align-items: center;
        max-width: 100%;
        width: 100%;
        
        @include rpmin(lg){
            width: auto;
            max-width: 50%;
            text-align: initial;
        }
        &>*{
            width: 100%;
            text-align: center;
            @include rpmin(lg){
                text-align: initial;
            }

        }
        // &>*{
        //     @include title(s74);
        //     color: map-get($color , black);
        //     line-height: 1.2;
        //     @include bold;
        //     @include rp(tx){
        //         font-size: 6rem;
        //     }
        //     @include rp(tm){
        //         font-size: 5rem;
        //     }
        //     @include rp(mm){
        //         font-size: 4.5rem;
        //     }
        // }
    }
    &__desc{
        flex: 1;
        padding-left: 0;
        padding-top: 12px;
        width: 100%;
        @include rpmin(lg){
            padding-top: 0px;
            padding-left: 4.2rem;
            flex: 1;
            width: auto;
        }
        display: flex;
        // justify-content: center;
        align-items: center;
        justify-content: flex-end;

        @include rp(mx){
            margin-left: 0px;
            margin-top: 8px;
        }
        &>*{
            @include size(s24);
            color: map-get($color , black-3);
            line-height: 1.2;
            @include regular;
            @include rp(tx){
                font-size: 2rem;
               
            }
            
        }
    }
    
    &-format{
        &>*{
            @include title(s74);
            color: map-get($color , black);
            line-height: 1.2;
            @include bold;
    
            // @include rp(tx){
            //     font-size: 6rem;
            // }
            // @include rp(tm){
            //     font-size: 5rem;
            // }
            // @include rp(mm){
            //     font-size: 4.5rem;
            // }
          
        }
    }
}
