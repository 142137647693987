@for $i from 1 through 10 {
    .p-line-clamp-#{$i} {
        display: -webkit-box;
        -webkit-line-clamp: #{$i};
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
}

.m-auto{
    margin-left: auto;
    margin-right: auto;
}
.ml-auto{
    margin-left: auto;
}
.mr-auto{
    margin-right: auto;
}

.jc-center{
    justify-content: center
}

.thin{
    @include thin(true);
}
.extra-light{
    @include extra-light(true);
}
.light{
    @include light(true);
}
.regular{
    @include regular(true);
}
.normal{
    @include normal(true);
}
.medium{
    @include medium(true);
}
.semi-bold{
    @include semi-bold(true);
}
.bold{
    @include bold(true);
}
.extra-bold{
    @include extra-bold(true);
}
.ultra-bold{
    @include ultra-bold(true);
}

.content-format{
    p, span{
        color: #292929;
        @include size(s18);
        @include regular;
        line-height: 1.25;
    }

    .alt{
        @include bold;
        text-align: center;

        margin-left: auto;
        margin-right: auto;

        display: block;

        width: calc(66.66667% - 40px);
        @include rp(tx){
            width: calc(83.33333% - 40px);
        }
        @include rp(mx){
            width: calc(100% - 40px);
        }
    }

    img{
        margin-left: auto;
        margin-right: auto;

        display: block;

        height: auto;
        object-fit: contain;
        
        width: 66.66667%;
        @include rp(tx){
            width: 83.33333%;
        }
        @include rp(mx){
            width: 100%;
        }
    }

    img + .alt{
        margin-top: 1.8rem;
    }
    
    p + p{
        margin-top: 1.8rem;        
    }
    p.alt + p{
        margin-top: 4rem;
    }
    p + img{
        margin-top: 4rem;
    }
}



.btn-see-more{
    display: inline-flex;
    flex-direction: row;
    align-items: stretch;

    @include size(s24);
    color: map-get($color, main);
    
    span{
        order: 3;
        @include regular;
        padding-left: 16px;

        @include rp(tl){
            padding-left: 8px;
        }
    }

    &:hover{
        span{
            @include bold;
        }
    }

    &:hover{
        .line{
            width: 20px;
            @include rp(tl){
                width: 18px;
            }
            &::before{
                width: 100%;
                transform-origin: right;
                transform: translateY(-50%) rotate(25deg);
                border-top: 2px solid map-get($color, main);
            }
            &::after{
                width: 100%;
                transform-origin: right;
                transform: translateY(-50%) rotate(-25deg);
                border-top: 2px solid map-get($color, main);
            }
        }
    }
    
    .line{
        padding: 0;
        margin: 0;
        position: relative;
        display: block;
        width: 58px;

        @include rp(tl){
            width: 48px;
        }

        transition: .7s all ease-in-out;
        
        &::before{
            transition: .7s all ease-in-out;
            position: absolute;
            content: "";
            top: 50%;
            left: 0%;
            width: 50%;
            border-top: 1px solid map-get($color, main);
            transform: translateY(-50%);
        }
        &::after{                   
            transition: .7s all ease-in-out;
            position: absolute;
            content: "";
            top: 50%;
            right: 0;
            width: 50%;
            height: 0px;
            border-top: 1px solid map-get($color, main);
            transform: translateY(-50%);
        }                    
    }

}

.time{
    position: absolute;
    bottom: 0;
    right: 0;

    display: flex;
    flex-wrap: wrap;

    width: 72px;
    background-color: map-get($color, main);
    
    &>*{
        height: 30px;
        color: white;
        @include regular;
        @include size(s18);

        display: flex;
        justify-content: center;
        align-items: center;
    }

    .day{
        order: 1;
        flex: 0 0 50%;
        width: 50%;
        max-width: 50%;
    }
    .month{
        order: 2;
        flex: 0 0 50%;
        width: 50%;
        max-width: 50%;

        position: relative;
        &::before{
            content: "";
            position: absolute;
            top: calc(50% + 2px);
            left: 0;
            transform: translate(-50%, -50%);
            width: 6px;
            height: 0;
            border-top: 2px solid white;
        }
    }
    .year{
        order: 3;
        flex: 0 0 100%;
        width: 100%;
        max-width: 100%;

        position: relative;
        &::before{
            content: "";
            position: absolute;
            top: 0;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 80%;
            height: 0;
            border-top: 2px solid white;

        }
    }
}


.service-1{
    margin-top: 0;
    @include fp{
        margin-top: 24px;
    }
}

.service-2,
.service-3,
.service-4,
.service-5,
.contact2,
.brn-index-8--padding,
.about-2,
.about-3,
.brn-index-services,
.brn-index-project,
.brn-index-process,
.brn-index-client,
.brn-index-contact {
    margin-top: 0;

    @include fp{
        margin-top: 64px;
    }

    @include rpmin(md){
        margin-top: 64px;
    }
}