.news-detail{
    padding-top: 20rem;

    &__title{
        margin-top: 5rem;
        &>*{
            color: map-get($color, main);
            @include bold;
            @include title(s40);
            line-height: 1.25;
        }
    }

    &__time{
        margin-top: 6rem;

        color: map-get($color, main);
        @include regular;
        @include size(s16);
        line-height: 1.21;
        letter-spacing: 0.5px;
    }

    &-social{
        margin-top: 18px;

        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        &__item{
            cursor: pointer;

            max-height: 40px;
            padding: 8px 12px;
            
            display: inline-flex;
            justify-content: center;
            align-items: center;
            color: white;
            
            @include size(s18);
            background-color: transparent;

            position: relative;
            overflow: hidden;
            &::before{
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: #0d8af0;
                z-index: -1;
            }
            &::after{
                content: "";
                position: absolute;
                top: 50%;
                transform: translate(0%, -50%);
                left: 0;
                width: 100%;
                height: 0%;
                background-color: map-get($color, main);
                z-index: -1;
                transition: .16s all ease-in-out;
            }

            
            .iconsSocial{
                width: 24px;
            }

            span{
                @include bold;
                text-transform: uppercase;
                margin-left: 8px;
                letter-spacing: 0.5;
                white-space: nowrap;
            }

            transition: .16s all ease-in-out;

            &:hover{
                box-shadow: 0 3px 6px rgba(0,0,0,.16);
                &::after{
                    width: 100%;
                    height: 100%;
                    transform-origin: center;
                }
            }
        }

        &__item + &__item{
            margin-left: 16px;
        }
    }

    &-content{
        margin-top: 6rem;
    }
}

.breadcrumbs{
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow-x: auto;

    width: 100%;

    &__item{
        &:not(:first-child){
            margin-left: 26px;
            padding-left: 26px;
            position: relative;

            &::after{
                content: "";
                position: absolute;

                top: calc(50% + 2px);
                left: 0;
                transform: translate(-50%, -50%);

                width: 0; 
                height: 0; 
                border-top: 7px solid transparent;
                border-bottom: 7px solid transparent; 
                
                border-left:13px solid #9E9E9E; 
            }
        }
        &>*{
            @include size(s24);
            line-height: 1.32;
            @include medium;
            color: #9E9E9E;
        }

        a{
            display: block;
            cursor: pointer;

        }

        &:last-child{
            a{
                cursor: auto;
            }
        }
    }
}