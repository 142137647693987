@keyframes tranlateY100_up {
    0%{
        transform: translateY(0);
        opacity: 1;
        display: block;
    }
    100%{
        transform: translateY(-100%);
        opacity: 0;
        // display: none;
    }
}

#loading-container {
    width: 100%;
    height: 100vh;
    position: fixed;
    z-index: 111111;
    background-color: gray;
    // display: none;
    // opacity: 0;
    // &.show {
    //     display: block;
    // }

    &.hiddenLoading{
        animation: tranlateY100_up .9s ease-in-out forwards;
    }

    #brn-loading {
        .brn-loading {
            &__wrapper {
                overflow: hidden;
                width: 100%;
                height: 100vh;
                background: linear-gradient(45deg, #00423E 0%, #0A4B3E 14%, #26653E 36.4%, #55903E 66%, #91C73E 100%);
                position: relative;

                &--bg{
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    svg{
                        width: 100%;
                        height: 100%;
                    }
                }

                &--logo {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    z-index: 2;

                    svg {
                        max-width: 60vw;
                        max-height: 8vw;
                        path {
                            fill: transparent;
                            stroke: 10;
                            stroke: #fff;
                            stroke-dasharray: 650;
                            stroke-dashoffset: 650;
                            animation-delay: 1s;
                            animation: animateLogo 3s linear infinite alternate;
                            // animation-delay: 1s;
                        }

                        text{
                            user-select: none;
                            animation-delay: 1s;
                            animation: opacityTextLogo 3s linear infinite alternate;
                        }
                    }
                }
                
                &--effects{
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    z-index: 1;

                    svg{
                        width: 100%;
                        height: 100%;
                    }

                    .objs{
                        &-01{
                            path{
                                transform-origin: center;
                                transform-box: fill-box;
                                animation: animatedMutiply 5s infinite linear;
                                &:nth-of-type(2){
                                    animation-delay: -0.4s;
                                }
                                &:nth-of-type(3){
                                    animation-delay: -0.8s;
                                }
                            }
                        }

                        &-02{
                            transform-box: fill-box;
                            transform-origin: center;
                            
                            animation: rotate90 5s linear infinite;
                            &__small{
                                position: relative;
                                animation: fillCircle 5s linear infinite;
                            }
                        }

                        &-03{
                            path{
                                transform-box: fill-box;
                                transform-origin: center;
                                animation: animatedArrowRight 2s infinite;
                                &:nth-of-type(2){
                                    animation-delay: -0.3s;
                                }
                                &:nth-of-type(3){
                                    animation-delay: -0.6s;
                                }
                            }
                        }
                        &-04{
                            path{
                                transform-box: fill-box;
                                transform-origin: center;
                                animation: animatedArrowUp 2s infinite;
                                &:nth-of-type(2){
                                    animation-delay: -0.3s;
                                }
                                &:nth-of-type(3){
                                    animation-delay: -0.6s;
                                }
                            }
                        }

                        &-05{
                            path{
                                fill: powderblue;
                                transform: scale(1);
                                transform-origin: center;
                                transition: .5;
                                // filter: drop-shadow(0px 0px 2px #fdfdfd);

                                transform-box: fill-box;
                                transform-origin: center;
                                animation-delay: var(--i);
                                animation: STAR 4s infinite linear alternate;
                            }
                        }
                    }

                    .circleAnimate{
                        transform-box: fill-box;
                        transform-origin: center;
                        animation: rotate360 6s infinite;
                    }

                    // .effects{
                    //     &-arrow-01{
                    //         position: absolute;
                    //         left: 10%;
                    //         top: 4%;  
                                                 
                    //         display: flex;
                    //         flex-direction: row;
                    //         &__item{
                    //             animation: animatedArrow 2s infinite;
                    //             &:nth-of-type(2){
                    //                 animation-delay: -0.2s;
                    //                 svg{
                    //                     path{
                    //                         fill:#2E6C3E;
                    //                     }
                    //                 }
                    //             }
                    //             &:nth-of-type(3){
                    //                 animation-delay: -0.4s;
                    //                 svg{
                    //                     path{
                    //                         fill:#91C73E;
                    //                     }
                    //                 }
                    //             }
                    //         }
                    //     }
                    // }
                }
            }
        }
    }
}


// @keyframes tranlateY100_up {
//     0%{
//         transform: translateY(0);
//         opacity: 1;
//         display: block;
//     }
//     100%{
//         transform: translateY(-100%);
//         opacity: 0;
//         // display: none;
//     }
// }

// #loading-container {
//     width: 100%;
//     height: 100vh;
//     position: fixed;
//     z-index: 111111;
//     background-color: gray;
//     // display: none;
//     // opacity: 0;
//     // &.show {
//     //     display: block;
//     // }

//     &.hiddenLoading{
//         animation: tranlateY100_up .9s ease-in-out forwards;
//     }
//     .loading {
//         overflow: hidden;
//         width: 100%;
//         height: 100%;
//         &-logo {
//             height: 100%;
//             width: 100%;
//             position: absolute;
//             top: 0%;
//             left: 0%;
//             display: flex;
//             justify-content: center;
//             align-items: center;
//             svg {
//                 height: 50vh;
//             }
//         }
//     }
//     #brn-loading {
//         .brn-loading {
//             &__wrapper {
//                 overflow: hidden;
//                 width: 100%;
//                 height: 100vh;
//                 background: linear-gradient(
//                     45deg,
//                     rgba(0, 67, 63, 1) 25%,
//                     rgba(146, 214, 101, 1) 87%
//                 );
//                 position: relative;
//                 // &::after{
//                 //     position: absolute;
//                 //     top: 0;
//                 //     left: 0;
//                 //     bottom: 0;
//                 //     right: 0;
//                 //     content: "";
//                 //     background:linear-gradient(45deg, rgba(0,67,63,.4) 25%, rgba(146,214,101,.4) 87%);
//                 //     // opacity: .7;
//                 //     // z-index: ;
//                 // }
//                 &--logo {
//                     position: absolute;
//                     top: 20%;
//                     left: 50%;
//                     transform: translateX(-50%);
//                     z-index: 2;
//                     svg {
//                         width: 33vw;
//                         height: 8vw;
//                         path {
//                             fill: transparent;
//                             stroke: 10;
//                             stroke: #fff;
//                             stroke-dasharray: 650;
//                             stroke-dashoffset: 650;
//                             animation: animate_1 1 3s linear forwards;
//                             // animation-delay: 1s;
//                             @keyframes animate_1 {
//                                 0% {
//                                     stroke-dashoffset: 0;
//                                 }
//                                 40% {
//                                     stroke-dashoffset: 650;
//                                 }
//                                 70% {
//                                     stroke-dashoffset: 1300;
//                                     fill: transparent;
//                                 }
//                                 100% {
//                                     stroke-dashoffset: 1300;
//                                     fill: #fff;
//                                 }
//                             }
//                         }
//                     }
//                 }
//                 &--wave {
//                     height: 100%;
//                     width: 100%;
    
//                     &_big {
//                         // po
//                         // svg{
//                         //     height: 100%;
//                         //     width: 100%;
//                         // }
//                         position: absolute;
//                         z-index: 3;
//                         top: 50%;
//                         left: 50%;
//                         transform: translate(-50%,-50%);
//                         animation: flow_3 infinite 9s ease-in-out alternate;
//                         transform-origin: bottom ;
//                         // trn
//                             // animation-delay: 1s;
//                         @keyframes flow_3 {
//                             0%{
//                                 transform:  translate(-70%,-50%) scaleY(1);
//                             }
                            
//                             100%{
//                                 transform: translate(30%,50%) scaleY(1.2) ;
//                             }
//                         }
//                         svg{
//                             position: absolute;
//                             top: 50%;
//                             clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);
//                             left: 50%;
//                             transform: translate(-50%,-30%);
//                             animation: visibleOverlay_1 1 4s forwards;
//                         }
                        
//                         @keyframes visibleOverlay_1 {
//                             0% {
//                                 // opacity: 0;
//                                 clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);
//                                 width: auto;
//                                 height: auto;
//                             }
//                             100% {
//                                 width: auto;
//                                 height: auto;
//                                 // opacity: 1;
//                                 clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
//                             }
                            
//                         }
//                     }
//                     &_bottom {
//                         position: absolute;
//                         z-index: 2;
//                         bottom: -10%;
//                         left: 50%;
//                         transform: translateX(-50%) scale(1.1);
//                         // width: 100%;
//                         max-width: 100%;
//                         min-width: 100%;
//                         .wave{
//                             svg{
//                                 width: 100%;
//                                 height: 100%;
//                                 // animation: flow_1 infinite 9s ease-in-out alternate;
//                                 // transform-origin: bottom ;
//                                 // // trn
//                                 //     // animation-delay: 1s;
//                                 // @keyframes flow_1 {
//                                 //     0%{
//                                 //         transform: scale(1.1) translate(-35px, 0%);
//                                 //     }
                                    
//                                 //     100%{
//                                 //         transform: scale(1.3) translate(85px, 0%);
//                                 //     }
//                                 // }
//                                 path{
//                                     fill: transparent;
//                                     stroke: white;
//                                     stroke-dasharray: 6100;
//                                     stroke-dashoffset: 6100;
//                                     animation: animate_2 1 2.5s linear forwards;
//                                     // animation-delay: 1s;
//                                     @keyframes animate_2 {
//                                         0% {
//                                             stroke-dashoffset: -6100;
//                                         }
//                                         80%{
//                                             stroke-dashoffset: -12200;
//                                             fill: transparent;
//                                         }
//                                         100% {
//                                             stroke-dashoffset: -6100;
//                                             fill: #fff;
//                                         }
//                                         // 100% {
//                                         // 	stroke-dashoffset: 6100;
//                                         // 	fill: #fff;
//                                         // }
//                                     }
//                                 }
//                             }
//                         }
//                     }
//                     &_back{
//                         position: absolute;
//                         z-index: 1;
//                         bottom: 0;
//                         left: 50%;
//                         transform: translateX(-50%) scale(1.1);
//                         // width: 100%;
//                         max-width: 100%;
//                         min-width: 100%;
//                         .wave{
//                             svg{
//                                 fill: url(#grad1);
//                                 width: 100%;
//                                 height: 100%;
//                                 animation: Opacity_1 1 2s ease-in-out alternate;
//                                 transform-origin: bottom ;
//                                 transform: scaleX(-1);
//                                     // animation-delay: 1s;
//                                 @keyframes Opacity_1 {
//                                     0%{
//                                         opacity: 0;
//                                     }
                                    
//                                     100%{
//                                         opacity: 1;
//                                     }
//                                 }
//                                 path{
//                                     stroke: white;
//                                     stroke-dasharray: 6100;
//                                     stroke-dashoffset: 6100;
//                                     fill: url(#grad1);
                                    
//                                 }
//                             }
//                         }
//                     }
//                 }
//             }
//         }
//     }
    
// }