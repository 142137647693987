#brn-index-process{
    background-color: white;
    height: 100%;
    width: 100%;
    @include fp{
        height:auto;
    }
    .brn-index-process{
        height: 100vh;
        width: 100%;
        @include fp{
            height:auto;
        }
        &__wrapper{
            height: 100%;
            @include fp{
                height:auto;
            }
            display: flex;
            align-items: center;
            &--process{
                z-index: 2;
                position: relative;
                width: 100%;
                @include rpmin(lg){
                    width: 50%;
                }

                &__title{
                    // @include bold;
                    // @include title(s74);
                    
                    @extend .title-format;
                    @extend .title__name;
                    width: 100% !important;
                    max-width: 100% !important;
                    &>*{
                        width: 100% !important;
                        max-width: 100% !important;
                    }
                }
                &__list{
                    margin-top: 30px;
                    width: 100%;
                    
                    // @include rpmin(lg){
                    //     position: relative;
                    //     padding-bottom: 56%;
                    //     overflow-y: auto;
                    // }
                    
                    // &::-webkit-scrollbar {
                    //     width: 5px;
                    // }
                    
                    // /* Track */
                    // &::-webkit-scrollbar-track {
                    //     background: #f1f1f1;
                    // }
                    
                    // /* Handle */
                    // &::-webkit-scrollbar-thumb {
                    //     background: map-get($color, main-second );
                    // }
                    
                    // /* Handle on hover */
                    // &::-webkit-scrollbar-thumb:hover {
                    //     background: map-get($color, main );
                    // }

                    &-items{
                        // @include rpmin(lg){
                        //     position: absolute;
                        //     top: 0;
                        //     left: 0;
                        //     right: 0;
                        //     bottom: 0;
                        // }
                        padding-right: 10px;
                        ol {
                            padding-inline-start: 40px;
                            @include size(s24);
                            color: map-get($color, black-2 );
                            .items{
                                position: relative;
                                // &::before{
                                //     transition: .2s ease-in-out;
                                // }
                                &::before{
                                    transition: .3s ease-in-out;
                                    opacity: 0;
                                    position: absolute;
                                        bottom:  0;
                                        left: -40px;
                                        width: calc(100% + 40px);
                                        height: 1px;
                                        background-color: map-get($color,main );
                                        content: "";
                                }
                                &-name{
                                    cursor: pointer;
                                    padding: 8px 0;
                                    @include rpmin(md){
                                        padding: 10px 0;
                                    }
                                    @include rpmin(xl){
                                        padding: 12px 0;
                                    }

                                    display: flex;
                                    align-items: center;
                                    span{
                                        width: 12px;
                                        height: 12px;
                                        @include rpmin(md){
                                            width: 18px;
                                            height: 18px;
                                        }

                                        border-radius: 50%;
                                        background: linear-gradient(180deg, rgba(146,214,101,1) 0%, rgba(0,67,63,1) 100%);
                                        content: "";
                                        transition: .2s ease-in-out;
                                        margin-left: auto;
                                    }
                                    position: relative;
                                
                                    &::after{
                                        position: absolute;
                                        top:  100%;
                                        left: -40px;
                                        width: calc(100% + 40px);
                                        height: 1px;
                                        background-color: map-get($color,main );
                                        content: "";
                                    }
                                    
                                }
                                &.active{
                                    &::before{
                                        opacity: 1;
                                        
                                    }
                                    .items-name span{
                                        width: 18px;
                                        height: 6px;
                                        border-radius: 10px;
                                        // background: map-get($color, main-second );
                                        animation: rotate .5s ease-in-out;
                                        @keyframes rotate {
                                            0%{
                                                transform:  rotate(90deg);
                                            }
                                            100%{
                                                transform:  rotate(0);
                                            }
                                        }
                                    }
                                }
                                &>*{
                                    @include size(s24);
                                    color: map-get($color, black-2 );
                                }
                                &-infor{
                                    
                                    padding: 0;
                                    @include size(s20);
                                    color: map-get($color, black-1 );
                                    position: relative;
                                    max-height: 0;
                                    overflow: hidden;
                                    margin-left: -40px;
                                    transition: .6s ease-in-out;
                                    ul{
                                        // padding-left: 40px;
                                        padding-inline-start: 21px;
                                        list-style-type: disc;
                                    }

                                    
                                    &.active{
                                        max-height: initial;
                                        
                                        // max-height: 300px;
                                        // overflow-Y: auto;
                                        // // &::-webkit-scrollbar {
                                        // //     display: none;
                                        // //   }
                                        // &::-webkit-scrollbar {
                                        //     width: 5px;
                                        // }
                                        
                                        // /* Track */
                                        // &::-webkit-scrollbar-track {
                                        //     background: #f1f1f1;
                                        // }
                                        
                                        // /* Handle */
                                        // &::-webkit-scrollbar-thumb {
                                        //     background: map-get($color, main-second );
                                        // }
                                        
                                        // /* Handle on hover */
                                        // &::-webkit-scrollbar-thumb:hover {
                                        //     background: map-get($color, main );
                                        // }
                                        // bac
                                    }
                                    &>*{
                                        padding: 30px 0;
                                    }
                                    *:not(:last-child){
                                        margin-bottom: 15px;
                                    }
                                    
                                }
                            }
                        }
                    }
                }
            }
            &--project{
                z-index: 1;
                position: relative;

                width: 100%;
                display: flex;
                justify-content: center;
                margin-top: 24px;
                @include rpmin(lg){
                    margin-top: 0px;
                    width: 50%;
                    justify-content: flex-end;
                }
                .svg{
                    position: relative;
                    width: 85%;
                    padding-bottom: 63%;
                    svg{
                        position: absolute;
                        z-index: 2;
                        width: 100%;
                        height: 100%;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        #process_line{
                            .line{
                                stroke: 10;
                                stroke-dasharray: 10;
                                // stroke-dashoffset: 10;
                                animation: line_through 20s infinite linear;
                               
                            }
                        }
                        #process_board{
                            .process_board_mouse{
                                animation: piecesMouse 2s infinite linear;
                                
                            }
                        }
                        #process_girl_1{
                            .process_girl_1_pieceL{
                                animation: pieces 3s infinite linear;
                                
                            }
                            .process_girl_1_pieceR{
                                animation: piecesRotate 3s infinite ease-in-out;
                                transform-origin: center bottom;
                                transform-box: fill-box;
                                
                            }
                            .process_girl_1_head{
                                animation: piecesRotate 4s infinite ease-in-out reverse;
                                transform-origin: center bottom;
                                transform-box: fill-box;
                            }
                            
                            
                        }
                        #process_girl_2{
                            .process_girl_2_head{
                                animation: piecesRotate 5s infinite ease-in-out reverse;
                                transform-origin: center bottom;
                                transform-box: fill-box;
                                transform: translateY(5px);
                            }
                        }
                        
                    }
                    .circle{
                        z-index: 1;
                        position: absolute;
                        top: -20%;
                        right: 30px;   
                        width: 35%;
                        animation: flow_index 4s linear infinite;
                        // transform: translateX(1440px) translateY(220px) ;
                        
                    }
                }
               
            }
        }
    }
}