.contact1{
    .contact{
        &__title{
            width: 100%;
            text-align: center;
        }

        &-form{
            margin-top: 3vh;
            @include rpmin(xxl){
                margin-top: 56px;
            }

            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: stretch;

            width: 100%;

            @include rpmin(lg){
                margin-left: -90px;
                width: calc(100% + 90px);
            }

            &--left{
                // margin-left: -45px;
                flex: 0 0 100%;
                display: flex;
                align-items: center;
                justify-content: center;

                svg{
                    max-height: 54vh;
                }

                @include rpmin(lg){
                    flex: 0 0 50%;
                }
            }

            &--right{
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 24px;
                padding-left: 0px;
                @include rpmin(xxl){
                    margin-top: 0px;
                    padding-left: 12px;
                }
                @include rpmin(xxl){
                    padding-left: 45px;
                }

                flex: 0 0 100%;
                @include rpmin(lg){
                    flex: 1;
                }
            }
        }
    }
}