.procedure-box{
    display: block;
    width: 100%;
    
    &>ul{
        list-style-type: none;
        *{
            transition: .3s all linear;
        }

        &>li{
            display: block;

            background-color: #E9E9E9;
            padding: 30px 20px;

            // @include rpmin(lg){
            //     padding: 50px 70px;
            // }

            &.hide{
                padding: 30px 20px;
                
                // @include rpmin(lg){
                //     padding: 32px 70px;
                // }

                .arrow{
                    transform: rotate(180deg);
                }


                .desc{
                    margin-top: 0;
                    opacity: 0;
                    height: 0;
                    overflow: hidden;
                    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
                }
            }

            .title{
                display: flex;
                justify-content: space-between;
                align-items: center;
                // font-size: 32px;
                @include size(s32);
                line-height: 100%;
                color: #151515;
                @include family--primary;
                @include regular;

                cursor: pointer;

                &>*{
                    font-size: 100%;
                    line-height: inherit;
                    color: currentColor;
                    font-family: inherit;
                    font-weight: inherit;

                    // font-size: 32px;
                    @include size(s32);

                    // @include rpTitle48(100%);
                }
            }
            .arrow{
                margin-left: 18px;
                user-select: none;
                pointer-events: none;

                &>*{
                    width: 16px;
                    height: 16px;
                    
                    // @include rpmin(lg){
                    //     width: 28px;
                    //     height: 28px;
                    // }
                }
            }

            .desc{
                margin-top: 26px;
                transform-origin: top;
                clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);

                color: #1C1C1C;
                // font-size: 20px;
                @include size(s24);
                line-height: 138%;
                @include family--primary;
                @include regular;

                &>*{
                    color: currentColor;
                    font-family: inherit;
                    font-weight: inherit;
                    line-height: inherit;

                    // @include rpMore24(138%);
                    @include size(s24);
                }
                ul{
                    padding-left: 3rem; 
                }
            }

            &:nth-of-type(1){
                background-color: #E9E9E9;
            }
            &:nth-of-type(2){
                background-color: #D5D5D5;
            }
            &:nth-of-type(3){
                background-color: #C8C8C8;
            }
            &:nth-of-type(4){
                background-color: #ADADAD;
            }
            &:nth-of-type(5){
                background-color: #9B9B9B;
            }
            &:nth-of-type(6){
                background-color: #858585;
            }
        }
    }
}