.hash1 {
    padding-top: 24px;
    @include rpmin(md){
        padding-top: 36px;
    }
    @include rpmin(lg){
        padding-top: 56px;
    }
    display: flex;
    flex-wrap: wrap;

    margin: 0 auto 0 auto;
    border-top: 1px solid #818282;

    flex: 0 0 auto;
    width: 100%;    
    padding-left: 52px;
    padding-right: 52px;
    @include rpmin(lg){
        padding-left: 0px;
        padding-right: 0px;
        width: 66.66667%;
    }

    &-left {
        width: 100%;
        @include rpmin(xl){
            width: 75%;
            padding-right: 8.3333%;
        }

        &__title {
            &>*{
                @include bold;
                @include title(s40);
                color: map-get($color, black-3);
            }
        }
        &__desc{
            margin-top: 24px;
            @include rpmin(md){
                margin-top: 40px;
            }
            @include rpmin(lg){
                margin-top: 56px;
            }
            &>*{
                @include regular;
                @include size(s20);
                color: map-get($color, grey);
            }
        }
    }
    
    &-right{
        margin-top: 24px;
        width: 100%;
        @include rpmin(xl){
            margin-top: 0px;
            width: 25%;
        }
        &__item{
            &:not(:first-child){
                margin-top: 28px;
            }
            &--tag{
                @include regular;
                @include size(s24);
                color: map-get($color, black-3);
            }
            &--content{
                margin-top: 8px;
                @include bold;
                @include size(s24);
                color: map-get($color, black-3);
            }
        }
    }
}