#fullpage{
    .section{
        overflow: hidden;
        @include fp{
            // overflow: auto;
            height: auto !important;
        
            .fp-tableCell{
                height: auto !important;
            }
        }
    }
    @include fp{
        height: auto !important;
        position: initial !important;
        transform: none !important;
    }
}

@include fph{
    .contact1,.contact2,
    .about-1,.about-2,
    .brn-index-about,.brn-index-services,.brn-index-project,.brn-index-process,
    .brn-index-client,.brn-index-contact,
    .service-1,.service-2,.service-3,.service-4,.service-5{
        opacity: 0;
        transition: .3s all linear;
    }
    
    .fp-viewing-0{
        .contact1,.about-1,.brn-index-about,.service-1{
            opacity: 1;
        }
    }
    .fp-viewing-1{
        .contact2,.about-2,.brn-index-services,.service-2{
            opacity: 1;
        }
    }
    .fp-viewing-2{
        .brn-index-project,.service-3{
            opacity: 1;
        }
    }
    .fp-viewing-3{
        .brn-index-process,.service-4{
            opacity: 1;
        }
    }
    .fp-viewing-4{
        .brn-index-client,.service-5{
            opacity: 1;
        }
    }
    .fp-viewing-5{
        .brn-index-contact{
            opacity: 1;
        }
    }
}




// @keyframes slideDown {
// 	0% {
// 		transform: translate3d(0, 100%, 0);
// 	}
// 	100% {
// 		transform: translate3d(0, 0%, 0);
// 	}
// }
// @keyframes slideUp {
// 	0% {
// 		transform: translate3d(0, 0%, 0);
// 	}
// 	100% {
// 		transform: translate3d(0, 100%, 0);
// 	}
// }

// .fp-container {
// 	position: fixed;
// 	z-index: 1000;
// 	width: 100%;
// 	height: 100%;
// 	left: 0;
// 	top: 0;
// 	overflow: hidden;
// }
// .fp-wrapper {
// 	width: 100%;
// 	height: 100%;
// 	position: relative;
// }

// .fp-slide {
// 	width: 100%;
// 	height: 100%;
// 	position: absolute;
// 	width: 100%;
// 	height: 100%;
// 	z-index: 1;
// 	background-color: darken(white, 10%);
// 	box-shadow: 0 0 10px black;
// 	left: 0;
// 	top: 0;
// 	z-index: 1;
// 	&.active {
// 		z-index: 2;
// 	}
// 	&.changing {
// 		z-index: 3;
// 	}
// }

// .fp-dots {
// 	position: fixed;
// 	// padding: 10px;
// 	z-index: 1001;
// 	right: 30px;
// 	bottom: 50px;
// 	width: 20px;
// 	.fp-dot {
// 		width: 8px;
// 		height: 8px;
// 		border: 1px solid map-get($mau, trang);
// 		margin: 5px auto;
// 		border-radius: 4px;
// 		background-color: map-get($mau, trang);
// 		cursor: pointer;
// 		position: relative;
// 		transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
// 		.fp-number {
// 			display: flex;
// 			justify-content: center;
// 			align-items: center;
// 			width: 20px;
// 			height: 20px;
// 			color: white;
// 			// d-none
// 			display: none;
// 		}
// 		.fp-title {
// 			position: absolute;
// 			right: 100%;
// 			top: 50%;
// 			transform: translateY(-50%);
// 			margin-right: -15px;
// 			padding-right: 10px;
// 			white-space: nowrap;
// 			opacity: 0;
// 			transition: all 0.5s ease;
// 			// d-none
// 			display: none;
// 		}
// 		&.active {
// 			background-color: transparent;
// 			height: 16px;
// 		}
// 		@media (min-width: 1025px) {
// 			&:hover {
// 				.fp-title {
// 					margin-right: 0;
// 					opacity: 1;
// 				}
// 			}
// 		}
// 	}
// }
