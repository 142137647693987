
#brn-index-client{
    background-color: white;
    height: 100%;
    width: 100%;
    @include fp{
        height:auto;
    }
    .brn-index-client{
        height: 100vh;
        width: 100%;
        @include fp{
            height:auto;
        }
        &__wrapper{
            height: 100%;
            display: flex;
            align-items: center;
            @include fp{
                height:auto;
            }
            &--client{
                width: 100%;
                @include rpmin(lg){
                    width: 33.33333%;
                }
                &__title{
                    // margin-bottom: 80px;
                    // position: relative;

                    @extend .title-format;
                    // @extend .title__name;

                    // *{
                    //     @include bold;
                    //     @include title(s74);
                    // }
                    // text{
                    //     fill: map-get($color, black );
                    // }
                    
                }
                &__list{
                    margin-top: 2vh;
                    padding-top: 2vh;

                    padding-bottom: 3vh;
                    width: 100%;
                    position: relative;

                    &::after{
                        content: "";
                        position: absolute;
                        top: 0px;
                        left: 0;
                        width: 100%;
                        height: 1px;
                        background-color: map-get($color,main );
                    }
                    .MyClient{
                        padding-bottom: 50px;
                    }
                    .swiper-pagination{
                        overflow: hidden;
                        left: 0;
                        transform: unset;
                        bottom: 5px;
                        // width: 50px !important;
                    }
                    .client-items{
                        &__decs{
                            margin-bottom: 30px;
                            color: map-get($color, black-2 );
                        }
                        &__name{
                            margin-top: 3.5vh;
                            display: flex;
                            flex-direction: column;
                            color: map-get($color, main );
                            @include size(s20);
                            span{
                                color: map-get($color, black-3 );
                            }
                        }
                    }
                   
                }
                &__svg{
                    margin-top: 24px;
                    @include rpmin(lg){
                        margin-top: 0;
                    }
                    svg{
                        max-height: 20vh;
                        overflow: visible;
                        width:100%;
                        #client_handshake{
                            .client_handshake_circle_rotate{
                                animation: circle_handshake_rotate infinite 9s linear;
                                transform-origin: center;
                                transform-box: fill-box;
                                
                            }
                            .client_handshake_circle{
                                animation: circle_handshake infinite 5s ease-in-out;
                                transform-origin: center;
                                transform-box: fill-box;
                                
                            }
                            .client_handshake_paper{
                                animation: fadein_paper infinite 3s ease-in-out;
                            }
                        }
                        #client_boyL{
                            .client_boyL_hand{
                                animation: piecesRotate 3s infinite ease-in-out;
                                transform-origin: top right;
                                transform-box: fill-box;
                            }
                            .client_boyL_hand_phone{
                                animation: phoneShake 1s infinite ease-in-out;
                                transform-origin: center ;
                                transform-box: fill-box;
                                
                            }
                        }
                        #client_boyR{
                            .client_boyR_hand{
                                animation: piecesRotate 1.5s infinite ease-in-out;
                                transform-origin: top right;
                                transform-box: fill-box;
                            }
                        }
                        #client_plants{
                            .client_plants_leaf{
                                animation: piecesRotate 3s infinite ease-in-out;
                                transform-origin:  center bottom;
                                transform-box: fill-box;
                            }
                        }
                    }
                }
            }
            &--clientLogo{
                width: 100%;
                margin-top: 24px;
                @include rpmin(lg){
                    width: 58.33333%;
                    margin-top: 0;
                }
                margin-left: auto;
                
                // margin-right: -100px;
                // overflow: scroll;
                // height: 100%;
                &__wrapper{
                    width: 100%;
                    @include rpmin(lg){
                        padding-bottom: 80%;
                        position: relative;
                    }
                    // height: 100%;
                    .MyClientLogo{
                        @include rpmin(lg){
                            position: absolute;
                            top: 0;
                            bottom: 0;
                            left: 0;
                            right: 0;
                        }
                        width: 100%;
                        height: 100%;
                        // margin-left: auto;
                        // margin-right: auto;
                        // .swiper-slide{
                            
                        // }
                    }
                }
                &_items{
                    width: 100%;
                    height: 100%;

                    display: flex;
                    align-items: center;
                    // padding-bottom: 100%;

                    &>*{
                        width: 33.3333%;
                    }

                    .clientLogo{
                        // width: calc(100% /3);
                        // width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: space-around;
                        
                        max-height: 100%;
                        height: 120px;
                        padding: 2% 3%;

                        // position: absolute;
                        // top: 0;
                        // bottom: 0;
                        // left: 0;
                        // right: 0;
                        img{
                            // object-fit: contain;
                            // display: flex;
                            // align-items: center;
                            // justify-content: center;
                            width: auto;
                            object-fit: contain;
                        }
                    }
                }
            }
        }
    }
}