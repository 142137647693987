.capacity-profile{
    width: 100%;
    height: calc(100vh - 188.44px);
    
    @include rpmin(md){
        height: calc(100vh - 246.34px);
    }
    .container{
        width: 100%;
        height: 100%;
        // min-height: 80rem;
        justify-content: center;
        align-items: center;
        text-align: center;
        // iframe{
        //     min-height: 100vh;
        // }
    }
}