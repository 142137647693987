@keyframes rotateAround3Item1 {
    0% {
        stroke-width: 0;
    }
    65% {
        stroke-width: 0.3;
    }
    70% {
        stroke-width: 0.7;
    }
    80% {
        stroke-width: 0.75;
    }
    90% {
        stroke-width: 0.7;
    }
    95% {
        stroke-width: 0.3;
    }
    100% {
        stroke-width: 0;
    }
}

@keyframes rotate360deg {
    to {
        transform: translate(-50%, -50%) rotate(360deg);
    }
}

@keyframes hoverBox {
    0% {
        opacity: 1;
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    }
    100% {
        clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
        opacity: 0.5;
    }
}
@keyframes hoverBox2 {
    0% {
        background-color: map-get($color , main );
        opacity: 0.5;
        clip-path: polygon(0 0, 100% 0, 0 0, 0% 100%);
    }
    100% {
        background-color: map-get($color , main );
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
        opacity: 1;
    }
}


@keyframes StrokeLineDown {
    0% {
        stroke-dashoffset: 0
    }
    100% {
        stroke-dashoffset: -200
    }
}

@keyframes StrokeLineUp {
    0% {
        stroke-dashoffset: 0
    }
    100% {
        stroke-dashoffset: 200
    }
}

@keyframes bounceInBottom {
    0% {
        opacity: 0;
        transform: translate3d(0, -700px, 0)
    }
    75% {
        opacity: 1;
        transform: translate3d(0, -25px, 0)
    }
    80% {
        transform: translate3d(0, 5px, 0)
    }
    90% {
        transform: translate3d(0, -5px, 0)
    }
    to {
        transform: translateZ(0);
        opacity: 1
    }
}

@keyframes bounceOpacity {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

// @keyframes moveLeft{
//     0%{
//         transform: translateX(-100%);
//     }
//     50%{
//         transform: translateX(-50%);
//     }
//     100%{
//         transform: translateX(0%);
//     }
// }

// ---------- Phu ------------- //
@keyframes pieces {
    0% {
        transform: translateX(10px) translateY(0px);
    }
    50%{
        transform: translateX(-5px) translateY(10px);
    }
    100%{
        transform: translateX(10px) translateY(0px);
    }
}
@keyframes piecesRotate {
    0% {
        transform: rotate(5deg);
    }
    50%{
        transform: rotate(-5deg);
    }
    100%{
        transform: rotate(5deg);
    }
}
@keyframes piecesMouse {
    0%{
        transform: translate(0px, 0px);
    }
    30%{
        transform: translate(10px, -10px);
    }
    60%{
        transform: translate(0px, 0px);
    }
    100%{
        transform: translate(0px, 0px);
    }
}
@keyframes line_through {
    to {
        stroke-dashoffset: -1000;
      }
}
@keyframes hair_flow {
    0%{
        transform: rotate(15deg) ;
        -o-transform: rotate(15deg);
        -ms-transform: rotate(15deg);
        -webkit-transform: rotate(15deg);
        -moz-transform: rotate(15deg);
    }
    50%{
        transform:rotate(0deg) ;
        -o-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
    }
    100%{
        transform:rotate(15deg) ;
        -o-transform: rotate(15deg);
        -ms-transform: rotate(15deg);
        -webkit-transform: rotate(15deg);
        -moz-transform: rotate(15deg);
    }
}
@keyframes hand_flow {
    0%{
        transform:rotate(10deg);
        -o-transform: rotate(10deg);
        -ms-transform: rotate(10deg);
        -webkit-transform: rotate(10deg);
        -moz-transform: rotate(10deg);
    }
    50%{
        transform:rotate(0deg);
        -o-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
    }
    100%{
        transform:rotate(10deg);
        -o-transform: rotate(10deg);
        -ms-transform: rotate(10deg);
        -webkit-transform: rotate(10deg);
        -moz-transform: rotate(10deg);
    }
}
@keyframes flow_index {
    0%{
        transform:rotate(0deg)
                  translate(-25px)
                  rotate(0deg);
      
      }
      100%{
        transform:rotate(360deg)
                  translate(-25px)
                  rotate(-360deg);
      }
}
@keyframes fadein_computer {
    0%{
        transform: scaleY(.96);
    }
    40%{
        transform: scaleY(1);
    }
    100%{
        transform: scaleY(.96);
    }
}
@keyframes fadein {
    0%{
        transform: translateY(-10px);
    }
    40%{
        transform: translateY(20px);
    }
    100%{
        transform: translateY(-10px);
    }
}
@keyframes fadein_paper {
    0%{
        transform: translateY(-4px);
    }
    40%{
        transform: translateY(4px);
    }
    100%{
        transform: translateY(-4px);
    }
}
@keyframes circle_handshake_rotate {
    from{
        transform:  rotate(360deg);
    }
    to{
        transform: rotate(0deg);
    }
}
@keyframes circle_handshake {
    0%{
        transform:  scale(.9);
    }
    50%{
        transform: scale(1.01);
    }
    100%{
        transform: scale(.9);
    }
}
@keyframes phoneShake {
    0%{
        transform: rotate(0deg);
    }
    10%{
        transform: rotate(-20deg);
    }
    20%{
        transform: rotate(20deg);
    }
    30%{
        transform: rotate(-20deg);
    }
    40%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(0deg);
    }
}

@keyframes opacityTextLogo {
    0%{
        opacity: 0;
    }   
    100%{
        opacity: 1;
    }
}

@keyframes animateLogo {
    0% {
        stroke-dashoffset: 0;
    }
    40% {
        stroke-dashoffset: 650;
    }
    70% {
        stroke-dashoffset: 1300;
        fill: transparent;
    }
    100% {
        stroke-dashoffset: 1300;
        fill: #fff;
    }
}

@keyframes animatedMutiply {
    0%, 35%, 100%{
        transform: translateX(0) rotate(0);
    }
    70%{
        transform: translateX(20vw) rotate(360deg);
    }
}
@keyframes animatedArrowRight {
    0%{
        opacity: 0;
        transform: translateX(-40px);
    }
    50%{
        opacity: 1;
    }
    100%{
        opacity: 0;
        transform: translateX(40px);
    }
}
@keyframes animatedArrowUp {
    0%{
        opacity: 0;
        transform: translateY(40px);
    }
    50%{
        opacity: 1;
    }
    100%{
        opacity: 0;
        transform: translateY(-40px);
    }
}

@keyframes rotate360 {
    0%{
        opacity: 0;
        transform: rotateZ(0) scale(0);
    }
    20%{
        opacity: 1;
        transform: rotateZ(0) scale(1);
    }
    100%{
        opacity: 0;
        transform: rotateZ(360deg);
    }
}

@keyframes rotate90 {
    0%{
        transform: rotateZ(0);
    }
    40%{
        transform: rotateZ(120deg);
    }
    90%{
        transform: rotateZ(-30deg);
    }
    100%{
        transform: rotateZ(0deg);
    }
}

@keyframes fillCircle {
    0%, 35%, 45%, 85%, 95%,100%{
        filter: drop-shadow(0 0 0px #91c73e);
    }
    40%, 90%{
        filter:
        drop-shadow(0 0 15px #91c73e)
        drop-shadow(0 0 20px #91c73e)
        drop-shadow(0 0 25px #91c73e)
        drop-shadow(0 0 30px #91c73e)
        drop-shadow(0 0 35px #91c73e);
    }
}


@keyframes STAR {
    0% {
      fill: powderblue;
      transform: scale(1);
    //   filter: drop-shadow(0px 0px 10px #fdfdfd)
    //   drop-shadow(0px 0px 15px #fdfdfd)
    //   drop-shadow(0px 0px 20px #fdfdfd);
    }
    10% {
      fill: #b0e5de;
    }
    20% {
      fill: #b0e5cc;
    }
    30% {
      fill: #cce5b0;
    }
    40% {
      fill: #dae5b0;
    }
    50% {
      fill: #e5ddb0;
      transform: scale(10);
    //   filter: drop-shadow(0px 0px 10px #fdfdfd)
    //   drop-shadow(0px 0px 15px #fdfdfd)
    //   drop-shadow(0px 0px 20px #fdfdfd);
    }
    100% {
      transform: scale(1);
      fill: powderblue;
    //   filter: drop-shadow(0px 0px 10px #fdfdfd)
    //   drop-shadow(0px 0px 15px #fdfdfd)
    //   drop-shadow(0px 0px 20px #fdfdfd);
    }

}