.service-2,
.service-4 {
    &__title{
        width: 100%;
        .title{
            &__content{
                justify-content: space-between;
            }
            &__desc{
                // flex: initial;
                &>*{
                    font-size: 3rem;
                    @include rp(tx){
                        font-size: 2.8rem;
                    }
                    @include rp(mx){
                        font-size: 2.7rem;
                    }
                }
            }
          
        }
    }
    &__content{
        padding-top: 24px;
        margin-top: 24px;

        @include rpmin(lg){
            padding-top: 2vh;
            margin-top: 2vh;
        }
        @include rpmin(yl){
            padding-top: 70px;
            margin-top: 50px;

            @include hmin(xxl){
                padding-top: 2vh;
                margin-top: 2vh;
            }
        }

        width: 100%;
        position: relative;
        &::after{
            position: absolute;
            content: "";
            top: 0;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: map-get($color, main );
        }
        .brand-service{
            &__img{
                padding-right: 0px;
                order: 1;
                @include rpmin(lg){
                    order: 2;
                    padding-right: 30px;
                }
                @include rpmin(xl){
                    padding-right: 60px;
                }

                display: flex;
                justify-content: center;
                align-items: center;
                
                svg{
                    max-height: 54vh;
                    
                    height: auto;
                    width: 100%;
                    @include rpmin(md){
                        height: auto;
                        width: 80%;
                    }
                    
                    @include rpmin(lg){
                    }
                }
                
                .kinhhienvi-rotate{
                    animation: flow 4s linear infinite;
                        // transform: translateX(1440px) translateY(220px) ;
                    @keyframes flow {
                        0%{
                            transform:  rotate(0deg)
                                        translate(-20px)
                                        rotate(0deg);
                            
                            }
                            100%{
                            transform:  rotate(360deg)
                                        translate(-20px)
                                        rotate(-360deg);
                            }
                    }
                }
            }
            &__infor{
                order: 2;
                
                @include rpmin(lg){
                    order: 1;
                }
            }
        }
    }
}



.service-4{
    .serSvg04 .search{
        animation: flow 4s linear infinite;
            // transform: translateX(1440px) translateY(220px) ;
        @keyframes flow {
            0%{
                transform:  rotate(0deg)
                            translate(-20px)
                            rotate(0deg);
                }
                100%{
                transform:  rotate(360deg)
                            translate(-20px)
                            rotate(-360deg);
                }
        }
    }
}