@import "abstracts/variables";
@import "abstracts/mixins";
// @import "abstracts/grid";
@import "abstracts/gridBs";

@import "base/animations";
@import "base/reset";
@import "base/fonts";
@import "abstracts/functions";
@import "base/utilities";

// component
@import "./components/fullpage";
@import "./components/cursor";
@import "./components/swiper";
@import "./components/form";
@import "./components/box-procedure";
@import "./components/svs-project-hl";


// layout
@import "./layout/header.scss";
@import "./layout/footer.scss";
@import "./layout/loading";
@import "./layout/page-nav.scss";
@import "./layout/box.scss";
@import "./layout/title.scss";
@import "./layout/box-linkSocial.scss";
@import "./layout/_hash1.scss";
@import "./layout/_hash2.scss";
@import "./layout/_card.scss";
@import "./layout/_brand-service.scss";
@import "./layout/_project.scss";
@import "./layout/_company-infor.scss";

// page
@import "pages/index/style";
@import "pages/about/style";
@import "pages/brand/style";
@import "pages/contact/style";
@import "pages/news/style";
@import "pages/service/style";
@import "pages/newsDetail/style";
@import "pages/all-project/style";
@import "pages/capacityProfile/style";
@import "pages/service-detail/style";

.fb_dialog{
    iframe{
        transition: .1s all;
        transform: translateY(-6rem);
    }
}