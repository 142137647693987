.brand2{
    @include space(s100, margin-top);
    &-wrapper{
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: stretch;
        justify-content: center;

        margin-top: -32px;
        margin-left: -32px;
        margin-right: -32px;
        width: calc(100% + 64px);

        @include rp(tx){
            margin-top: -24px;
            margin-left: -16px;
            margin-right: -16px;
            width: calc(100% + 32px);
        }

        @include rp(tm){
            margin-top: -24px;
            margin-left: -12px;
            margin-right: -12px;
            width: calc(100% + 24px);
        }

        @include rp(mx){
            margin-top: -24px;
            margin-left: -8px;
            margin-right: -8px;
            width: calc(100% + 16px);
        }
        
        &>*{
            flex: 0 0 33.33333%;
            width: 33.33333%;
            max-width: 33.33333%;

            padding-top: 32px;
            padding-left: 32px;
            padding-right: 32px;

            @include rp(tx){
                padding-top: 24px;
                padding-left: 16px;
                padding-right: 16px;
            }

            @include rp(tm){
                padding-top: 24px;
                padding-left: 12px;
                padding-right: 12px;
            }
            @include rp(mx){
                padding-top: 24px;
                padding-left: 8px;
                padding-right: 8px;
            }

            @include rp(tm){
                flex: 0 0 50%;
                width: 50%;
                max-width: 50%;
            }

            @include rp(ml){
                flex: 0 0 100%;
                width: 100%;
                max-width: 100%;
            }
        }
    }
}