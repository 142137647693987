.company_infor{
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    // test
    // margin-top: 80px;
    // margin-bottom: 80px;
    position: relative;
    margin-left: -8px;
    margin-right: -8px;
    margin-top: -24px;

    // padding-top: 3vh;
    // margin-top: 3vh;
    // &::after{
    //     position: absolute;
    //     top: 0px;
    //     left: 50%;
    //     transform: translateX(-50%);
    //     width: calc(100% - 16px);
    //     height: 1px;
    //     content: "";
    //     background-color: map-get($color,main );
    // }

    &>*{
        margin-top: 3vh;

        @include size(s24);
        // white-space: pre-wrap;        
        word-wrap: break-word;
        padding: 0 8px;

        width: 100%;
        text-align: center;
        @include rpmin(sm){
            width: 50%;
            text-align: initial;
            text-align: center;
        }
        @include rpmin(lg){
            width: 33.3333%;
            text-align: left;
        }
    }
    .social{
        height: 100%;
        display: flex;
        align-items: flex-end;
        justify-content: center;

        width: 100%;
        padding: 0;
        @include rpmin(sm){
            padding: 0 20%;
        }
        @include rpmin(lg){
            width: auto;
            padding: 0 0;
        }

        .box-linkSocial {
            &__content{
                // justify-content: space-around;
                svg{
                    height: 100%;
                    width: 100%;
                    path{
                        fill: map-get($color, black-3 );
                    }
                }
            }
            &__facebook,&__be,&__ins{
                &:hover{
                    background-color: unset !important;
                    // opacity: 0;
                    // animation: hoverBox2 0.4s linear  0.2s;
                    svg{
                        path{
                            fill: map-get($color , main) !important;
                        }
                    }
                }
            }
        }
    }
}
