.project_items{
    // padding: 20px;
    width: 100%;
    // padding-bottom: 80%;
    // @include rpmin(sm){
    //     padding-bottom: 86%;
    // }
    // @include rpmin(lg){
    //     padding-bottom: 80%;
    // }

    position: relative;
    cursor: pointer;
    overflow: hidden;
    transition: .2s ease-in-out;

    .link_project{
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: transparent;
        z-index: 1;
    }
    .items{
        // padding: 30px;
        // position: absolute;
        // top: 0;
        // left: 0;
        // bottom: 0;
        // right: 0;

        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .items-header{
            width: 100%;
            padding-bottom: 55%;
            position: relative;
            img{
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
            }
        }
        .items-main{
            display: flex;
            align-items: center;

            margin-top: 12px;

            &__name{
                @include size(s32);
                color: map-get($color, black-3 );
                @include p-line-clamp(1);
                @include medium;

                flex:1;
            }
            &__sub{
                margin-left: auto;
                @include size(s24);
                color: map-get($color,main );
                @include p-line-clamp(1)
            }
        }
        .items-footer {
            margin-top: 12px;

            display: inline-flex;
            .btn-see-more{
                @include size(s24);
                color: map-get($color, main);
                // @include bold;
                position: relative;
                // padding-left: 7rem;
                display: inline-flex;
                transition: .2s ease-in-out;
                padding: 10px 0;
                z-index: 2;
               
            }
        }
        
    }
    &:hover{
        span{
            @include bold;
        }
    }

    &:hover{
        .btn-see-more{
            .line{
                width: 20px;
                @include rp(tl){
                    width: 18px;
                }
                &::before{
                    width: 100%;
                    transform-origin: right;
                    transform: translateY(-50%) rotate(25deg);
                    border-top: 2px solid map-get($color, main);
                }
                &::after{
                    width: 100%;
                    transform-origin: right;
                    transform: translateY(-50%) rotate(-25deg);
                    border-top: 2px solid map-get($color, main);
                }
            }
        }
        
    }
}