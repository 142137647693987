@keyframes toggleMenuBar {
    50%{
        height: 4px;
        width: 100%;
        top: 50%;
        transform: translate(-50%, -50%);   
    }
    100%{
        height: 4px;
        width: 100%;
        top: 50%;
        transform: translate(-50%, -50%);   
        opacity: 0;
    }
}

@keyframes toggleMenuBarFirts {
    50%{
        height: 4px;
        width: 100%;
        top: 50%;
        transform: translate(-50%, -50%);   
    }
    100%{
        height: 4px;
        width: 100%;
        top: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
    }
}

@keyframes toggleMenuBarLast {
    50%{
        height: 4px;
        width: 100%;
        top: 50%;
        transform: translate(-50%, -50%);   
    }
    100%{
        height: 4px;
        width: 100%;
        top: 50%;
        transform: translate(-50%, -50%) rotate(-45deg);
    }
}

@keyframes toggleMenuBarReverse {
    0%{
        height: 4px;
        width: 100%;
        top: 50%;
        transform: translate(-50%, -50%);   
        opacity: 0;
    }
    50%{
        height: 4px;
        width: 100%;
        top: 50%;
        transform: translate(-50%, -50%);   
    }
}

@keyframes toggleMenuBarFirtsReverse {
    0%{
        height: 4px;
        width: 100%;
        top: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
    }
    50%{
        height: 4px;
        width: 100%;
        top: 50%;
        transform: translate(-50%, -50%);   
    }
}

@keyframes toggleMenuBarLastReverse {
    0%{
        height: 4px;
        width: 100%;
        top: 50%;
        transform: translate(-50%, -50%) rotate(-45deg);
    }
    50%{
        height: 4px;
        width: 100%;
        top: 50%;
        transform: translate(-50%, -50%);   
    }
}


@keyframes strokeLine {
    0%{
        fill-dashoffset: 0;
    }
    100%{
        stroke-dashoffset: 10000;
    }
}

@keyframes translateLine {
    from{
        transform: rotate3d(0, 0, 0);
    }
    to{
        transform: rotate3d(0, 0, 180deg);
    }
}

@keyframes wave{
    from{
        transform: translate(0px) rotate(0);
        opacity: 1;
    }
    to{
        transform: translate(1000px) rotate(360deg);
        opacity: 0.5;
    }
}

// @keyframes wave2{
//     0%{
//         transform-origin: center;
//         transform: translate(0px);
//         opacity: 1;
//     }
//     100%{
//         transform-origin: center;
//         transform: translate(20px);
//         opacity: 0.5;
//     }
// }

@keyframes wave2{
    from{
        transform: translateX(0px) translateY(0);
    }
    to{
        transform: translateX(30px) translateY(20px);
    }
}

// @keyframes wave3{
//     from{
//         transform: translate(0px);
//         opacity: 1;
//     }
//     to{
//         transform: translate(100px);
//         opacity: 0.5;
//     }
// }

@keyframes visibleOverlay {
    from{
        opacity: 0;
        clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);
        width: 100%;
        height: 100%;
    }
    to{
        width: 100%;
        height: 100%;
        opacity: 1;
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    }
}

@keyframes fixedHeader{
    0%{
        opacity: 1;
        top: 0%;
        transform: translateY(-100%);
    }
    100%{
        opacity: 1;
        top: 0;
        transform: translateY(0%);

        @include shadow(s1);
    }
}

.header{
    background-color: transparent;
    position: fixed;
    z-index: 111;
    display: block;
    width: 100%;

    transition: .6s all ease-in-out;

    @include fp{
        position: relative;
        background-color: white;

        &.scroll{
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            transform: translateY(-100%);
            opacity: 1;
            box-shadow: 0 3px 6px rgba(0,0,0,.16);
    
            // animation-delay: 0.1s;
            animation: fixedHeader 1.5s ease forwards;
        }
    }

    &.scrollDown{
        background-color: white;
        box-shadow: none;
    }

    &.show{
        height: 100vh;
        background-color: white;
    
        .container{
            height: 100%;
            flex-direction: column;
        }

        .header-overlay{
            animation: visibleOverlay 1.3s ease-in-out .6s forwards;
        }

        .header-bottom{
            height: auto;
            display: block;
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
            opacity: 1;
        }
    }

    &-overlay{
        position: absolute;
        top: 0;
        left: 0;
        
        overflow: hidden;

        display: flex;
        align-items: flex-end;

        opacity: 0;
        clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);

        // transition: .26s all ease-in-out;

        .dot{
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }

        svg{
            position: absolute;

            left: 0;
            bottom: 0;

            .dotGroup{
                transform: translate(-150px, 450px) rotate(22deg);
            }
            .dotGroup2{
                // transform: translate(0%, 24%) rotate(36deg);
                transform: translate(-150px, 120px) rotate(38deg);
            }
            .dotGroup3{
                transform: translate(-150px,275px) rotate(22deg);
            }

            // .gLine{
            //     animation: wave 11s infinite linear;
            // }
            // // .gLine2{
            // //     animation: wave2 28s infinite linear alternate;
            // // }
            // .gLine3{
            //     animation: wave 16s infinite linear;
            // }
        }
    }

    &-nav{
        list-style-type: none;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        &__item{
            text-align: center;
            
            &>*{
                @include title(s74);
                @include bold;
                color: map-get($color, black);
                line-height: 1.6;  

                &.active{
                    & ~ ul{
                        opacity: 1;
                        height: auto;
                        user-select: initial;
                        pointer-events: initial;
                    }
                }
            }

            ul{
                transition: .3s all;
                opacity: 0;
                height: 0;
                list-style-type: none;
                pointer-events: none;
                user-select: none;
                li{
                    text-align: center;
                    display: block;
                    a{
                        display: block;
                        @include size(s32);
                        color: #616161;
                        line-height: 1.4;
                    }
                }
            }
        }
    }

    &-top{
        width: 100%;
        // height: 125px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: stretch;
    }
    &-bottom{
        flex: 1;
        width: 100%;
        clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
        height: 0px;
        opacity: 0;
        transition: .4s all ease-in-out;
        flex: 1;
        overflow: auto;
    }
    &-logo{
        display: inline-flex;
        height: 42px;
        width: 140px;
        margin: 18px 0;
        
        @include rpmin(sm){
            height: 59px;
            width: 160px;
            margin: 12px 0;
        }
        
        @include rpmin(lg){
            margin: 30px 0;
        }

        img{
            width: auto;
            height: 100%;
            object-fit: contain;
        }

        position: relative;
        overflow: hidden;

        &__default{
            transition: .3s all ease-in-out;
            user-select: none;
            pointer-events: none;
        }
        
        &__active{
            pointer-events: none;
            user-select: none;
            position: absolute;
            top: 0;
            left: 0;
            transition: .3s all ease-in-out;
            transform: translateY(100%);
            width: 100%;
            height: 100%;
        }

        &.header-logo--bg{
            .header-logo__default{
                transform: translateY(-100%);
            }
            .header-logo__active{
                transform: translateY(0%);
            }
        }
    }

    &-toggle{
        display: flex;
        align-items: center;
        .btn-toggle{            
            width: 42px;
            height: 48px;

            @include rpmin(md){
                width: 55px;
                height: 55px;
            }

            position: relative;
            cursor: pointer;
            transition: .3s all ease-in-out;

            &.btn-toggle--white{
                &:hover{
                    .line{
                        box-shadow: 0 3px 3px rgba(0,0,0,.16);
                    }
                }
                .line{
                    background-color: map-get($color, white);
                }
            }

            &.focus{
                // transform: scale(.9);
                width: 28px;
                height: 28px;
                .line{
                    height: 3px !important;
                    animation: toggleMenuBar .3s ease-in-out forwards;

                    &:first-child{
                        animation: toggleMenuBarFirts .3s ease-in-out forwards;
                    }
                    &:last-child{
                        animation: toggleMenuBarLast .3s ease-in-out forwards;
                    }
                }
            }

            &:hover{
                transform: scale(.9);
                .line{
                    box-shadow: 0 3px 3px rgba(255,255,255,.16);
                }
            }

            .line{
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 100%;
                height: 5px;
                background-color: map-get($color, black);
                // border-top: 6px solid map-get($color, black);
                border-radius: 10px;
                
                transition: .3s all ease-in-out;
                &:first-child{
                    top: calc(50% - 20%);
                    width: calc(100% - 20px);
                    height: 5px;
                    @include rpmin(md){
                        height: 6px;
                    }
                }
                &:last-child{
                    top: calc(50% + 20%);
                    width: calc(100% - 20px);
                    height: 5px;
                    @include rpmin(md){
                        height: 6px;
                    }
                }
            }
        }
    }
}