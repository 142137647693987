@import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

// font-family: 'Open Sans', sans-serif;
// @import url('https://fonts.googleapis.com/css2?family=Judson:ital,wght@0,400;0,700;1,400&display=swap');

// @font-face {
//   font-family: "sf";
//   src: url("../fonts/SANFRANCISCODISPLAY-REGULAR.OTF") format("truetype");
//   @include regular;
// }
// @font-face {
//   font-family: "sf";
//   src: url("../fonts/SANFRANCISCODISPLAY-MEDIUM.OTF") format("truetype");
//   @include medium;
// }
// @font-face {
//   font-family: "sf";
//   src: url("../fonts/SANFRANCISCODISPLAY-SEMIBOLD.OTF") format("truetype");
//   @include semi-bold;
// }
// @font-face {
//   font-family: "sf";
//   src: url("../fonts/SANFRANCISCODISPLAY-BOLD.OTF") format("truetype");
//   @include bold;
// }
