#brn-index-project{
    background-color: white;
    height: 100%;
    width: 100%;
    @include fp{
        height:auto;
    }
    .brn-index-project{
        height: 100vh;
        width: 100%;
        @include fp{
            height:auto;
        }
        &__wrapper{
            height: 100%;
            @include fp{
                height:auto;
            }
            // margin-bottom: 40px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            &--intro{
                // display: flex;
                // flex-wrap: wrap;
                // align-items: center;
                // margin-bottom: 75px;
                // margin-bottom: 6vh;
                @extend .title__content;
                
                &_title{
                    // @include bold;
                    // @include title(s74);

                    @extend .title-format;
                    @extend .title__name;
                }
                &_decs{
                    // @include size(s24);
                    // line-height: 29px;

                    @extend .title__desc;
                }
            }
            &--project{
                width: 100%;
                // margin-top: 30px;
                padding-top: 3vh;
                margin-top: 3vh;
                position: relative;
                &::after{
                    position: absolute;
                    top: 0px;
                    left: 0;
                    width: 100%;
                    height: 1px;
                    content: "";
                    background-color: map-get($color, main );
                }
                .nav-custom{
                   
                    .nav{
                        .btn-all{
                            display: inline-flex;
                            position: absolute;
                            right: 0;
                            bottom: -100%;
                            .btn{
                                @include size(s24);
                                color: map-get($color, black-3);
                                // text-transform: capitalize;
                            }
                        }
                        .swiper-button-next,.swiper-button-prev{
                            position: unset;
                            // top: 100%;
                            width: auto;
                            left: unset;
                            right: unset;
                            margin-left: 20px;
                            // margin-left: auto;
                            &::after{
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }
}