#footer{
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: transparent;

    @include fp{
        position: relative;
        background-color:  white;
        padding-top: 5.2vh;
    }

    &.full{
        position: relative;
        bottom: auto;
        background-color:  white;
        .normalInfo{
            display: none !important;
        }
        .fullInfo{
            display: block !important;
            padding-bottom: 20px;
            .container{
                position: relative;
                padding-top: 2vh;
                margin-top: 2vh;
    
                &::after{
                    position: absolute;
                    top: 0px;
                    left: 50%;
                    transform: translateX(-50%);
                    width: 100%;
                    height: 1px;
                    content: "";
                    background-color: map-get($color,main );
                }
            }
        }

        @include fp{
            padding-top: 0;
        }
    }

    &.relate{
        position: relative;
        bottom: auto;
        background-color:  white;
    }

    .footer{
        &.normalInfo{
            position: relative;
            .container{
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
            }
            .box-linkSocial {
                padding-bottom: 4.2vh;
                display: inline-flex;
                width: auto;
                &__content{
                    justify-content: flex-start;
                    &>*:not(:first-child){
                        margin-left: 12px;
                    }
                }
            }

            .languages{
                display: flex;
                align-items: center;
                padding-bottom: 4.2vh;

                @include rpmin(xl){
                    margin-right: 0;
                }

                &-item{
                    cursor: pointer;
                    background: transparent;
                    border: none;
                    outline: none;

                    padding: 8px 12px;

                    &:hover, &:focus{
                        background: transparent;
                        border: none;
                        outline: none;
                    }

                    color: map-get($color, main);
                    @include size(s32);
                    @include bold;
                    display: none;
                    &.active{
                        display: block;
                    }
                }
            }
        }
        &.fullInfo{
            display: none;
        }
    }


    .box-linkSocial {
        &__content{
            justify-content: flex-start;
        }
    }
}