////////////////////////////////////////////////
/////////////////////////MEDIA ///////////////////

@mixin rp($canvas) {
    @media (max-width: map-get($media, #{$canvas})){
        @content;
    }
}

@mixin rpmin($canvas) {
    @media (min-width: map-get($mdWidth, #{$canvas})){
        @content;
    }
}

@mixin hmin($canvas) {
    @media (min-height: map-get($mdHeight, #{$canvas})){
        @content;
    }
}

@mixin fp{
    @media (max-width: map-get($fpsize, w)), (max-height: map-get($fpsize, h)){
        @content;
    }
}

@mixin fph{
    @media (min-width: 1280px) and (min-height: 540px){
        @content;
    }
}
  
/////////////////////////MEDIA ///////////////////
//////////////////////////////////////////////////


